ModalView = require './modal-view'
ContactGroup = require 'models/contact-group'
utils = require 'lib/utils'

module.exports = class DeleteSendoutView extends ModalView
  autoRender: false

  options:
    footer: true
    header: true
    primaryButton: 'Close'
    title: 'Sendout has started'

  modalOptions:
    backdrop: 'static'
    keyboard: false
    show: true

  getModalTemplateFunction: ->
    (data) => """
      #{@getFullBody()}
    """

  getFullBody: ->
    """
    <p>
     This Sendout is being processed can't be updated or deleted
    </p>
  """

  getPrimaryButton: ->
    """
    <button class="btn btn-primary deleteGroup">
      #{@options.primaryButton}
    </button>
    """

  getModalHeader: ->
    """
    <div class="modal-header">
      #{@getCloseButton()}
      <h4 class="modal-title">#{@options.title}</strong></h4>
    </div>
    """

  getModalFooter: ->
    """
    <div class="modal-footer">
      #{@getPrimaryButton()}
    </div>
    """

  dispose: ->
    return if @disposed
    @options.group.set('deleteGroup', false)

    super
    # Remove any eventual first backdrop
    $('.modal-backdrop').eq(0).remove()

