View = require 'views/base/view'

module.exports = class TransactionView extends View
  tagName: 'tr'

  getTemplateFunction: ->
    (data) -> """
      <td>#{ _.escape(moment.unix(data.timestamp).format('L LT')) }</td>
      <td>#{ _.escape(data.comment) }</td>
      <td>€#{ _.escape(data.change) }</td>
      <td>€#{ _.escape(data.new_balance) }</td>
    """
