Collection = require 'models/base/collection'
Conversation = require './conversation'

module.exports = class Conversations extends Collection
  model: Conversation

  count: 200
  offset: 0

  urlRoot: ->
    "/2/conversations/?count=#{@count}"

  url: ->
    base = super
    base += "&offset=#{@offset}" if @offset > 0
    base

  initialize: (models, options={}) ->
    @offset = parseInt(options.offset) or 0
    super

  # Get previous page url
  getPrevPageURL: ->
    offset = @offset - @count
    options = {}
    options.offset = offset if offset > 0
    utils.reverse 'messages#inbox', {offset}
