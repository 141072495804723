View = require 'views/base/view'

module.exports = class WalletSubscriberView extends View
  tagName: 'li'
  className: 'list-group-item'

  events:
    'click .remove-subscriber': 'removeSubscriber'

  removeSubscriber: (event) ->
    event.preventDefault()?
    @model.destroy()

  getTemplateFunction: ->
    (data) ->
      """
      <a class="remove-subscriber pull-right" href="#"><i class="fa fa-times"></i></a>
      #{ _.escape(data.email) }
      """
