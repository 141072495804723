View = require('views/base/view')
utils = require('lib/utils')

module.exports = class HlrBatchResultItemView extends View
  tagName: 'tr'

  getTemplateFunction: ->
    (data) ->
      if data.to.contact
        fullContactName = data.to.contact.firstname+' '+data.to.contact.lastname
      else
        fullContactName = '<span class="text-muted">No Name</span>'
      if data.dlr.status
        status = switch data.dlr.status
          when 'DELIVRD' then 'Delivered'
          when 'UNDELIV', 'FAILED', 'DELETED' then 'Undelivered'
          when 'EXPIRED' then 'Expired'
          when 'REJECTD' then 'Rejected'
          else data.dlr.status
      else
        status = 'No status'

      return """
        <td style="white-space:nowrap">#{ _.escape(fullContactName)}</td>
        <td>#{ _.escape(data.to.number) or ''}</td>
        <td style="white-space:nowrap">#{ _.escape(status) }</td>
        <td style="white-space:nowrap">#{ _.escape(data.mccmnc.mcc or '') }</td>
        <td style="white-space:nowrap">#{ _.escape(data.mccmnc.mnc or '') }</td>
      """
