View = require 'views/base/view'
utils = require 'lib/utils'
mediator = require 'mediator'

module.exports = class MessageInputView extends View
  template: require './templates/message-input'

  useRivets: true

  events:
    'keyup .sms-control': 'updateText'

  optionNames: View::optionNames.concat ['sendout', 'message']

  initialize: () ->
    @text = @getModelMessage()
    @listenToOnce @sendout, 'sync', @insertText if @sendout

  render: ->
    super
    @$progress = @$('.sms-progress .progress-bar')

    @updateText()

  clearMessage: () ->
    @text = ''
    @updateStatusField()

  insertText: (sendout) ->
    @text = @getModelMessage()
    @updateStatusField()

  updateText: (event) ->
    keyCode = event and (event.keyCode or event.which)
    if event and (event.metaKey or event.ctrlKey) and keyCode is 13
      # can't really get cmd-key on mac to work, whatever. might work for someone (event.metaKey)
      mediator.publish('submitMessageKeyCombo', event)
    else
      @text = event.target.value if event
      @updateStatusField()

  updateStatusField: ->
    @smsData = utils.getSMSData(@text)
    # Progress bar
    progress = 100 * @text.length / (@smsData.msgMaxLength * @smsData.multipartCount)
    progress = 0 if isNaN(progress)

    # necessary evil
    @$progress.css width: "#{progress}%"

    progressClass = "progress-bar progress-bar-success"
    progressClass = "progress-bar progress-bar-warning" if @text.length > (@smsData.msgMaxLength * .8)
    progressClass = "progress-bar progress-bar-danger" if @text.length > @smsData.msgMaxLength
    @$progress.attr({ class: progressClass })

    @setModelMessage()


  setModelMessage: () ->
    if(@sendout)
      sms = @sendout.get('sms') or {}
      sms.message = @text
      @sendout.set({sms: sms})
      @sendout.trigger('change:sms', @sendout, sms)
    else if(@message)
      @message.set({message: @text})

  getModelMessage: () ->
    message = ''
    if(@sendout)
      sms = @sendout.get('sms') or {}
      message = sms.message if sms and sms.message
    else if(@message)
      message = @message.get('message') or ''
    return message

  dispose: ->
    return if @disposed
    properties = [
      '$progress', '$message', '$info', '$unicodeAlert'
    ]
    delete this[prop] for prop in properties
    super
