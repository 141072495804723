
View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class ContactImportView extends View
  template: require 'views/templates/contact-imports-item-view'
  noWrap: true
  useRivets: true

  events:
    'click .close': 'cancelImport'
    'submit form': 'uploadFile'

  initialize: ->
    file = @model.get('file')
    if file?.size > 65536000 # 64 MB!
      @model.set(error: 'File size exceeded (64MB)')
    super

  cancelImport: (event) ->
    event.preventDefault()
    @model.collection.remove @model

  uploadFile: (event) ->
    return true # whatever

  dispose: ->
    return if @disposed
    properties = []
    delete this[prop] for prop in properties
    super
