View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class CustomerView extends View

  listen:
    'synced model': 'render'

  getTemplateFunction: ->
    (data) ->
      return '' unless data.synced
      """
        <div class="panel panel-default">
          <div class="panel-heading">
            <h2 class="panel-title">#{ _.escape(data.name) }</h2>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-4">
                #{ _.escape(data.address) }<br>
                #{ _.escape(data.post_box) } #{ _.escape(data.city) }, #{ _.escape(data.country) }<br>
                #{ _.escape(utils.formatPhone(data.phone)) }
              </div>
              <div class="col-sm-4">
                <strong>Email:</strong> #{ _.escape(data.email) }<br>
                <strong>VAT:</strong> #{ _.escape(data.vat) }<br>
                <strong>Invoice type:</strong> #{ _.escape(data.invoice_type) }
              </div>
              <div class="col-sm-4">
                <strong>Pricelist type:</strong> #{if data.pricelist_type then 'json' else 'csv'}<br>
                <strong>Pricelist updates:</strong> #{ _.escape(data.pricelist_schedule.name) }<br>
                <strong>Account Manager:</strong> <a href="mailto:#{ _.escape(data.account_manager.email) }">#{ _.escape(data.account_manager.name) }</a>
              </div>
            </div>
          </div>
        </div>
      """
