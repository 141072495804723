# Application-specific utilities
# ------------------------------

# Delegate to Chaplin’s utils module.
utils = Chaplin.utils.beget Chaplin.utils
PNF = libphonenumber.PhoneNumberFormat
PhoneNumber = libphonenumber.PhoneNumberUtil.getInstance()

_.extend utils,
  GSM7Map: ["\u0040","\u00a3","\u0024","\u00a5","\u00e8","\u00e9","\u00f9","\u00ec","\u00f2","\u00c7","\u000a","\u00d8","\u00f8","\u000d","\u00c5","\u00e5","\u0394","\u005f","\u03a6","\u0393","\u039b","\u03a9","\u03a0","\u03a8","\u03a3","\u0398","\u039e","\u00c6","\u00e6","\u00df","\u00c9","\u0020","\u0021","\u0022","\u0023","\u00a4","\u0025","\u0026","\u0027","\u0028","\u0029","\u002a","\u002b","\u002c","\u002d","\u002e","\u002f","\u0030","\u0031","\u0032","\u0033","\u0034","\u0035","\u0036","\u0037","\u0038","\u0039","\u003a","\u003b","\u003c","\u003d","\u003e","\u003f","\u00a1","\u0041","\u0042","\u0043","\u0044","\u0045","\u0046","\u0047","\u0048","\u0049","\u004a","\u004b","\u004c","\u004d","\u004e","\u004f","\u0050","\u0051","\u0052","\u0053","\u0054","\u0055","\u0056","\u0057","\u0058","\u0059","\u005a","\u00c4","\u00d6","\u00d1","\u00dc","\u00a7","\u00bf","\u0061","\u0062","\u0063","\u0064","\u0065","\u0066","\u0067","\u0068","\u0069","\u006a","\u006b","\u006c","\u006d","\u006e","\u006f","\u0070","\u0071","\u0072","\u0073","\u0074","\u0075","\u0076","\u0077","\u0078","\u0079","\u007a","\u00e4","\u00f6","\u00f1","\u00fc","\u00e0"]
  GSM7MapExt: ["\u005e", "\u007b", "\u007d", "\u005c", "\u005b", "\u007e", "\u005d", "\u007c", "\u20ac"]

  formatPhone: (value, country) ->
    return value if not value?
    return value if value.length < 7
    return value unless value.match(/^\+?[()\d- ]+$/)
    formattedNumber = if value[0] isnt '+' then '+' + value else value
    country = if country then country.toUpperCase() else 'ZZ'
    try
      phone = PhoneNumber.parse(formattedNumber, country)
      if PhoneNumber.isValidNumber(phone)
        return PhoneNumber.format(phone, PNF.INTERNATIONAL)
      else
        return value
    catch error
      return value


  sanitizePhone: (value, country) ->
    return value if not value?
    return value if value.length < 7
    return value unless value.match(/^\+?[()\d- ]+$/)

    formattedNumber = if value[0] isnt '+' then '+' + value else value
    country = if country then country.toUpperCase() else 'ZZ'
    try
      phone = PhoneNumber.parse(formattedNumber, country)
      if PhoneNumber.isValidNumber(phone)
        return PhoneNumber.format(phone, PNF.E164).substring(1)
      else
        return value
    catch error
      return value


  dlrMap:
    "DELIVRD": "delivered",
    "UNDELIV": "undelivered",
    "FAILED": "undelivered",
    "DELETED": "undelivered",
    "REJECTD": "rejected",
    "EXPIRED": "expired",
    "UNKNOWN": "unknown",
    "": "no_dlr"
  ###
    Utlity function to validate a phone number
    Returns true or false depending on if it's valid phone
    number or not. Some examples below
    if value = 0                       -> return false
    if value +46#702137998             -> return false
    if value +46 702 13 79 98          -> return true
    if value 46702137998               -> return true
    if value is 72000                  -> return true
  ###
  validatePhone: (value, country) ->
    return false if not value? or value.length >= 20 or value.length < 3
    return true if /^\d{3,7}$/.test(value)
    return false unless value.match /^\+?[()\d- ]+$/

    value = '+' + value if value[0] isnt '+'
    country = if country then country.toUpperCase() else 'ZZ'
    phone = PhoneNumber.parse(value, country)

    return if phone and PhoneNumber.isValidNumber(phone) then true else false


  passwordStrength: (password = '') ->
    passCheckRes = utils.checkPassword(password)
    level = 0
    return level if not passCheckRes.valid
    level += 1 if passCheckRes.valid
    level += Math.min(passCheckRes.conditions, 4) - 1
    return level

  checkPassword: (string) ->
    result = {
      length: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
      conditions: 0,
      valid: false
    }
    wordCharArr = string.replace(/\W|\d/g, '').split('')
    result.lengthOK = string.length >= 16
    result.hasUppercase = wordCharArr.some((char) -> char is char.toUpperCase())
    result.hasLowercase = wordCharArr.some((char) -> char is char.toLowerCase())
    result.hasNumber = /\d/.test(string)
    result.hasSpecialChar = /\W/.test(string)
    conditions = ['hasUppercase', 'hasLowercase', 'hasNumber', 'hasSpecialChar']
    conditions.forEach((key) -> result.conditions += 1 if result[key])
    result.valid = result.lengthOK
    return result

  getSMSData: (text) ->
    data = {}
    data.GSM7 = @isGSM7(text)
    data.GSM7Extended = @isGSM7Extended(text)
    data.GSM7 = true if data.GSM7Extended
    data.Unicode = not data.GSM7

    data.encoding = if data.GSM7 then 'GSM7' else 'UNICODE'
    data.actualLength = @getStringCharacterCount(text, data.encoding)

    data.multipart = @isMultipart(data.actualLength, data.Unicode)
    data.nonGSM7Chars = @getNonGSM7Characters(text)
    data.msgMaxLength = if data.GSM7 then 160 else 70
    data.msgMaxLength = (if data.GSM7 then 153 else 66) if data.multipart

    data.multipartCount = Math.ceil(data.actualLength / data.msgMaxLength)
    data.charsToNextFull = (data.multipartCount * data.msgMaxLength) - data.actualLength
    data.currentSMSLength = data.actualLength % data.msgMaxLength
    # the next line is because niclas absolutely insisted. We want to show max full length before we tip over multipart count
    data.currentSMSLength = data.msgMaxLength if data.multipartCount >= 1 and data.currentSMSLength is 0
    return data

  getStringCharacterCount: (string, encoding) ->
    encoding = 'GSM7' if encoding is undefined
    length = 0
    if encoding is 'GSM7'
      length += string.length
      length += 1 for char in string when @GSM7MapExt.indexOf(char) isnt -1
    else if encoding is 'UNICODE'
      # This is apparently how we count bytelength for UTF16 strings according to marcus
      # He sat a while looking at UTF reference sheets and scratched his head, so I think it works
      bytelength = 0
      for char in string
        bytelength += if (char.charCodeAt(0) <= 0xffff) then 2 else 4
      length = bytelength / 2
    return length

  isGSM7: (message) ->
    #Mapping of ALL permitted GSM7 characters found in Unicode/UTF8 character
    #set. NB. The values used are those in the Unicode/UTF8 hex values table.
    return message.split('').every (char) => @GSM7Map.indexOf(char) isnt -1

  isGSM7Extended: (message) ->
    #Mapping of ALL permitted GSM7 characters found in Unicode/UTF8 character
    #set. NB. The values used are those in the Unicode/UTF8 hex values table.
    charMap = @GSM7Map.concat(@GSM7MapExt)
    return message.split('').every (char) => charMap.indexOf(char) isnt -1

  getNonGSM7Characters: (message) ->
    charMap = @GSM7Map.concat(@GSM7MapExt)
    chars = message.split('').filter (char) => charMap.indexOf(char) is -1
    message = chars.join('')
    characters = []
    # because silly coffeescript can't do regular for loops, we need a while loop
    i = 0
    while i<message.length
      code = message.charCodeAt(i)
      if code >= 0xd800 and code <= 0xdfff
        # surrogate pairs, push both at the same time and skip one index
        characters.push("#{message[i]}#{message[i+1]}")
        i = i + 2
      else
        characters.push(message[i])
        i = i + 1

    return _.uniq(characters)

  isMultipart: (messageLength, isUnicode) -> (isUnicode and messageLength > 70) or (not isUnicode and messageLength > 160)

  formatISO8601: (value) ->
    return '' unless value
    value = new Date(value).getTime() / 1000
    value = moment.unix(value)
    if value.isValid()
      value.format('L LT')
    else
      ''

  getPath: (obj, paths, defaultValue) ->
    return defaultValue if not obj?
    paths = paths.split('.')
    current = obj
    for path in paths
      current = current[path]
      break if not current?
    return if current? then current else defaultValue

# Prevent creating new properties and stuff.
Object.seal? utils

module.exports = utils
