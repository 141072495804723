
var config = { __loaded__: false }

config.__init = function init() {
  var requestConfig = {
    url: '/application-config.json',
    dataType: "json"
  }
  return $.ajax(requestConfig).success(function(response) {
    Object.assign(config, response, { __loaded__: true })
  })
}

module.exports = config
