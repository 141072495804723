View = require 'views/base/view'

module.exports = class ModalView extends View
  className: 'modal fade'
  container: 'body'
  containerMethod: 'prepend'
  template: null

  options: null

  modalOptions:
    backdrop: true
    keyboard: true
    show: true

  events:
    'show.bs.modal': 'modalShow'
    'hide.bs.modal': 'modalHide'
    'hidden.bs.modal': 'dispose'
    'submit form': 'submit'
    'click .modal-footer .btn-primary': 'submit'

  optionNames: View::optionNames.concat ['template']

  initialize: (options = {}) ->
    @options = _.extend {
      title: ''
      close: true
      footer: true
      header: true
      size: null
      primaryButton: 'Ok'
      secondaryButton: 'Cancel'
    }, @options, options
    super

  submit: (event) ->
    event.preventDefault()
    @dismiss()

  dismiss: ->
    @$el.modal 'hide'

  attach: ->
    super
    @$el.modal @modalOptions

  getTemplateData: ->
    data = super
    templateFunc = @getModalTemplateFunction()

    data.content = if typeof templateFunc is 'function'
      templateFunc data
    else
      ''
    data

  getTemplateFunction: ->
    (data) =>
      """
        <div class="modal-dialog#{if @options.size then " #{@options.size}" else '' }">
          <div class="modal-content">
            #{if @options.header then @getModalHeader() else ''}
            <div class="modal-body">#{data.content}</div>
            #{if @options.footer then @getModalFooter() else ''}
          </div>
        </div>
        """

  # Need to snatch this from the base view
  getModalTemplateFunction: View::getTemplateFunction

  getModalHeader: ->
    """
    <div class="modal-header">
      #{if @options.close then @getCloseButton() else ''}
      <h4 class="modal-title">#{@options.title}</h4>
    </div>
    """

  getModalFooter: ->
    """
    <div class="modal-footer">
      #{if @options.secondaryButton then @getSecondaryButton() else ''}
      #{if @options.primaryButton then @getPrimaryButton() else ''}
    </div>
    """

  getCloseButton: ->
    """
<button type="button" class="close" data-dismiss="modal" aria-hidden="true">
&times;</button>
    """

  getPrimaryButton: ->
    return '' unless @options.primaryButton
    """
    <button class="btn btn-primary">
      #{@options.primaryButton}
    </button>
    """

  getSecondaryButton: ->
    return '' unless @options.secondaryButton
    """
    <button class="btn btn-link" data-dismiss="modal">
      #{@options.secondaryButton}
    </button>
    """


  modalShow: ->
    $('body').addClass 'modal-active'

  modalHide: ->
    $('body').removeClass 'modal-active'

  dispose: ->
    return if @disposed
    delete @options
    delete @modalOptions
    super
    # Remove any eventual first backdrop
    $('.modal-backdrop').eq(0).remove()
