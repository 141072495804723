Collection = require 'models/base/collection'
Wallet = require './wallet'
mediator = require 'mediator'

module.exports = class Wallets extends Collection
  model: Wallet

  urlRoot: '/2/wallets/'

  initialize: ->
    super
    mediator.socket.addCallback(@subscribeSocket)
    @subscribeEvent "socket:disconnected", =>
      return if @disposed
      mediator.socket.addCallback(@subscribeSocket)
    @subscribeEvent "socket:wallet", @subscribeData

  subscribeSocket: () =>
    @publishEvent "socket:send", command: "walletsubscribe"    

  subscribeData: (data) ->
    model = @get(data.id)
    return unless model
    model.set data

  dispose: ->
    return if @disposed
    @publishEvent "socket:send", command: "walletunsubscribe"
    super
