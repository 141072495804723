
View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class MessageShowView extends View
  autoRender: false

  optionNames: View::optionNames.concat ['error']

  initialize: ->
    @listenTo @error, 'synced', @render
    super

  'getTemplateFunction': ->
    @model.set({ error: @error.toJSON() })
    (data) ->
      panel = switch data.dlr.status
        when 'DELIVRD' then 'panel-success'
        when 'UNDELIV', 'FAILED', 'DELETED' then 'panel-danger'
        when 'EXPIRED', 'REJECTD' then 'panel-warning'
        when 'MOSMS' then 'panel-info'
        else 'panel-default'
      status = if data.dlr.status
        """<strong>Status:</strong> #{ _.escape(data.dlr.status) }"""
      else
        ''
      html = """
      <div class="panel #{panel}">
        <div class="panel-heading">

          <div class="row">
            <div class="col-xs-6 col-xs-push-6 col-sm-4 col-sm-push-8 text-right">
              <small>#{moment.unix(data.timestamps.sms.in).format('L LT:ss')}</small>
            </div>
            <div class="col-xs-6 col-xs-pull-6 col-sm-4 col-sm-pull-4">
              <small><strong>From:</strong> #{ _.escape(utils.formatPhone(data.from.address)) }</small>
            </div>
            <div class="col-xs-12 col-sm-4 col-sm-pull-4">
              <small><strong>To:</strong> #{ _.escape(utils.formatPhone(data.to.address)) }</small>
            </div>
          </div>

        </div>

        <div class="panel-body">
          <p class="lead no-bottom-buffer">#{ _.escape(data.body) }</p>
        </div>

        <div class="panel-footer">

          <div class="row">
            <div class="col-xs-6 col-xs-push-6 col-sm-4 col-sm-push-8 text-right">
              <small>€#{ _.escape(data.price) }</small>
            </div>
            <div class="col-xs-6 col-xs-pull-6 col-sm-4 col-sm-pull-4">
              <small>#{ _.escape(data.connection.label) }</small>
            </div>
            <div class="col-xs-12 col-sm-4 col-sm-pull-4">
              <small>#{ status }</small>
            </div>
          </div>

        </div>

      </div>

      <div class="panel panel-default">

        <div class="panel-heading">
          <h2 class="panel-title">Metadata</h2>
        </div>

        <table class="table">
          <tbody>
            <tr>
              <td>Message Id</td>
              <td>#{ _.escape(data.id) }</td>
            </tr>
            <tr>
              <td>From</td>
              <td>#{ _.escape(utils.formatPhone(data.from.address)) }</td>
            </tr>
            <tr>
              <td>To</td>
              <td>#{ _.escape(utils.formatPhone(data.to.address)) }</td>
            </tr>
            <tr>
              <td>Price</td>
              <td>€#{ _.escape(data.price) }</td>
            </tr>
            <tr>
              <td>Connection</td>
              <td>#{ _.escape(data.connection.label) }</td>
            </tr>
      """
      if data.batch?
        html += """
            <tr>
              <td>Sendout</td>
              <td>#{ _.escape(data.batch.label) }</td>
            </tr>
            """
      #validity period cast to readable date time format in ltz
      if data.validity_period
        date = data.validity_period
        year = "20#{date[0]}#{date[1]}"
        month = "#{date[2]}#{date[3]}"
        day = "#{date[4]}#{date[5]}"
        hours = "#{date[6]}#{date[7]}"
        minutes = "#{date[8]}#{date[9]}"
        offsetWay = date[date.length - 1]
        nnVal = "#{date[date.length-3]}#{date[date.length-2]}"
        dateTime = moment(new Date(Date.UTC(year, month-1, day, hours, minutes)))

        if offsetWay is "+"
          data.validity_period = dateTime.add(parseInt(nnVal)/4,'hours').format('MMMM Do YYYY, h:mm a')
        else if offsetWay is "-"
          data.validity_period = dateTime.substract(parseInt(nnVal)/4,'hours').format('MMMM Do YYYY, h:mm a')

      html += """
            <tr>
              <td>Mcc Mnc</td>
              <td>#{ _.escape(data.mccmnc.mcc) } #{ _.escape(data.mccmnc.mnc) }</td>
            </tr>
            <tr>
              <td>Validity Period</td>
              <td>#{ _.escape(data.validity_period or 'None') }</td>
            </tr>
            <tr>
              <td>Sent</td>
              <td>#{moment.unix(data.timestamps.sms.in).format('L LT:ss')}</td>
            </tr>
            <tr>
              <td>Delivered</td>
              <td>#{if data.timestamps.sms.delivered then moment.unix(data.timestamps.sms.delivered).format('L LT:ss') else 'Not yet'}</td>
            </tr>
            <tr>
              <td>Dlr Sent</td>
              <td>#{if data.timestamps.dlr_out then moment.unix(data.timestamps.dlr_out).format('L LT:ss') else 'Not yet'}</td>
            </tr>
            <tr>
              <td>Data Coding</td>
              <td>#{ _.escape(data.data_coding) }</td>
            </tr>
      """
      if data.dlr and data.dlr.error
        html += """
            <tr>
              <td>Error code</td>
              <td>#{ _.escape(data.dlr.error) }</td>
            </tr>
            """
      if data.error
        html += """
            <tr>
              <td>Error description</td>
              <td>#{ if data.error.description then _.escape(data.error.description) else '<i>No error</i>' }</td>
            </tr>
            """
      html += """

          </tbody>

        </table>

      </div>

      """
      html
