View = require 'views/base/view'
utils = require 'lib/utils'
Model = require 'models/base/model'

module.exports = class ConnectionView extends View
  noWrap: true
  useRivets: true
  template: require './templates/connection'

  editing: false
  generateApiToken: false

  events:
    'click .edit-connection': 'editConnection'
    'click .cancel-edit': 'cancelEdit'
    'submit .connection-form': 'saveConnection'
    'click .generate-api-token': 'toggleApiGeneration'
    'click .request-password': 'requestPassword'

  initialize: ->
    super

  requestPassword: ->
    el = if @model.get 'password' then '.request-password-inline' else  '.request-password'
    throbber = @$(el).spin()
    @model.sync('read', @model,
      url: @model.urlFor "/2/connections/#{@model.get 'id'}/passwordreset"
    ).then (data) =>
      throbber.spin(false)
      @model.set password: data.password
    , (jqXHR) =>
      throbber.spin(false)
      @model.set errors: jqXHR.responseJSON.errors

  toggleApiGeneration: ->
    @generateApiToken = not @generateApiToken

  editConnection: (event) ->
    event.preventDefault()
    @editing = true

  cancelEdit: (event) ->
    event.preventDefault()
    @editing = false
    @model.fetch()
    @model.collection.remove @model if @model.isNew()

  saveConnection: (event) ->
    event.preventDefault()
    save = () =>
      throbber = @$('.save-connection').spin()
      handle = {
        success: () =>
          @editing = false
          # make it dirty to force backbone to refetch it
          @model.set('callbacks', undefined)
          @model.fetch()
        always: () => throbber.spin(false)
      }
      @model.save().success(handle.success).always(handle.always)

    if @generateApiToken
      tokenReset = new Model
      tokenReset.url = @model.urlFor "/2/connections/#{@model.get 'id'}/tokenreset"
      tokenReset.fetch
        success: =>
          @model.set {api_token: tokenReset.get 'api_token'}
          save()
      @toggleApiGeneration()
    else
      # stop the race conditions!
      save()

  render: ->
    @_parsley?.destroy()
    super
    @_parsley = @$('.connection-form').parsley() if @editing

  dispose: ->
    return if @disposed
    @_parsley?.destroy()
    delete @_parsley
    super
