View = require('views/base/view')
HlrBatchPreviewView = require('views/components/hlr-batch-preview-view')

mediator = require('mediator')
utils = require('lib/utils')

module.exports = class HlrBatchSendView extends View
  template: require('./templates/hlr-batch-send')

  useRivets: true

  events: {
    'submit form': 'send'
  }

  optionNames: View::optionNames.concat([
    'wallets',
    'toGroup',
    'connections',
    'contactgroups',
    'numbers',
    'customer',
    'contacts'
  ])

  constructor: ->
    @rivets = { formatters: { 'recipients': @formatRecipients } }
    super
    @contactsList = {}

  initialize: ->
    super
    @listenTo(@contactgroups, 'reset', @selectizeRecipients)
    @listenTo(@connections, 'reset', @checkConnections)
    @listenToOnce(
      @contactgroups, 'synced', => @_recipients?.addItem(@toGroup)
    ) if @toGroup


  formatRecipients: (values) ->
    if Array.isArray(values)
      values = values.map(_.escape)

    if values and values.lenght < 4
      return values.join(',')
    else if values
      return "<small>#{values.join(', ')}</small>"
    else
      return ''

  checkConnections: (connections) ->
    @connectionsHasSynced = true
    if @connections.length
      @selectizeConnections()

  # Add recipents to the model when the "To"-field is changed.
  updateRecipients: (values = []) =>
    recipients_names = []
    recipients_count = 0
    hlr = {}
    # Flush to and groups, keep the rest
    _.assign(hlr, @model.get('hlr') or {}, { to: [], groups: [] })
    for value in values
      group = @contactgroups.get(value)
      # Push contact groups to hlr attributes.
      if group
        recipients_names.push(group.get('name'))
        recipients_count += group.get('contacts_count')
        hlr.groups.push(group.id)
      # Push number to hlr attributes.
      else
        recipients_names.push(utils.formatPhone(value))
        recipients_count += 1
        hlr.to.push(value)

    @model.set({
      recipients_count: recipients_count,
      recipients_names: recipients_names,
      hlr: hlr
    })

  selectizeRecipients: ->
    @_recipients?.destroy()
    render = (item, escape) ->

      if item.contacts_count
        count = "#{escape(item.contacts_count)} Contact"
        count = count + 's' if item.contacts_count > 1

        return """
          <div>
            #{escape(item.name)}
            <small class="text-muted">#{count}</small>
          </div>
        """
      else
        return ''

    $contactgroups = @$('#recipients-select').selectize({
      plugins: ['remove_button']
      valueField: 'id'
      labelField: 'name'
      searchField: 'name'
      options: @contactgroups.toJSON()
      persist: false
      createOnBlur: true
      create: (value, callback) =>
        @model.unset('errors')
        if utils.validatePhone(value)
          sanitized = utils.sanitizePhone(value)
          formatted = utils.formatPhone(value)
          # If customer id exists = no self signup customer
          if mediator.user.get('trial') || mediator.user.get('customer').trial?
            callback({ id: sanitized, name: formatted })
          # Check so that recipient is the same as user registered phone
          else if sanitized is mediator.user.get('phones')[0].number
            callback({ id: sanitized, name: formatted })
          else
            @model.set({
              errors:
                ["""You may only send to your own number until you have made
                your first Payment. <a href="#{utils.reverse('wallets#index')}">
                Make a payment now!</a>"""] })
            false
        else
          @model.set({
            errors:
              ["#{value} seems to be an invalid number.
              Please enter your number like +46 73 600 75 00."]
          })
          false
      onChange: @updateRecipients
      render:
        { option: render }
    })

    @_recipients = $contactgroups[0].selectize

    # Set prepopulated values that stems from URL for example
    to = @model.get('to')
    return unless to
    group = @contactgroups.get(to)
    if group
      @_recipients.setValue([group.id])
    else
      name = to
      if _.isObject(@contactsList)
        name = @contactsList.firstname+' '+@contactsList.lastname

      @_recipients.addOption({ id: to, name: name })
      @_recipients.setValue([to])

  selectizeConnections: ->
    @_connections?.destroy()
    render = (item, escape) ->
      return """
        <div>
          <strong>#{escape(item.label)}</strong>
          <p class="text-muted no-bottom-buffer">
            <small>
              #{escape(item.wallet.name)} €#{escape(item.wallet.balance)}
            </small>
          </p>
        </div>
      """
    $_connections = @$('#connection').selectize({
      valueField: 'id'
      labelField: 'label'
      searchField: 'label'
      options: @connections.toJSON()
      onChange: (value) =>
        connection = @connections.get(value)
        return unless connection
        number = @numbers.findWhere({
          number: @model.get('from')
        })
        if (number and number.get(('keywords')[0].connection.id) isnt connection.get('id'))
          @model.setHlrProp
        wallet = connection.get('wallet')
        @model.set({
          connection_id: connection.id,
          connection_name: connection.get('label'),
          wallet_id: wallet.id,
          wallet_name: wallet.name,
          wallet_balance: wallet.balance
        })
      render: { option: render }
    })

    @_connections = $_connections[0].selectize

    # Check whatever the default connection is in @connections
    if @connections.get(mediator.user.get('default_connection'))
      connection = @connections.get(mediator.user.get('default_connection').id)
    # If so, use it, otherwise select the first connection in @connections
    if connection
      @_connections.setValue(connection.id)
    else if @connections.models.length
      @_connections.setValue(@connections.at(0).id)

  render: ->
    super

    # Render the hlr batch preview.
    @subview('hlr-preview', new HlrBatchPreviewView({
      container: @$('.hlr-preview')
      className: 'col-md-9'
      hlr: @model
      wallets: @wallets,
      connections: @connections
    }))

    # Use Parsley for form validation.
    @_parsley = @$('form').parsley()

  send: (event) ->
    event.preventDefault()
    errors = []
    @model.unset('errors')
    return if @_submitting
    @_submitting = true
    throbber = @$('.submit-hlr-batch').spin()

    alwaysFn = =>
      throbber.spin(false)
      @_submitting = false

    onSuccess = (data) =>
      if not data
        return @model.set({
          errors: ['Our service is currently unavailable. Please try again later.']
        })

      # Check for error messages
      for message in data
        if message.errors then for error in message.errors
          errors.push("#{message.to} - #{error.description}")

      # Successful submit, redirect to list of hlr batches.
      utils.redirectTo('hlr-batch#index')

    onError = (jqXHR) =>
      if jqXHR.responseJSON and jqXHR.responseJSON.errors
        for message in jqXHR.responseJSON.errors
          errors.push(message['description'])
      else
        errors.push('Our service is currently unavailable. Please try again later.')

      @model.set({ errors: errors })

    @model.send().then(onSuccess, onError).always(alwaysFn)

  dispose: ->
    return if @disposed
    @_recipients?.destroy()
    @_connections?.destroy()
    @_parsley?.destroy()
    modelprops = [
      'wallets',
      'connections',
      'contactgroups',
      'numbers',
      'customer',
      'contacts'
    ]
    extraprops = [
      '_recipients',
      '_parsley',
    ]
    props = modelprops.concat(extraprops)
    delete this[prop] for prop in props
    super
