View = require('views/base/view')
utils = require('lib/utils')

module.exports = class HlrBatchItemView extends View
  className: 'panel panel-default'
  useRivets: true
  template: require('./templates/hlr-batch-item')

  events: {
    'click .csv': 'fetchCsv'
  }

  constructor: ->
    @rivets = { binders: { 'status-width': @statusWidth } }
    super

  statusWidth: (el, value) =>
    width = '0%'
    width = (value / @model.get('total')) * 100 if value
    el.style.width = "#{width}%"

  fetchCsv: (event) ->
    id = event.currentTarget.getAttribute('data-id')
    url = "/2/hlrbatch/#{id}/results.csv?api_token=#{Chaplin.mediator.user.get('api_token')}"
    if @customer.get('pricelist_delimiter')
      delimiter = encodeURIComponent(@customer.get('pricelist_delimiter'))
      url += "&delimiter=#{delimiter}"
    window.location = @model.urlFor(url)

    downloadLink = @$(event.target)
    downloadLink.spin().addClass('disabled')
    setTimeout ( ->
      downloadLink.spin(false).removeClass('disabled')
    ), 5000
