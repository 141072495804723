View = require 'views/base/view'
utils = require 'lib/utils'
TrialHelpModal = require('./trial-help-modal')

module.exports = class HomePageView extends View
  className: 'home-page'
  template: require './templates/home'
  useRivets: true

  isTrial: ()->
    user = Chaplin.mediator.user
    isTrial = user.get('trial') || user.get('customer').trial
    return isTrial

  events:
    'click .opentrialmodal': 'openTrialHelp'

  openTrialHelp: () ->
    new TrialHelpModal()

