View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class WalletSettingsView extends View
  className: 'panel panel-default'
  template: require './templates/wallet-settings'

  useRivets: true

  events:
    'submit form': 'saveSettings'

  render: ->
    @_parsley?.destroy()
    super
    @_parsley = @$('form').parsley()

  saveSettings: (event) ->
    event.preventDefault()
    throbber = @$('.save-settings').spin()

    @model.save().then =>
      utils.redirectTo url: @model.reverse()
    , (jqXHR) =>
      @model.set errors: jqXHR.responseJSON.errors
      throbber.spin(false)

  dispose: ->
    return if @disposed
    @_parsley?.destroy()
    delete @_parsley
    super
