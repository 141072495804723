ModalView = require './modal-view'

module.exports = class PaypalConnectingView extends ModalView

  autoRender: false

  initialize: ->
    super
    @subscribeEvent 'paypal:connect', @render

  options:
    footer: false
    header: false

  modalOptions:
    backdrop: 'static'
    keyboard: false
    show: true

  getModalTemplateFunction: ->
    (data) -> """
    <h3>Connecting with paypal... <i class="fa fa-spin fa-circle-o-notch"></i></h3>
    """
