Controller = require 'controllers/base/controller'

Model = require 'models/base/model'
Customer = require 'models/customer'
Connections = require 'models/connections'
ContactGroups = require 'models/contact-groups'
Sendouts = require 'models/sendouts'
SendoutMoMessages = require 'models/sendout-mo-messages'
Numbers = require 'models/numbers'
Batches = require 'models/batches'
Wallets = require 'models/wallets'
Contacts = require 'models/contacts'
Error = require 'models/error'
Sendout = require 'models/sendout'

SendoutsNavbarView = require 'views/sendouts-navbar-view'

SendoutSendView = require 'views/sendout-send-view'
SendoutsView = require 'views/sendouts-view'
SendoutMoView = require 'views/sendout-mo-view'



module.exports = class SendoutsController extends Controller

  beforeAction: ->
    super

    return if @redirected

    @reuse('sendouts-navbar', SendoutsNavbarView, region: 'main')

  index: (params, route, options)->

    @adjustTitle 'Sendouts'
    @publishEvent 'pageHeader', 'Sendouts', description: 'Manage sendouts and see progress in real time'

    @collection = new Sendouts [], { subscribeBuffer: true, offset: options.query.offset or 0 }
    @model = new Model
    @customer = new Customer

    @collection.fetchWithStats()
    @customer.fetch()

    @view = new SendoutsView {@model, @collection, @customer, region: 'main'}


  edit: (params, route, options) ->

    @adjustTitle 'Edit Sendout'
    @publishEvent 'pageHeader', 'Edit Sendout', description: 'Make changes to your sendout'

    @sendout = new Sendout({id: params.id})

    @contactgroups = new ContactGroups
    @connections = new Connections
    @numbers = new Numbers
    @customer = new Customer

    # when we have a sendout we need to fetch the other data sources first
    $.when(
      @connections.fetchWithType(1)
      @contactgroups.fetch(reset: true, data: include_global: true)
      @numbers.fetch(reset: true)
      @customer.fetch()
    ).then () =>
      @sendout.fetch()

    wallets = @reuse 'wallets', Wallets

    @view = new SendoutSendView {
      model: @sendout,
      wallets: wallets,
      contactgroups: @contactgroups,
      connections: @connections,
      numbers: @numbers,
      customer: @customer,
      region: 'main'
    }


  create: (params, route, options) ->

    @adjustTitle 'Edit Sendout'
    @publishEvent 'pageHeader', 'New Sendout', description: 'Create a new sendout'

    @sendout = new Sendout

    @contactgroups = new ContactGroups
    @connections = new Connections
    @numbers = new Numbers
    @customer = new Customer

    groupId = undefined
    groupId = parseInt(options.query.group) if options.query?.group

    @connections.fetchWithType(1)
    @contactgroups.fetch(reset: true, data: include_global: true)
    @numbers.fetch(reset: true)
    @customer.fetch()

    wallets = @reuse 'wallets', Wallets

    @view = new SendoutSendView {
      model: @sendout,
      wallets: wallets,
      contactgroups: @contactgroups,
      connections: @connections,
      numbers: @numbers,
      customer: @customer,
      toGroup: groupId
      region: 'main'
    }

  show: (params) ->

    @adjustTitle 'Sendout details'
    @publishEvent 'pageHeader', 'Sendout details', description: 'View sendout details'

    @collection = new SendoutMoMessages([], {id: params.id})
    @model = new Sendout({id: params.id})

    @collection.fetch()
    @model.fetch()

    @view = new SendoutMoView({
      model: @model,
      collection: @collection,
      region: 'main'
    })
