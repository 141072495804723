Collection = require 'models/base/collection'
Contact = require './contact'
utils = require 'lib/utils'

module.exports = class Contacts extends Collection
  model: Contact

  pagination: 'contacts'
  comparator: 'name'
  count: 200
  offset: 0

  urlRoot: ->
    if @groupId
      "/2/contacts/?group=#{@groupId}&sort=name&include_global=true&count=#{@count}"
    else
      "/2/contacts/?sort=name&count=#{@count}"
  url: ->
    base = super
    base += "&offset=#{@offset}" if @offset > 0
    base

  initialize: (models, options = {}) ->
    @groupId = options.groupId or null
    @offset = parseInt(options.offset) or 0
    super

  getPrevPageURL: ->
    offset = @offset - @count
    options = {}
    options.offset = offset if offset > 0
    if @groupId
      utils.reverse 'contacts#group', {@groupId}, {offset}
    else
      utils.reverse 'contacts#index', {}, {offset}

  search: (query) ->
    url = "/2/search/contacts/?query=#{query}"
    url += "&group_id=#{@groupId}&include_global=true" if @groupId
    @fetch url: @urlFor(url), reset: true

  dispose: ->
    return if @disposed
    delete @groupId
    delete @offset
    delete @count
    super
