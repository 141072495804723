CollectionView = require 'views/base/collection-view'
MessagesListItemView = require 'views/messages-list-item-view'
utils = require 'lib/utils'

module.exports = class MessagesListView extends CollectionView
  itemView: MessagesListItemView

  listSelector: '.list-group'
  fallbackSelector: '.fallback'

  getTemplateFunction: ->
    (data) -> """
      <div class="list-group"></div>

      <div class="panel panel-default fallback">
        <div class="panel-body">
          <h3 class="text-center">No Messages</h3>
          <p class="text-center">
            <a href="#{utils.reverse 'messages#create'}" class="btn btn-default btn-lg">
              <i class="fa fa-plus"></i>
              New Message
            </a>
          </p>
        </div>
      </div>
    """
