Collection = require 'models/base/collection'
ConversationMessage = require './conversation-message'

module.exports = class ConversationMessages extends Collection
  model: ConversationMessage

  comparator: (model) ->
    model.get('timestamps')?.sms.in

  initialize: (models, options = {}) ->
    @conversationId = options.conversationId or null
    super
    @on 'reset', @checkCache

  checkCache: ->
    key = "conversation-#{@conversationId}"
    items = JSON.parse(localStorage.getItem(key)) or []
    cleanedItems = []
    for item in items
      model = @get(item.id)
      unless model
        cleanedItems.push item
        @add item
    if cleanedItems.length
      localStorage.setItem key, JSON.stringify(cleanedItems)
    else
      localStorage.removeItem(key)

  addCache: (data) ->
    key = "conversation-#{@conversationId}"
    items = JSON.parse(localStorage.getItem(key)) or []
    items.push data
    localStorage.setItem key, JSON.stringify items
    @checkCache()

  dispose: ->
    return if @disposed
    delete @conversationId
    super
