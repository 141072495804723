Collection = require 'models/base/collection'
Transaction = require './transaction'

module.exports = class Transactions extends Collection
  model: Transaction

  urlRoot: -> "/2/wallets/#{@walletId}/transactions/"

  comparator: '-timestamp'

  initialize: (models, options = {}) ->
    @walletId = options.walletId or null
    super

  dispose: ->
    return if @disposed
    delete @walletId
    super
