Controller = require 'controllers/base/controller'

Model = require 'models/base/model'
Customer = require 'models/customer'
Message = require 'models/message'
Messages = require 'models/messages'
Conversation = require 'models/conversation'
Conversations = require 'models/conversations'
ConversationMessages = require 'models/conversation-messages'
Connections = require 'models/connections'
ContactGroups = require 'models/contact-groups'
Sendouts = require 'models/sendouts'
Numbers = require 'models/numbers'
Wallets = require 'models/wallets'
Contacts = require 'models/contacts'
Error = require 'models/error'
InboxView = require 'views/inbox-view'
ConversationView = require 'views/conversation-view'
MessagesNavbarView = require 'views/messages-navbar-view'
MessagesListView = require 'views/messages-list-view'
MessagesLookupView = require 'views/messages-lookup-view'
MessageSendView = require 'views/message-send-view'
MessagesShowView = require 'views/message-show-view'



module.exports = class MessagingsController extends Controller

  beforeAction: ->
    super

    return if @redirected

    @reuse 'messages-navbar', MessagesNavbarView, region: 'main'

  index: ->
    @redirectTo 'messages#create'


  create: (params, route, options) ->
    @model = new Message options.query
    @connections = new Connections
    @numbers = new Numbers
    @customer = new Customer

    @adjustTitle 'New Message'
    @publishEvent 'pageHeader', 'New Message', description: 'Send single messages'

    @model.trigger 'change:name'
    wallets = @reuse 'wallets', Wallets
    @view = new MessageSendView {
      wallets: wallets,
      model: @model,
      connections: @connections,
      numbers: @numbers,
      customer: @customer,
      region: 'main'
    }

    @connections.fetchWithType(1)
    @numbers.fetch(reset: true)
    @customer.fetch()

  inbox: (params, route, options) ->
    @model = new Model
    @collection = new Conversations [], offset: options.query.offset or 0

    Chaplin.mediator.user.resetInboxCount()
    @listenTo Chaplin.mediator.user, 'change:inbox_count', =>
      unless @collection.isSyncing()
        @model.set query: ''
        @collection.fetch(reset: true)

    @adjustTitle 'Inbox'
    @publishEvent 'pageHeader', 'Inbox', description: 'View incoming messages and conversations'

    @view = new InboxView {@model, @collection, region: 'main'}

    @collection.fetch(reset: true)

  conversation: (params, route, options) ->
    @model = new Conversation id: params.conversationId
    @collection = new ConversationMessages [], conversationId: params.conversationId
    @reply = new Message

    @subscribeEvent 'socket:state:mo', (data) =>
      if @model.get('to') is data.to.address and
        @model.get('from')?['number'] is data.from.address
          @collection.addCache data
          Chaplin.mediator.user.decrementInboxCount()

    @subscribeEvent 'socket:state:mt', (data) =>
      message = @collection.get(data.id)
      return unless message
      data.pending = false
      message.set data
      @view.toggleScrollHeight()

    @listenTo @model, 'synced', (model) =>
      title = "Conversation with #{model.fromDisplayName()}"
      @adjustTitle title
      @publishEvent 'pageHeader', title

      @collection.reset @model.get('items')

      @reply.set
        show_cost: true
        connection: model.get('connection').id
        from: model.get('to')
        to: model.get('from').number

      setTimeout =>
        return if @disposed
        @model.fetch()
      , 120000

    @view = new ConversationView {@model, @collection, @reply, region: 'main'}

    @model.fetch().fail =>
      @redirectTo 'messages#inbox'

  outbox: (params, route, options) ->
    @adjustTitle 'Sent Messages'
    @publishEvent 'pageHeader', 'Sent Messages', description: 'View message history and delivery'

    # Inititialize Collections & Models
    @sendouts = new Sendouts
    @connections = new Connections
    @numbers = new Numbers
    @collection = new Messages
    @model = new Model

    @view = new MessagesLookupView {
      collection: @collection,
      model: @model,
      connections: @connections,
      numbers: @numbers
      sendouts: @sendouts,
      region: 'main'
    }

    @messagesView = new MessagesListView {
      collection: @collection,
      region: 'main'
    }

    @numbers.fetch(reset: true)
    @sendouts.fetch(reset: true)
    @connections.fetchWithType(1, reset: true)

    @model.set options.query
    @view.submitForm()

  show: (params) ->
    @adjustTitle 'Message'
    @publishEvent 'pageHeader', 'Message'

    @model = new Message id: params.messageId

    @error = new Error

    @view = new MessagesShowView {
      @model, @error, region: 'main'
    }

    # fetch error message for Message
    @model.fetch().then (data) =>
      @error.set {id: data.dlr.error}
      @error.fetch()
