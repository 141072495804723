Model = require '/models/base/model'
utils = require 'lib/utils'
mediator = require 'mediator'

module.exports = class ContactGroup extends Model

  apiAttrs: ['name', 'global']

  urlRoot: '/2/contacts/groups/'

  comparator: 'name'

  initialize: ->
    @set(isOwner: true) if @isNew()
    super

  parse: (response) ->
    response.isOwner = response.owner?.id is mediator.user.get('id')
    if mediator.user.get('customer')?
      response.tooltip = "This group is shared between all users under #{mediator.user.get('customer').name}."
      if response.isOwner
        response.tooltip += " You are the owner of this group."
      else
        response.tooltip += " Owner: #{response.owner?.name}."
    response

  reverse: =>
    return '#' if @isNew()
    url = utils.reverse 'contacts#group', groupId: @id
    return url

  setEdit: =>
    return unless @get 'isOwner'
    @set edit: true

  setDeleteGroup: =>
    return unless @get 'isOwner'
    @set deleteGroup: true

  saveGroup: (event) =>
    return unless @has('name')
    return if @get('name') is ''
    isNew = @isNew()
    # Check existing groups and if this group has the same name as some other, show warning
    # if we are editing the existing group and we save it with the same name, thats also ok
    # but also during the editing we cannot use name as some existing group
    for group in @collection.models
      if (@get('name') is group.get('name') && ((isNew && group.get('id')?) || (not isNew and (@id isnt group.get('id')))))
        @set errors: ["Group with name #{@get('name')} already exists!"]
        return
    @save(edit: false).then =>
      return if @disposed
      return unless isNew
      utils.redirectTo 'contacts#group', groupId: @id

  destroy: =>
    if @isNew() and @collection
      @collection.remove this
    else
      super {}

  deleteContacts: =>
    if @isNew() and @collection
      @collection.remove this
    else
      @sync('delete', this, {url: @urlFor("/2/contacts/groups/members/#{@get('id')}"), success: @destroy})
