CollectionView = require 'views/base/collection-view'
SendoutMoItemView = require 'views/sendout-mo-item-view'
utils = require 'lib/utils'

module.exports = class SendoutMoView extends CollectionView
  template: require './templates/sendout-mo'

  listSelector: 'tbody'
  useRivets: true

  itemView: SendoutMoItemView

  events:
    'click .download-csv': 'fetchCsv'

  initialize: (models, options = {}) ->
    @subscribeEvent 'socket:state:mt', @onSocketMTData
    @subscribeEvent 'socket:state:update', @onSocketSendoutUpdate
    @model.on('reset', () => @render() )
    @moInterval = setInterval(@updateMTs, 250)
    @resetSocketDataCache()
    @rivets =
      binders:
        'status-width': @statusWidth
    super

  onSocketSendoutUpdate: (data) ->
    return if not data.id and data.id is @model.get('id')
    @model.set({
      processed: data.processed
      status: data.status
    })

  resetSocketDataCache: () ->
    @socketDataCache = {'no_dlr': 0}

  onSocketMTData: (data) ->
    # Not all data contains a batch id
    return if not data.batch and data.batch.id
    return if parseInt(data.batch.id) != @model.get('id')
    # Build the proper hashmap structure if not preset already
    status = utils.dlrMap[data.dlr.status]
    # Don't handle no_dlr. We have no proper way of actually measuring this
    if status isnt 'no_dlr'
      @socketDataCache[status] = 0 if not @socketDataCache[status]
      @socketDataCache[status] += 1

  updateMTs: =>
    @model.mergeSocketStats(@socketDataCache)
    @resetSocketDataCache()

  statusWidth: (el, value) =>
    width = '0%'
    width = (value / @model.get('total')) * 100 if value
    @$(el).attr('style', "width: #{width}%")

  fetchCsv: (event) ->
    id = @model.get('id')
    window.location = @model.urlFor("/2/sendouts/#{id}/messages.csv?api_token=#{Chaplin.mediator.user.get('api_token')}")
    # Disable the link and show the spinner, after 5 seconds enable it again
    downloadLink = @$(event.target)
    downloadLink.spin().addClass('disabled')
    setTimeout ( ->
      downloadLink.spin(false).removeClass('disabled')
    ), 5000

  dispose: () ->
    return if @disposed
    super
    clearInterval(@moInterval)
