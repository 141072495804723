View = require 'views/base/view'
MessageInputView = require 'views/components/message-input-view'
MessagePreviewView = require 'views/components/message-preview-view'

mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class MessageSendView extends View
  template: require './templates/sendout-send'

  useRivets: true

  events:
    'submit form': 'send'
    'click #expirydatetime': 'pickExpiryDate'
    'click #senddatetime': 'pickSendDate'
    'click .open-help': 'openHelpModal'


  optionNames: View::optionNames.concat ['wallets', 'toGroup', 'connections', 'contactgroups', 'numbers', 'customer', 'contacts']


  constructor: ->
    @rivets =
      formatters:
        'recipients': @formatRecipients

    super

    @contactsList = {}

  initialize: ->
    super
    @listenTo @contactgroups, 'reset', @selectizeRecipients
    @listenTo @connections, 'reset', @checkConnections
    @listenTo @numbers, 'synced', @selectizeFrom
    @listenTo @customer, 'synced', @prefillVerifiedNumber
    @listenToOnce @model, 'sync', @onFetchSendout
    @listenToOnce(@contactgroups, 'synced', ()=> @_recipients?.addItem(@toGroup)) if @toGroup

  openHelpModal: () ->
    new SendoutHelpModal()

  isTrial: ()->
    user = Chaplin.mediator.user
    isTrial = user.get('trial') || user.get('customer').trial
    return isTrial

  formatRecipients: (values) ->
    if Array.isArray values
      values = values.map _.escape
    if values and values.length < 4
      values.join(', ')
    else if values
      return "<small>#{values.join(', ')}</small>"
    else ''

  changedRecipients: (values = []) =>
    recipients_names = []
    recipients_count = 0
    sms = {}
    # flush to and groups, keep the rest
    _.assign(sms, @model.get('sms') or {}, { to: [], groups: [] })
    for value in values
      group = @contactgroups.get(value)
      if group
        recipients_names.push group.get('name')
        recipients_count += group.get('contacts_count')
        sms.groups.push(group.id)
      else
        recipients_names.push utils.formatPhone value
        recipients_count += 1
        sms.to.push(value)

    @model.set({
      recipients_count: recipients_count,
      recipients_names: recipients_names,
      sms: sms
    })


  checkConnections: (connections) ->
    @connectionsHasSynced = true
    if @connections.length
      @selectizeConnections()

  selectizeRecipients: ->
    @_recipients?.destroy()
    render = (item, escape) ->
      count = if item.contacts_count is 1
        '1 Contact'
      else if item.contacts_count
        "#{escape(item.contacts_count)} Contacts"
      else
        ""
      if count isnt ""
        """
          <div>
            #{escape(item.name)}
            <small class="text-muted">#{count}</small>
          </div>
        """
      else
        """
        """

    $contactgroups = @$('#recipients').selectize
      plugins: ['remove_button']
      valueField: 'id'
      labelField: 'name'
      searchField: 'name'
      options: @contactgroups.toJSON()
      persist: false
      createOnBlur: true
      create: (value, callback) =>
        @model.unset 'errors'
        if utils.validatePhone value
          sanitized = utils.sanitizePhone value
          formatted = utils.formatPhone value
          # if customer id exists = no self signup customer

          if mediator.user.get('trial') || mediator.user.get('customer').trial?
            callback(id: sanitized, name: formatted)
          # check so that recipient is the same as user registered phone
          else if sanitized is mediator.user.get('phones')[0].number
            callback(id: sanitized, name: formatted)
          else
            @model.set({ errors: ["""You may only send to your own number until you have made your first Payment. <a href="#{utils.reverse 'wallets#index'}">Make a payment now!</a>"""] })
            false
        else
          @model.set({ errors: ["#{value} seems to be an invalid number. Please enter your number like +46 73 600 75 00."] })
          false
      onChange: @changedRecipients
      render:
        option: render
    @_recipients = $contactgroups[0].selectize

    # Set prepopulated values that stems from URL for example
    to = @model.get('to')
    return unless to
    group = @contactgroups.get(to)
    if group
      @_recipients.setValue [group.id]
    else
      name = to

      if _.isObject @contactsList
        name = @contactsList.firstname+' '+@contactsList.lastname

      @_recipients.addOption {id: to, name: name}
      @_recipients.setValue [to]

  selectizeConnections: ->
    @_connections?.destroy()
    render = (item, escape) ->
      """
        <div>
          <strong>#{escape(item.label)}</strong>
          <p class="text-muted no-bottom-buffer">
            <small>#{escape(item.wallet.name)} €#{escape(item.wallet.balance)}</small>
          </p>
        </div>
      """
    $_connections = @$('#connection').selectize
      valueField: 'id'
      labelField: 'label'
      searchField: 'label'
      options: @connections.toJSON()
      onChange: (value) =>
        connection = @connections.get(value)
        return unless connection
        number = @numbers.findWhere {
          number: @model.get('from')
        }
        if number and number.get('keywords')[0].connection.id isnt connection.get 'id'
          @model.setSMSProp 'from', ''
          @model.set 'from', ''
          @selectizeFrom()
        wallet = connection.get('wallet')
        @model.set
          connection_id: connection.id,
          connection_name: connection.get('label'),
          wallet_id: wallet.id,
          wallet_name: wallet.name,
          wallet_balance: wallet.balance
      render:
        option: render

    @_connections = $_connections[0].selectize
    # Check whatever the default connection is in @connections
    if @connections.get(mediator.user.get('default_connection'))
      connection = @connections.get(mediator.user.get('default_connection').id)
    # If so, use it, otherwise use the first connection in @connections
    if connection
      @_connections.setValue(connection.id)
    else if @connections.models.length
      @_connections.setValue(@connections.at(0).id)

  selectizeFrom: ->
    render = (item, escape) =>
      if item.opt_group is "longcodes" and @connections.length > 1
        return """
          <div>
            #{escape(item.text)}
            <p class="text-muted no-bottom-buffer">
              <small class="clearfix"><i>#{escape(item.country)} <span class="pull-right">#{escape(item.connection.name)}<span></i></small>
            </p>
          </div>
        """
      else
        return "<div>#{escape(item.text)}</div>"

    current = @model.get('from')

    if @_from
      @_from.destroy()

    priorityNumbers = []


    phones = mediator.user.get('phones')
    results = _.map phones, (phone) =>
      sanitized = utils.sanitizePhone(phone.number)
      priorityNumbers.push sanitized
      {value: sanitized, text: utils.formatPhone(phone.number), opt_group: 'my'}

    recent = mediator.user.get('recent_alphas') or []
    for phone in recent
      if (phone.match(/^[a-zA-Z0-9 ]+$/) or utils.validatePhone(phone)) and 2 <= phone.length <= 17
        results.push {value: utils.sanitizePhone(phone), text: phone, opt_group: 'alpha'}

    for longcode in @numbers.models
      sanitized = utils.sanitizePhone(longcode.get('number'))
      priorityNumbers.push sanitized
      results.push {
        value: sanitized
        country: longcode.get('country')
        connection: longcode.get('keywords')[0].connection
        text: utils.formatPhone(longcode.get('number'))
        opt_group: 'longcodes'
      }


    # if we are editing a sendout with an alphanumeric it has to exist in the list
    prefilled = @model.get('sms').from
    if prefilled
      exists = results.some (item)-> item.value == prefilled
      item = {
        value: @model.get('sms').from
        text: utils.formatPhone(@model.get('sms').from)
        opt_group: 'alpha'
      }
      results.push item if not exists


    $_from = @$('#from')

    $_from.selectize
      optgroups: [
        {value: 'my', label: 'My Verified Numbers'},
        {value: 'longcodes', label: 'Leased Numbers'},
        {value: 'alpha', label: 'Recently used alphanumerics'}
      ]
      optgroupField: 'opt_group',
      optgroupOrder: ['alpha', 'my', 'longcodes'],
      options: results
      createOnBlur: true
      render:
        option: render
      create: (value, callback) =>
        # We only check for errors here, you are not allowed to create an item that is faulty
        @model.unset 'errors'
        errors = []

        # value.length 2-17
        if 2 > value.length or value.length > 17
          errors.push('Sender ID must be between 2 and 17 characters')

        else if not utils.validatePhone(value)
          if value.length > 11
            errors.push("Alphanumeric sender ID must be 2 to 11 characters long. Your sender ID contains #{value.length} characters")
          if not utils.isGSM7(value)
            notAllowedChars = _.uniq(utils.getNonGSM7Characters(value))
            errors.push("All characters must be included in GSM7 (#{notAllowedChars.join(', ')} is not)")

        if(errors.length)
          @model.set { errors: errors }
          return callback(false)


        sanitized = utils.sanitizePhone(value)
        if sanitized in priorityNumbers
          return callback {value: sanitized, text: value}
        else
          recent = _.clone(mediator.user.get('recent_alphas')) or []
          recent.unshift(value) if recent.indexOf(value) is -1
          mediator.user.set 'recent_alphas', recent.slice(0, 5)
          callback {value: sanitized, text: value, opt_group: 'alpha'}

      onChange: (senderID) =>
        # We only check for warnings here, you CAN use sender ID's that are not directly faulty
        @model.unset 'errors'
        @model.unset 'warnings'
        warnings = []

        # checks for alphanumeric warnings
        if not utils.validatePhone(senderID) and senderID.length
          # alphanumeric is recommended to use characters a-zA-Z0-9 only (we cover spaces in separate conditional)
          if /[^a-z\d ]/gi.test(senderID)
            warnings.push('Some operators restricts sender ID\'s to a-z, A-Z & 0-9. Check with your account manager if you have questions')

          # Spaces is not recommended
          if /[ ]/gi.test(senderID)
            warnings.push('Some phones does not support spaces in sender ID')

          # alphanumeric is recommended to be at least 3 characters long
          if senderID.length < 3
            warnings.push('Some phones does not support sender ID\'s with less than 2 characters')

        @model.set { warnings: warnings } if warnings.length

        @model.setSMSProp('from', senderID)
        @model.set {from: senderID}
        number = @numbers.findWhere({number: senderID})
        @_connections.setValue(number.get('keywords')[0].connection.id) if number

    @_from = $_from[0].selectize
    # TODO: Fix so that when changing connections, and current is set,
    # the value on this stupid control is reupdated
    @_from.setValue(current) if current

  prefillVerifiedNumber: (value) ->
    # Check if user is in trial mode
    if value.id == 2 || value.trial
      primaryNumber = null
      phones = mediator.user.get('phones')
      for phone in phones
        if (phone.primary_number == true and phone.verified == true)
          primaryNumber = phone.number

      $_from = @$('#from')
      @_from.setValue(primaryNumber) if primaryNumber

  pickSendDate: =>
    _.defer @_sendDate.open

  pickSendTime: (data) =>
    return @model.unset('send_time') if data['clear'] is null
    return if not data['select']
    @_expiryDate.set('min', @_sendDate.get("select"))
    if @_sendDate.get() is moment().format('YYYY-MM-DD')
      @_sendTime.set('min', true)
    else
      @_sendTime.set('min', 0)
    _.defer @_sendTime.open

  setSendDateTime: (data) =>
    return if not data['select']
    sendoutDate = @_sendDate.get()
    sendoutTime = @_sendTime.get() or '12:00'

    if sendoutDate
      sendoutDate = sendoutDate.split("-")
      sendoutTime = sendoutTime.split(":")
      @model.set({ send_time: moment("#{sendoutDate} #{sendoutTime}",'YYYY-MM-DD HH:mm').unix() })
    else
      @model.unset('send_time')

  pickExpiryDate: =>
    _.defer @_expiryDate.open

  pickExpiryTime: (data) =>
    return @model.setSMSProp('validity_period', null) if data['clear'] is null
    return if not data['select']
    @_sendDate.set('max', @_expiryDate.get("select"))
    if @_expiryDate.get() is moment().format('YYYY-MM-DD')
      @_expiryTime.set('min', true)
    else
      @_expiryTime.set('min', 0)
    _.defer @_expiryTime.open

  setExpiryDateTime: (data) =>
    expiryDate = @_expiryDate.get()
    expiryTime = @_expiryTime.get() or '12:00'

    if expiryDate
      expiryDate = expiryDate.split("-")
      expiryTime = expiryTime.split(":")
      @model.setSMSProp('validity_period', moment("#{expiryDate} #{expiryTime}",'YYYY-MM-DD HH:mm').unix())
    else
      @model.unsetSMSProp('validity_period')

  send: (event) ->
    event.preventDefault()
    return if @_sending
    @_sending = true
    errors = []
    @model.unset('errors')
    # Checking and notify user about enough funds on his/her account
    currentWallet = @wallets.get({id:@model.get('wallet_id')})
    if(currentWallet)
      availableFunds = currentWallet.get('balance') - currentWallet.get('max_negative_balance')
      if @model.get('total_cost') > availableFunds
        errors.push("Minimum balance for this wallet is #{currentWallet.get('max_negative_balance')}, this sendout would cost too much")
        @model.set({ errors: errors })
        return

    throbber = @$('.send-message').spin()

    always = () =>
      throbber.spin false
      @_sending = false
    done = (data) =>
      if not data
        errors.push('Our messaging service is currently unavailable. Please try again later.')
        @model.set({ errors: errors })
        return

      for message in data
        if message.errors then for error in message.errors
            errors.push("#{message.to} - #{error.description}")

      # great success!
      utils.redirectTo('sendouts#index')

    fail = (jqXHR) =>
      if jqXHR.responseJSON.errors
        for message in jqXHR.responseJSON.errors
          errors.push(message['description'])

      @model.set({ errors: errors })

    send = @model.save()
    send.done(done).fail(fail).always(always)

  render: ->
    super
    @subview 'message-input', new MessageInputView
      container: @$('.message-input')
      className: 'col-md-9'
      sendout: @model

    @subview 'message-preview', new MessagePreviewView
      container: @$('.message-preview')
      sendout: @model
      wallets: @wallets
      connections: @connections

    sendoutDate = @$('#senddate').pickadate({ min: new Date(), firstDay: true, format: 'yyyy-mm-dd', onSet: @pickSendTime })
    sendoutTime = @$('#sendtime').pickatime({ format: 'HH:i', onSet: @setSendDateTime })
    @_sendDate = sendoutDate.pickadate('picker')
    @_sendTime = sendoutTime.pickatime('picker')

    expiryDate = @$('#expirydate').pickadate({ min: new Date(), firstDay: true, format: 'yyyy-mm-dd', onSet: @pickExpiryTime })
    expiryTime = @$('#expirytime').pickatime({ format: 'HH:i', onSet: @setExpiryDateTime })
    @_expiryDate = expiryDate.pickadate('picker')
    @_expiryTime = expiryTime.pickatime('picker')

    @_expiryDate.set "min", if @_sendDate.get("select") then @_sendDate.get("select") else new Date()
    @_sendDate.set "max", @_expiryDate.get("select") if @_expiryDate.get("select")
    @_parsley = @$('form').parsley()

  onFetchSendout: () ->
    smsProps = @model.get('sms')

    # set recipient names
    recipients_names = []
    if @model.get('sms').groups
      for group in smsProps.groups
        @_recipients.addItem [group]
    if smsProps.to
      for to in smsProps.to
        recipients_names.push utils.formatPhone to
        @_recipients.addOption {id: to, name: to}
        @_recipients.addItem to

    @selectizeFrom()
    @_from.setValue(smsProps.from)

    @_connections.setValue @model.get('connection_id')

  dispose: ->
    return if @disposed
    @_recipients?.destroy()
    @_connections?.destroy()
    @_parsley?.destroy()
    modelprops = ['wallets', 'connections', 'contactgroups', 'numbers', 'customer', 'contacts']
    extraprops = ['_recipients', '_parsley', '_from', '_expiryDate', '_expiryTime', '_sendDate','_sendTime']
    props = modelprops.concat(extraprops)
    delete this[prop] for prop in props
    super
