ModalView = require './modal-view'

module.exports = class ShowTokenView extends ModalView

  autoRender: false

  listen:
    'sync collection': 'populateData'

  events:
    'click #selectButton': 'selectText'

  populateData:->
    @model = @collection.at(0)
    @$('#alert-box').hide()
    @$('#token-container').val(@model.get('api_token'))
    @$('#token-container').focus()
    @$('#token-container').select()

    @$('#token-container').on 'copy' , =>
      @$('#alert-box').show 'true'

  options:
    footer: false
    header: true
    title: 'Your API access token'

  modalOptions:
    backdrop: 'static'
    keyboard: false
    show: true

  getModalTemplateFunction: ->
    (data) -> """
    <p>
      Use <kbd>Ctrl</kbd> + <kbd>C</kbd>    ( <kbd>&#8984</kbd> + <kbd>C</kbd> ) to copy your token
    </p>
    <div class="input-group">
      <input type="text" id="token-container" class="form-control" readonly>
      <span id="selectButton" class="input-group-addon">
        <abbr title="Click to select text for copying"><i class="fa fa-clipboard fa-fw"></i></abbr>
      </span>
    </div>
    <p id="alert-box" class="alert alert-success top-buffer" role="alert">You have successfully copied the token</p>
    """

  selectText: =>
    @$('#token-container').focus()
    @$('#token-container').select()


