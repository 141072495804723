CollectionView = require 'views/base/collection-view'
PricelistItemView = require 'views/pricelist-item-view'
mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class PricelistView extends CollectionView
  template: require './templates/pricelist'
  itemView: PricelistItemView

  useRivets: true
  renderItems: false

  listSelector: '.panel-group'
  fallbackSelector: '.fallback'

  listen:
    'change:query model': 'filterItems'

  events:
    'click .download-pricelist': 'download'

  optionNames: CollectionView::optionNames.concat ['connections']

  download: (event) ->
    event.preventDefault()
    window.location = @model.urlFor("/2/pricelists/#{@model.get('account').id}.csv?api_token=#{mediator.user.get('api_token')}")

  filterItems: (model, query, options) ->
    query = query.toLowerCase()
    @filter (country) ->
      ret = true

      return ret unless query

      name = country.get('name')
      prefix = country.get("prefix")
      mcc = country.get('mcc')
      operators = (network.operator for network in country.get('networks'))

      needle = "#{name}|#{mcc}|#{prefix}|"+ operators.join('|')

      ret and needle.toLowerCase().indexOf(query) isnt -1

  render: ->
    @_selectize?.destroy()
    super
    return unless @connections.length > 1
    $selectize = @$('.connections-select').selectize
      valueField: 'id',
      labelField: 'label',
      searchField: 'label',
      options: @connections.toJSON()
      onChange: @redirect
    @_selectize = $selectize[0].selectize
    @_selectize.setValue @model.get('account').id

  redirect: (id) =>
    utils.redirectTo 'pricelists#show', connectionId: id

  dispose: ->
    return if @disposed
    @_selectize?.destroy()
    delete @_selectize
    super
