View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class VerifyPhoneView extends View
  template: require './templates/verify-email'

  useRivets: true

  initialize: ->
    super

    @model.sync('read', @model, {
      url: @model.urlFor "/2/users/email/#{@model.get 'hash'}"
    }).then (data) =>
      window.location = @model.get 'forwardURL' if @model.get('forwardURL')?
      @model.set
        progress: false
        success: true
    , (jqXHR) =>
      @model.set
        progress: false
        errors: if jqXHR.status is 404
          ['Invalid or expired verification hash']
        else
          jqXHR.responseJSON.errors
