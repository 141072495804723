ModalView = require './modal-view'

module.exports = class DatepickerView extends ModalView

  useRivets: true

  options:
    footer: true
    header: false

  modalOptions:
    backdrop: 'static'
    keyboard: false
    show: true

  events:
    'click #to-datetime': 'pickBeforeDate'
    'click #from-datetime': 'pickAfterDate'

  pickBeforeDate: =>
    _.defer @_to_date.open

  pickBeforeTime: (context) =>
    return @model.unset('to_date') if 'clear' of context
    return unless 'select' of context
    _.defer @_to_time.open

  pickAfterDate: =>
    _.defer @_from_date.open

  pickAfterTime: (context) =>
    return @model.unset('from_date') if 'clear' of context
    return unless 'select' of context
    _.defer @_from_time.open

  setBeforeDateTime: =>
    @setDateTime 'to_date', @_to_date.get(), @_to_time.get()

  setAfterDateTime: =>
    @setDateTime 'from_date', @_from_date.get(), @_from_time.get()

  setDateTime: (attr, date, time) ->
    if date and time
      @model.set attr, moment("#{date} #{time}", 'YYYY-MM-DD HH:mm').unix()
    else
      @model.unset(attr)

  render: ->
    super

    to_date = @$('#to-date').pickadate {
      firstDay: true
      format: 'yyyy-mm-dd'
      onSet: @pickBeforeTime
      min: moment().subtract('1', 'month').toDate()
    }
    to_time = @$('#to-time').pickatime {
      format: 'HH:i'
      onSet: @setBeforeDateTime
    }
    @_to_date = to_date.pickadate('picker')
    @_to_time = to_time.pickatime('picker')

    from_date = @$('#from-date').pickadate {
      firstDay: true
      format: 'yyyy-mm-dd'
      onSet: @pickAfterTime
      min: moment().subtract('1', 'month').toDate()
    }
    from_time = @$('#from-time').pickatime {
      format: 'HH:i'
      onSet: @setAfterDateTime
    }
    @_from_date = from_date.pickadate('picker')
    @_from_time = from_time.pickatime('picker')

  submit: ->
    super
    @model.trigger 'dateModal:closed'

  getModalTemplateFunction: ->
    (data) -> """
      <form class="form-horizontal" role="form">
        <div class="form-group">
          <label for="from-date" class="col-sm-3 control-label">From date</label>
          <div class="col-sm-9">
            <input type="text" class="form-control picker__input" readonly="readonly" id="from-datetime" rv-value="model:from_date | moment LL LT">
            <input type="text" id="from-date" class="hidden">
            <input type="text" id="from-time" class="hidden">
          </div>
        </div>
        <div class="form-group">
          <label for="to-date" class="col-sm-3 control-label">To date</label>
          <div class="col-sm-9">
            <input type="text" class="form-control picker__input" readonly="readonly" id="to-datetime" rv-value="model:to_date | moment LL LT">
            <input type="text" id="to-date" class="hidden">
            <input type="text" id="to-time" class="hidden">
          </div>
        </div>
      </form>
    """
