Collection = require 'models/base/collection'
View = require 'views/base/view'
utils = require 'lib/utils'
mediator = require 'mediator'

module.exports = class ContactView extends View
  tagName: 'li'
  className: 'list-group-item clearfix'

  listen:
    'change:edit model': 'render'

  events:
    'click .delete-contact': 'deleteContact'
    'click .edit-contact': 'editContact'
    'click .cancel-edit': 'cancelEdit'
    'submit .contact-form': 'saveContact'
    'blur .phone-input': 'loadModel'

  initalize: ->
    @rivets =
      formatters:
        phone:
          read: (value) =>
            if value? then utils.formatPhone(value.replace /\s0/, ' ') else ''
          publish: (value) =>
            if value? then utils.sanitizePhone(value) else ''
    super

  deleteContact: (event) ->
    return unless @group.get('isOwner')
    event.preventDefault()
    event.stopPropagation()
    if confirm("Delete #{@model.get('firstname')} #{@model.get('lastname')}?\nWarning! contact will be removed from all groups")
      # need to make new refernce to @groups because its undefined after @model.destroy()
      $groupsRef = @groups
      @model.destroy().then =>
        $groupsRef.fetch(reset: true)

  editContact: (event) ->
    return unless @group.get('isOwner')
    event.preventDefault()
    event.stopPropagation()
    @model.set edit: true
    @previousModel = @model.clone()

  cancelEdit: (event) ->
    event.preventDefault()
    if @model.isNew() or @model.get('preloaded')
      @model.collection.remove @model
    else
      @model.set @previousModel.toJSON()
      @model.set edit: false

  saveContact: (event) ->
    event.preventDefault()
    throbber = @$('.save-contact').spin()
    @model.save().then =>
      @model.set(edit: false)
      # If preloaded is still true, calling cancelEdit would remove it from the collection
      @model.set({preloaded: false}) if @model.get('preloaded')
      @groups.fetch(reset: true, data: include_global: true)
      @model.collection.fetch(reset: true, data: include_global: true)
    , (response) =>
      @model.set errors: response.responseJSON.errors
      throbber.spin(false)

  render: ->
    return unless @group.isSynced() or @group.isNew()
    @_parsley?.destroy()
    @useRivets = @model.get('edit') or false
    super
    if @useRivets
      @_parsley = @$('.contact-form').parsley()
      @selectizeGroups()
    @renderPhoneInput()

  renderPhoneInput: ->
    $phone = @$("#phone-#{@model.cid}")
    $phone.intlTelInput {
      preferredCountries: []
    }

  getTemplateData: ->
    data = super
    data.displayName = @model.displayName()
    data.isOwner = @group.get('isOwner')
    data

  getTemplateFunction: ->
    if @model.get('edit')
      @editTemplate
    else
      @defaultTemplate

  loadModel: =>
    #return unless @model.isNew() and @model.get('msisdn')
    collection = new Collection
    collection.urlRoot = "/2/contacts/msisdn/#{@model.get('msisdn')}"
    collection.fetch().then (response) =>
      existingModel = @model.collection.get(response.id)
      @model.collection.remove existingModel if existingModel?
      @model.set(response)
      # Add current group to the loaded model
      @model.get('groups').push({id: @model.collection.groupId})
      # Set preloaded so that we know to remove this from the collection on cancelEdit
      # model.isNew() returns false if the model has an id
      @model.set({'preloaded': true})
      @selectizeGroups()

  selectizeGroups: ->
    @_groups?.destroy()
    options = @groups.toJSON()
    filteredOptions = options.filter (group) ->
      group.owner.id is mediator.user.get 'id'
    $groups = @$("#groups-#{@model.cid}").selectize
      plugins: ['remove_button']
      valueField: 'id'
      labelField: 'name'
      searchField: 'name'
      options: filteredOptions
      onChange: (values) =>
        groupList = []
        if values
          for value in values
            groupList.push {id: value}
        @model.set {groups: groupList}
    @_groups = $groups[0].selectize
    # populate field with values already in the model
    @_groups.addItem(group.id) for group in @model.get('groups')


  defaultTemplate: (data) =>
    href = utils.reverse('messages#create', {}, { to: _.escape(data.msisdn) })
    ret =
      """
      <div class="btn-group pull-right">
        <a href="#{ href }" class="btn btn-default" title="Send new message">
          <i class="fa fa-pencil-square-o fa-fw"></i>
        </a>
      """
    if data.isOwner
      ret +=
        """
          <button class="btn btn-default edit-contact" title="Edit contact">
            <i class="fa fa-pencil fa-fw"></i>
          </button>
          <button class="btn btn-danger delete-contact" title="Delete contact"><i class="fa fa-trash-o"></i></button>
        """
    ret +=
      """
      </div>
      <div class="pull-left">
        <h4 class="list-group-item-heading">#{ data.displayName }</h4>
        <p class="list-group-item-text"><i class="fa fa-phone"></i> #{_.escape(utils.formatPhone(data.msisdn))}</p>
      </div>
      """
    ret

  editTemplate: (data) =>
    """
    <form class="form-horizontal contact-form" role="form">
      <div class="form-group">
        <label for="phone-#{@model.cid}" class="col-sm-3 control-label">Phone</label>
        <div class="col-sm-9">
          <input type="text" type="phone" firstname="msisdn" class="form-control phone-input" id="phone-#{@model.cid}" rv-value="model:msisdn | phone" maxlength="20" data-parsley-phone="true" required>
        </div>
      </div>
      <div class="form-group">
        <label for="firstname-#{@model.cid}" class="col-sm-3 control-label">First name</label>
        <div class="col-sm-9">
          <input type="text" firstname="firstname" class="form-control" id="firstname-#{@model.cid}" rv-value="model:firstname" data-parsley-minlength="1" required>
        </div>
      </div>
      <div class="form-group">
        <label for="lastname-#{@model.cid}" class="col-sm-3 control-label">Last name</label>
        <div class="col-sm-9">
          <input type="text" lastname="lastname" class="form-control" id="lastname-#{@model.cid}" rv-value="model:lastname" data-parsley-minlength="1" required>
        </div>
      </div>
      <div class="form-group">
        <label for="groups-#{@model.cid}" class="col-sm-3 control-label">Groups</label>
        <div class="col-sm-9">
          <select multiple="multiple" id="groups-#{@model.cid}"></select>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-3 col-sm-9">
          <div class="alert alert-danger" rv-show="model:errors">
            <ul class="list-unstyled">
              <li rv-each-error="model:errors">
                { error }
              </li>
            </ul>
          </div>
          <button type="submit" class="btn btn-default save-contact">Save</button>
          <button type="button" class="btn btn-link cancel-edit">Cancel</button>
        </div>
      </div>
    </form>
    """

  dispose: ->
    return if @disposed
    @_parsley?.destroy()
    delete @_parsley
    delete @group
    delete @groups
    super
