CollectionView = require 'views/base/collection-view'
ConversationMessageView = require 'views/conversation-message-view'
MessageInputView = require 'views/components/message-input-view'
mediator = require 'mediator'


module.exports = class ConversationView extends CollectionView
  template: require './templates/conversation'
  itemView: ConversationMessageView

  useRivets: true
  renderItems: false

  listSelector: '.list-group'

  listen:
    'reset collection': 'hideLoader'
    'submitMessageKeyCombo mediator': 'sendReply'

  events:
    'submit form': 'sendReply'

  optionNames: CollectionView::optionNames.concat ['reply']

  # After the conversation is loaded, hide the loader and enable the Reply button
  hideLoader: ->
    @$('.bs-loading').remove()
    @$('.send-message').removeAttr('disabled')

  sendReply: (event) ->
    event.preventDefault()
    throbber = @$('.send-message').spin()

    send = @reply.send()

    send.always ->
      throbber.spin false
    .then (data) =>
      unless data and data.length
        return @model.set errors: ['Our messaging service is currently unavailable. Please try again later.']

      message = _.first data

      errors = []
      if message.errors then for error in message.errors
        errors.push "#{message.to} - #{error.description}"

      return @reply.set {errors} if errors.length > 0
      body = @reply.get('message')
      @subview('message-input').clearMessage()

      @collection.addCache
        id:  message.id
        body: body
        from:
          address: message.from
        timestamps:
          sms:
            in: moment().unix()
        pending: true

    , (jqXHR) =>
      # if something wrong happened with the api, errors are thrown on the top level
      errors = []
      if jqXHR.responseJSON.errors
        errors.push error.description for error in jqXHR.responseJSON.errors
      else
        # if messages have been returned, there are individual errors
        for message in jqXHR.responseJSON
          errors.push error.description for error in message.errors
      @reply.set {errors}

  render: ->
    super

    @subview 'message-input', new MessageInputView
      container: @$('.message-input')
      message: @reply

    @initScrollHeight()

  initScrollHeight: ->
    @on 'visibilityChange', @toggleScrollHeight

    # Listen for sync events on the collection.
    @listenTo @collection, 'syncStateChange', @toggleScrollHeight

    # Set visibility initially.
    @toggleScrollHeight()

  toggleScrollHeight: =>
    height = window.innerHeight - $('#site-container').height() - 95
    height = 300 if height < 300
    @$list.css(
      overflow: 'auto'
      height: "#{height}px"
    ).scrollTop(@$list[0].scrollHeight)

  initItemView: (model) ->
    view = super
    view.conversation = @model
    view

  dispose: ->
    return if @disposed
    delete @reply
    super
