View = require 'views/base/view'
mediator = require 'mediator'

module.exports = class PasswordResetView extends View
  noWrap: true
  template: require './templates/password-reset'

  useRivets: true

  events:
    'submit form': 'resetPassword'

  render: ->
    @_parsley?.destroy()
    super
    _.defer () => @_parsley = @$('form').parsley() # do it after the render is done

  resetPassword: (event) ->
    event.preventDefault()
    return unless @model.get 'email'

    throbber = @$('.reset-password').spin()

    @model.sync('read', @model, {
      url: @model.urlFor "/2/users/passwordreset?email=#{@model.get 'email'}"
    }).then =>
      @model.set success: true
      throbber.spin(false)
      window.ga? 'send', {
        'hitType': 'event',
        'eventCategory': 'auth',
        'eventAction': 'passwordReset',
        'eventValue': mediator.user.id
      }
    , (jqXHR) =>
      @model.set errors: ['Something went wrong. Try again later or contact us at help.sms@twilio.com']
      throbber.spin(false)

  dispose: ->
    return if @disposed
    @_parsley?.destroy()
    delete @_parsley
    super
