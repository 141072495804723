ModalView = require './modal-view'
ContactGroup = require 'models/contact-group'
utils = require 'lib/utils'

module.exports = class DeleteConnectionGroupView extends ModalView

  autoRender: false

  options:
    footer: true
    header: true
    primaryButton: 'Delete group'
    secondPrimaryButton: 'Delete group and '
    secondPrimaryButtonEnd: ' contacts'
    title: 'Are you sure you want to delete '

  modalOptions:
    backdrop: 'static'
    keyboard: false
    show: true

  events:
    'click .modal-footer .deleteGroup': 'deleteGroup'
    'click .modal-footer .deleteGroupAndContacts': 'deleteGroupAndContacts'
    'show.bs.modal': 'modalShow'
    'hide.bs.modal': 'modalHide'
    'hidden.bs.modal': 'modalHide'

  getModalTemplateFunction: ->
    (data) => """
    #{if @options.group.get('contacts_count') > 0 then @getFullBody() else @getEmptyBody()}
    """

  getFullBody: ->
    """
    <p>
      You can choose to delete the group only. Your contact will remain.
    </p>
    <p>
      Or delete the group and all contacts within it. Note that this will not remove the contacts from other groups.
    </p>
  """

  getEmptyBody: ->
    """
    <p>
      Delete empty group?
    </p>
    """

  getPrimaryButton: ->
    return '' unless @options.primaryButton
    """
    <button class="btn btn-primary deleteGroup">
      #{@options.primaryButton}
    </button>
    """

  getSecondPrimaryButton: ->
    return '' unless @options.secondPrimaryButton
    """
    <button class="btn btn-primary deleteGroupAndContacts">
      #{@options.secondPrimaryButton} #{@options.group.get('contacts_count')} #{@options.secondPrimaryButtonEnd}
    </button>
    """

  getModalHeader: ->
    """
    <div class="modal-header">
      #{if @options.close then @getCloseButton() else ''}
      <h4 class="modal-title">#{@options.title} <strong>#{ _.escape(@options.group.get('name')) }</strong>?</h4>
    </div>
    """

  getModalFooter: ->
    """
    <div class="modal-footer">
      #{if @options.secondaryButton then @getSecondaryButton() else ''}
      #{if @options.secondPrimaryButton and @options.group.get('contacts_count') > 0 then @getSecondPrimaryButton() else ''}
      #{if @options.primaryButton then @getPrimaryButton() else ''}
    </div>
    """

  dispose: ->
    return if @disposed
    @options.group.set('deleteGroup', false)
    delete @options
    delete @modalOptions
    super
    # Remove any eventual first backdrop
    $('.modal-backdrop').eq(0).remove()

  deleteGroup: ->
    @options.group.destroy()
    utils.redirectTo 'contacts#index'

  deleteGroupAndContacts: ->
    @options.group.deleteContacts().then =>
      @options.collection.fetch(reset: true, data: include_global: true)
    utils.redirectTo 'contacts#index'
