Model = require '/models/base/model'

module.exports = class Contact extends Model

  apiAttrs: ['firstname', 'lastname', 'msisdn', 'groups']

  urlRoot: '/2/contacts/'

  displayName: ->
    firstname = @escape('firstname')
    lastname = @escape('lastname')
    if firstname and lastname and firstname isnt lastname
      "#{firstname} #{lastname}"
    else
      firstname
