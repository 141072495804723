View = require 'views/base/view'
Model = require 'models/base/model'
utils = require 'lib/utils'
mediator = require 'mediator'
config = require 'config'

class VatAmount extends Model
  urlRoot: -> "/2/wallets/#{@get('walletId')}/topup/vat-check/#{@get('amount')}"

module.exports = class WalletView extends View
  template: require './templates/wallet'

  useRivets: true

  showHelp: false
  showVat: false

  events:
    'click .submit-via-logo': 'submitProxy'
    'submit .topup-form': 'topup'
    'click #show-help': 'toggleHelp'

  constructor: ->
    @rivets =
      formatters:
        'remaining': (obj) =>
          obj.limit - obj.interval.amount
      binders:
        'progress': @formatLimit
        'parsley-max': @setParsleyMax
    super

  optionNames: View::optionNames.concat ['customer']

  initialize: ->
    super
    @vatModel = new VatAmount()
    @vatModel.set 'walletId', @model.id

    @listenTo @model, 'change:amount', _.debounce(=>
        amt = @model.get "amount"
        if amt
          @vatModel.set 'amount', amt
          @vatModel.fetch().success =>
            if mediator.user.get('customer')?
              @showVat = true
            amt = (@model.get('balance') + @vatModel.get('topup_amount'))
            @vatModel.set 'new_balance', amt
            @vatModel.set 'topup_combined', (@vatModel.get('topup_amount') + @vatModel.get('vat_amount'))
        else
          @showVat = false
      , 300)
    @listenTo @customer, 'synced', =>
      @model.set 'showTopupLimitHelp', false
      limit = @model.get 'topup_limit'
      if limit.limit - limit.interval.amount < 5
        @showHelp = true

  formatLimit: (el, obj) ->
    perc = obj.interval.amount / obj.limit * 100
    el.style.width = (if perc > 100 then 100 else perc) + '%'

  setParsleyMax: (el, obj) ->
    limit = obj.limit - obj.interval.amount
    $(el).parsley({max: if limit < 0 then 0 else limit})

  toggleHelp: ->
    @showHelp = !@showHelp

  submitProxy: ->
    @$('form').submit()

  topup: (event) ->
    event.preventDefault()

    amount = @model.get('amount')
    return if isNaN(parseFloat(amount))

    if mediator.user.get('customer').id is 2
      return window.location.href = "#{config.SIGNUP}/complete/#{mediator.user.get('api_token')}/#{amount}"

    throbber = @$('.submit-top-up').spin size: '2x'

    topup = @model.topup()

    topup.always ->
      throbber.spin(false)
    .then (data) =>
      @publishEvent 'paypal:connect'
      window.ga? 'send', {
        'hitType': 'event',
        'eventCategory': 'wallets',
        'eventAction': 'topup',
        'eventLabel': 'Most topped up wallet'
        'eventValue': @model.id
      }
      window.ga? 'send', {
        'hitType': 'event',
        'eventCategory': 'wallets',
        'eventAction': 'topup',
        'eventLabel': 'Most frequent top-up user'
        'eventValue': mediator.user.id
      }
      window.location = data.url

  render: ->
    @_parsley?.destroy()
    super
    @_parsley = @$('.topup-form').parsley()

  dispose: ->
    return if @disposed
    @_parsley?.destroy()
    delete @_parsley
    super
