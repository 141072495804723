CollectionView = require 'views/base/collection-view'
ContactImportView = require 'views/contact-import-view'
utils = require 'lib/utils'
mediator = require 'mediator'
config = require 'config'

module.exports = class ContactImportsView extends CollectionView
  template: require './templates/contact-imports'
  itemView: ContactImportView
  useRivets: true

  listSelector: '.imports-list'
  fallbackSelector: '.fallback'

  optionNames: CollectionView::optionNames.concat ['contactGroups']

  fileuploadObject: undefined # save a reference here so we can complete it and send it later

  events:
    'click [data-action="cancelImport"]': 'resetView'
    'click [data-action="resetUpload"]': 'resetUpload'
    'click [data-action="startImport"]': 'upload'

  initialize: () ->
    super
    @resetView()
    @listenTo @contactGroups, 'synced', @selectizeGroups
    mediator.socket.addCallback(@subscribeSocket)
    @subscribeEvent 'socket:disconnected', =>
      return if @disposed
      mediator.socket.addCallback(@subscribeSocket)

    @subscribeEvent 'socket:contact-upload', @socketImportDataHandler

  socketImportDataHandler: (data) ->
    importJob = @collection.get(data.id)
    importJob.handleSocketData(data) if importJob

  render: () ->
    super
    @initFileSelect()
    @setDraggingClasses()

  resetView: () ->
    # model is a base model used for view state
    @model.set({
      file: undefined
      group: undefined
      uploading: false
      upload_done: false
      upload_successful: false
      upload_failed: false
      uploadProgress: 'width: 0%'
    })
    @_groups?.clear?()
  resetUpload: () ->
    @model.set({
      uploading: false
      upload_done: false
      upload_successful: false
      upload_failed: false
      uploadProgress: 'width: 0%'
    })


  subscribeSocket: () =>
    @publishEvent 'socket:send', { command: 'contactsubscribe' }

  upload: () ->
    @resetUpload()
    @fileUploadObject.formData = { group_id: @model.get('group').get('id') }
    @fileUploadObject.submit()
    @model.set('uploading', true)

  uploadDataReady: () ->
    file = @model.get('file')
    group = @model.get('group')
    isReady = group? and file? and file.sizeOK is true
    return isReady

  initFileSelect: () ->
    fileSelect = @$('.js-upload-dropzone')
    fileSelect.fileupload({
      url: config.API + '/2/contacts/uploads/'
      paramName: 'data'
      headers: {'Authorization': "Token #{Chaplin.mediator.user.get 'api_token'}"}
      add: (e, fileUploadObject) =>
        file = fileUploadObject.files[0]
        file.sizeLabel = "#{(file.size / (1024*1024)).toFixed(2)}MB"
        file.sizeLabel = "#{(file.size / 1024).toFixed(2)}KB" if file.size < 1024
        file.sizeOK = file.size < 64 * 1024 * 1024 # 64 mb
        @model.set('file', fileUploadObject.files[0])
        @fileUploadObject = fileUploadObject
      progressall: (e, data) =>
        progress = parseInt(data.loaded / data.total * 100, 10)
        @model.set('upload_done', true) if progress is 100
        @model.set('uploadProgress', "#{progress}%")

      done: (e, data) =>
        @model.set('upload_successful', true)
        @collection.fetch()
        setTimeout( () =>
          @resetView() #refetch collection
        , 1000)
      fail: (e, data) =>
        @model.set('upload_failed', true)

    })
  selectizeGroups: () ->
    @_groups.destroy if @_groups
    groupSelect = @$('#contactGroups').selectize
      options: @contactGroups.map (group) -> return {value: group.get('id'), text: group.get('name')}
      closeAfterSelect: true
      onChange: (groupId) => @model.set('group', @contactGroups.get(groupId))
    @_groups = groupSelect[0]?.selectize


  setDraggingClasses: () ->
    fileSelect = @$('.js-upload-dropzone')
    dragEnters = 0
    setClass = ()-> fileSelect[if dragEnters is 0 then 'removeClass' else 'addClass']('dragOver')
    reset = ()->
      dragEnters = 0
      setClass()
    fileSelect.on 'dragenter', ()-> dragEnters += 1
    fileSelect.on 'dragleave', ()-> dragEnters -= 1
    fileSelect.on 'dragenter dragleave', setClass
    fileSelect.on 'drop dragend', reset

  dispose: () ->
    return if @disposed
    @publishEvent 'imports-disposed'
    @_groups.destroy if @_groups
    @publishEvent 'socket:send', {command: 'contactunsubscribe'}
    super
