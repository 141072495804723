View = require 'views/base/view'
MessageInputView = require 'views/components/message-input-view'
MessagePreviewView = require 'views/components/message-preview-view'
mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class MessageSendView extends View
  template: require './templates/message-send'

  useRivets: true

  events:
    'submit form': 'send'
    'blur #recipients': 'recipientsChange'

  optionNames: View::optionNames.concat ['wallets', 'connections', 'numbers', 'customer']

  initialize: ->

    @contactsList = {}
    @listenTo @connections, 'reset', @checkConnections
    @listenTo @numbers, 'synced', @selectizeFrom
    @listenTo @customer, 'synced', @prefillVerifiedNumber

    super

  isTrial: ()->
    user = Chaplin.mediator.user
    isTrial = user.get('trial') || user.get('customer').trial
    return isTrial


  checkConnections: (connections) ->
    @connectionsHasSynced = true
    if @connections.length
      @selectizeConnections()

  selectizeConnections: ->
    @_connections?.destroy()
    render = (item, escape) ->
      """
        <div>
          <strong>#{escape(item.label)}</strong>
          <p class="text-muted no-bottom-buffer">
            <small>#{escape(item.wallet.name)} €#{escape(item.wallet.balance)}</small>
          </p>
        </div>
      """
    $_connections = @$('#connection').selectize
      valueField: 'id'
      labelField: 'label'
      searchField: 'label'
      options: @connections.toJSON()
      onChange: (value) =>
        connection = @connections.get(value)
        return unless connection
        number = @numbers.findWhere {
          number: @model.get('from')
        }
        if number and number.get('keywords')[0].connection.id isnt connection.get 'id'
          @model.set 'from', ''
          @selectizeFrom()
        wallet = connection.get('wallet')
        @model.set
          connection_id: connection.id
          connection_name: connection.get('label')
          wallet_id: wallet.id
          wallet_name: wallet.name
          wallet_balance: wallet.balance
      render:
        option: render

    @_connections = $_connections[0].selectize
    # Check whatever the default connection is in @connections
    if @connections.get(mediator.user.get('default_connection'))
      connection = @connections.get(mediator.user.get('default_connection').id)
    # If so, use it, otherwise use the first connectin in @connections
    @_connections.setValue if connection
      connection.id
    else
      @connections.at(0).id

  selectizeFrom: ->
    render = (item, escape) =>
      if item.opt_group is "longcodes" and @connections.length > 1
        return """
          <div>
            #{escape(item.text)}
            <p class="text-muted no-bottom-buffer">
              <small class="clearfix"><i>#{escape(item.country)} <span class="pull-right">#{escape(item.connection.name)}<span></i></small>
            </p>
          </div>
        """
      else
        return "<div>#{escape(item.text)}</div>"

    current = @model.get('from')

    if @_from
      @_from.destroy()

    priorityNumbers = []

    phones = mediator.user.get('phones')
    results = _.map phones, (phone) =>
      sanitized = utils.sanitizePhone(phone.number)
      priorityNumbers.push sanitized
      {value: sanitized, text: utils.formatPhone(phone.number), opt_group: 'my'}

    recent = mediator.user.get('recent_alphas') or []
    for phone in recent
      if (phone.match(/^[a-zA-Z0-9 ]+$/) or utils.validatePhone(phone)) and 2 <= phone.length <= 17
        results.push {value: utils.sanitizePhone(phone), text: phone, opt_group: 'alpha'}

    for longcode in @numbers.models
      sanitized = utils.sanitizePhone(longcode.get('number'))
      priorityNumbers.push sanitized
      results.push {
        value: sanitized
        country: longcode.get('country')
        connection: longcode.get('keywords')[0].connection
        text: utils.formatPhone(longcode.get('number'))
        opt_group: 'longcodes'
      }

    $_from = @$('#from')

    $_from.selectize
      optgroups: [
        {value: 'my', label: 'My Verified Numbers'},
        {value: 'longcodes', label: 'Leased Numbers'},
        {value: 'alpha', label: 'Recently used alphanumerics'}
      ]
      optgroupField: 'opt_group',
      optgroupOrder: ['alpha', 'my', 'longcodes'],
      options: results
      createOnBlur: true
      render:
        option: render
      create: (value, callback) =>
        # We only check for errors here, the user is not allowed to create an item that is faulty
        @model.unset 'errors'
        errors = []

        # value.length 2-17
        if 2 > value.length or value.length > 17
          errors.push('Sender ID must be between 2 and 17 characters')

        else if not utils.validatePhone(value)
          if value.length > 11
            errors.push("Alphanumeric sender ID must be 2 to 11 characters long. Your sender ID contains #{value.length} characters")
          if not utils.isGSM7(value)
            notAllowedChars = _.uniq(utils.getNonGSM7Characters(value))
            errors.push("All characters must be included in GSM7 (#{notAllowedChars.join(', ')} is not)")

        if(errors.length)
          @model.set {errors: errors}
          return callback(false)


        sanitized = utils.sanitizePhone(value)
        if sanitized in priorityNumbers
          return callback {value: sanitized, text: value}
        else
          recent = _.clone(mediator.user.get('recent_alphas')) or []
          recent.unshift(value) if recent.indexOf(value) is -1
          mediator.user.set 'recent_alphas', recent.slice(0, 5)
          callback {value: sanitized, text: value, opt_group: 'alpha'}

      onChange: (value) =>
        # We only check for warnings here, you CAN use sender ID's that are not directly faulty
        @model.unset 'errors'
        @model.unset 'warnings'
        warnings = []

        # checks for alphanumeric warnings
        if not utils.validatePhone(value) and value.length
          # alphanumeric is recommended to use characters a-zA-Z0-9 only (we cover spaces in separate conditional)
          if /[^a-z\d ]/gi.test(value)
            warnings.push('Some operators restricts sender ID\'s to a-z, A-Z & 0-9. Check with your account manager if you have questions')

          # Spaces is not recommended
          if /[ ]/gi.test(value)
            warnings.push('Some phones does not support spaces in sender ID')

          # alphanumeric is recommended to be at least 3 characters long
          if value.length < 3
            warnings.push('Some phones does not support sender ID\'s with less than 2 characters')

        @model.set {warnings: warnings} if warnings.length

        @model.set {from: value}
        number = @numbers.findWhere({number: value})
        @_connections.setValue(number.get('keywords')[0].connection.id) if number

    @_from = $_from[0].selectize
    # TODO: Fix so that when changing connections, and current is set,
    # the value on this stupid control is reupdated
    @_from.setValue(current) if current

  prefillVerifiedNumber: (value) ->
    # Check if user is in trial mode
    if value.id == 2 || value.trial
      primaryNumber = null
      phones = mediator.user.get('phones')
      for phone in phones
        if (phone.primary_number == true and phone.verified == true)
          primaryNumber = phone.number

      @_from?.setValue?(primaryNumber) if primaryNumber

  recipientsChange: () ->
    @model.unset 'errors'
    value = @model.get('to')
    return if not value
    return @model.set({ errors: ["""#{value} is not a valid phone number"""] }) if not utils.validatePhone value

    sanitizedNumber = utils.sanitizePhone value
    isTrial = mediator.user.get('trial') || mediator.user.get('customer').trial
    isOwnNumber = false
    mediator.user.get('phones').some (phone) ->
      isOwnNumber = true if phone.number is sanitizedNumber
    trialErrorMessage = """You may only send to your own number until you have made your first Payment."""

    return @model.set({ errors: [trialErrorMessage] }) if isTrial and not isOwnNumber

    @model.set('to', sanitizedNumber)

  send: (event) ->
    event.preventDefault()

    throbber = @$('.send-message').spin()
    send = @model.send()

    send.always ->
      throbber.spin false
    .then (data) =>
      if not data
        return @model.set errors: ['Our messaging service is currently unavailable. Please try again later.']

      errors = []
      for message in data
        if message.errors then for error in message.errors
            errors.push "#{message.to} - #{error.description}"

      if errors.length > 0
        errors.unshift 'Not all of your messages were sent. See numbers and corresponding errors below.'
        @_recipients.clear()
        return @model.set {errors}

      utils.redirectTo('messages#outbox')

    , (jqXHR) =>
      # if something wrong happened with the api, errors are thrown on the top level
      errors = []
      if jqXHR.responseJSON.errors
        for message in jqXHR.responseJSON.errors
          errors.push(message['description'])

      @model.set {errors}

  render: ->
    super
    @subview 'message-input', new MessageInputView
      container: @$('.message-input')
      className: 'col-md-9'
      message: @model

    @subview 'message-preview', new MessagePreviewView
      container: @$('.message-preview')
      message: @model
      connections: @connections
      wallets: @wallets

    @_parsley = @$('form').parsley()

  dispose: ->
    return if @disposed
    @_recipients?.destroy()
    @_connections?.destroy()
    @_parsley?.destroy()
    myFuncCalls = 0
    properties = [
      'connections', '_recipients', '_parsley', '_date', '_batchLabel', '_time', '_from', 'groups','numbers','_sendoutDate','_sendoutTime, sendout'
    ]
    delete this[prop] for prop in properties
    super
