View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class HeaderWalletView extends View
  tagName: 'li'

  listen:
    'change:balance model': 'updateBalance'

  updateBalance: ->
    @_od.update @model.get('balance')

  render: ->
    super
    @_od = new Odometer
      el: @$('.wallet-balance').get(0)
      value: @model.get('balance')

  getTemplateFunction: ->
    (data) ->
      """
        <a href="#{utils.reverse 'wallets#show', walletId: _.escape(data.id) }">
          <span style="vertical-align: middle;">&euro;</span>
          <span class="wallet-balance"></small>
        </a>
      """

  dispose: ->
    return if @disposed
    delete @_od
    super
