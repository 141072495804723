ModalView = require './modal-view'
utils = require 'lib/utils'

module.exports = class ContactsImportHelpModal extends ModalView
  autoRender: true
  template: require './templates/contacts-import-help-modal'

  options:
    footer: true
    header: true
    title: '<i class="fa fa-exclamation-circle"></i>&nbsp;Import Contacts help'
    primaryButton: 'Ok!'
    secondaryButton: false

  modalOptions:
    keyboard: true
    show: true

  events:
    'hide.bs.modal': 'modalHide'

  dispose: ->
    return if @disposed
    super
