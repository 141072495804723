
Model = require('/models/base/model')
utils = require('lib/utils')

module.exports = class HlrBatch extends Model

  urlRoot: '/2/hlrbatch/'

  defaults: ({
    label: ''
    recipients_count: 0
    success: 0
    failed: 0
    pending: 0
    status: 1
    total: 0
  })

  initialize: ->
    if @isNew()
      @set({ label: @generateLabel() })

  # Override the regular send method.
  send: ->
    # POST url requires appended connection id.
    url = @urlFor("#{@urlRoot}#{@get('connection_id') or ''}")
    data = {
      hlr: @get('hlr')
      label: @get('label')
    }

    @sync('create', this, {url, attrs: data})

  parse: (response) ->
    # If key statistics is defined, then we have responses as well
    # Used to display real time statistics of hlr statuses.
    if response.statistics
      stats = response.statistics
      response.success = stats.delivered or 0
      response.failed = 0
      response.failed += stats.undelivered if stats.undelivered
      response.failed += stats.expired if stats.expired
      response.failed += stats.unknown if stats.unknown
      response.failed += stats.rejected if stats.rejected
      response.pending = stats.no_dlr or 0
      response.total = response.success + response.failed + response.pending

    if response.id
      response.link = utils.reverse('hlr-batch#show', { id: response.id })

    response

  setHlrProp: (prop, value) ->
    hlr = @get('hlr') or {}
    hlr[prop] = value
    @set({ hlr: hlr })
    @trigger('change:hlr', @, hlr)
    @trigger('change', @)

  fetchWithStats: ->
    @fetch({ reset: true, silent: true }).then =>
      @fetch({
        add: false,
        merge: true,
        remove: false,
        silent: false,
        url: @urlFor("/2/analytics/hlrbatch/#{@id}")
      }).then =>
        @trigger('reset')

  mergeSocketStats: (newStatistics) ->
    statistics = @get('statistics') or {}
    for status, count of newStatistics
      statistics[status] = 0 if not statistics[status]
      statistics[status] += count
      statistics.no_dlr = Math.max(
        statistics.no_dlr - count, 0
      ) if status isnt 'no_dlr'

    # we want the data to be parsed like if it came from the rest api
    @set(@parse({statistics: statistics}))

  generateLabel: ->
    return "HLR Batch [#{moment().format('L')}]"

  isPending: ->
    return @get('status') is 0

  isInProgress: ->
    return @get('status') is 1

  isDone: ->
    return @get('status') is 2
