Model = require '/models/base/model'
utils = require 'lib/utils'

module.exports = class Sendout extends Model

  urlRoot: '/2/sendouts/'

  defaults:
    label: ''
    message_cost: 0
    total_cost: 0
    recipients_count: 0
    success: 0
    failed: 0
    pending: 0
    status: 1
    total: 0

  initialize: () ->
    if @isNew()
      @set({ label: @generateLabel() })


  parse: (response) ->
    # if key statistics is defined, then we have responses as well
    if response.statistics
      stats = response.statistics
      response.success = stats.delivered or 0
      response.failed = 0
      response.failed += stats.undelivered if stats.undelivered
      response.failed += stats.expired if stats.expired
      response.failed += stats.unknown if stats.unknown
      response.failed += stats.rejected if stats.rejected
      response.pending = stats.no_dlr or 0
      response.total = response.success + response.failed + response.pending

    if response.responses
      response.responses.sort (a, b) -> return b.count - a.count
      response.responses.forEach (smsResponse) ->
        smsResponse.percentage = (smsResponse.count * 100 / response.total_responses).toFixed(2)
      response.topResponses = response.responses.slice(0, 5)
      response.bottomResponses = response.responses.slice(-5)

    if response.id
      response.link = utils.reverse 'sendouts#show', id: response.id

    response

  setSMSProp: (prop, value) ->
    sms = @get('sms') or {}
    sms[prop] = value
    @set({ sms: sms })
    @trigger('change:sms', @, sms)
    @trigger('change', @)

  unsetSMSProp: (prop) ->
    sms = @get('sms') or {}
    delete sms[prop] if sms[prop]?
    @set({ sms: sms })
    @trigger('change:sms', @, sms)
    @trigger('change', @)


  fetchWithStats: ->
    @fetch(reset: true, silent: true).then =>
      @fetch(
        add: false, merge: true, remove: false, silent: false
        url: @urlFor("/2/analytics/sendouts/#{@id}")
      ).then =>
        _.defer( () =>
          # because for some reason these does not get fired for our custom binders specified in views
          @trigger('change:success')
          @trigger('change:failed')
        )
        @trigger('reset')

  mergeSocketStats: (newStatistics) ->
    statistics = @get('statistics') or {}
    for status, count of newStatistics
      statistics[status] = 0 if not statistics[status]
      statistics[status] += count
      statistics.no_dlr = Math.max(statistics.no_dlr - count, 0) if status isnt 'no_dlr'

    # we want the data to be parsed like if it came from the rest api
    @set(@parse({statistics: statistics}))

  estimateCost: (connectionId) =>
    return if @disposed
    sms = @get('sms') or {}
    groups = sms.groups or []
    to = sms.to or []
    text = sms.message or ''
    smsData = utils.getSMSData(text)
    if(smsData.multipartCount is 0)
      @set({ total_cost: 0, message_cost: 0 })
      return
    if(groups.length is 0 and to.length is 0)
      @set({ total_cost: 0, message_cost: 0 })
      return

    @estimateRequest?.abort?()
    estimateOptions = {
      url: @urlFor("/2/sms/costestimate/#{connectionId}"),
      attrs: {groups: groups, to: to, message: 'Dummy text for estimate'}
    }
    @estimatingCost = true
    @estimateRequest = @sync('create', @, estimateOptions)
      .then (data) =>
        return if @disposed
        @set({ message_cost: data.total_cost, total_cost: data.total_cost * smsData.multipartCount }) if data.total_cost
      .always =>
        return if @disposed
        @estimatingCost = false

  generateLabel: =>
    return "Sendout[#{moment().format('L')}]"

  isScheduled: () ->
    return @get('status') is 0
  isProcessing: () ->
    return @get('status') is 1
  isProcessed: () ->
    return @get('status') is 2
