config = require 'config'

# Base model.
module.exports = class Model extends Chaplin.Model

  # Mixin a synchronization state machine.
  _.extend @prototype, Chaplin.SyncMachine

  apiAttrs: null # List of attributes to save with api
  apiRoot: config.API

  initialize: ->
    super
    @on 'request', @beginSync
    @on 'sync', @finishSync
    @on 'error', @unsync

  url: ->
    base = super
    if base.indexOf(@apiRoot) is -1
      @urlFor base
    else
      base

  urlFor: (path) ->
    return @apiRoot + path

  sync: (method, model, options) ->
    if model.apiAttrs and not options.attrs
      options.attrs = _.pick model.toJSON(options), model.apiAttrs

    options.headers = {} unless options.headers
    options.headers['Authorization'] = "Token #{Chaplin.mediator.user.get 'api_token'}"

    success = options.success
    options.success = (args...) =>
      return if model.disposed
      success? args...
      @finishSync()


    error = options.error
    options.error = (args...) =>
      if arguments[0].status is 401
        return Chaplin.mediator.publish '!logout', _.first(arguments[0].responseJSON.errors)
      return if model.disposed
      error? args...
      @abortSync()

    super method, model, options

  dispose: ->
    return if @disposed
    delete @apiAttrs
    super
