Transactions = require 'models/transactions'
Wallets = require 'models/wallets'
Controller = require 'controllers/base/controller'
Model = require 'models/base/model'
WalletsView = require 'views/wallets-view'
WalletView = require 'views/wallet-view'
WalletSettingsView = require 'views/wallet-settings-view'
TransactionsView = require 'views/transactions-view'
TransferFundsView = require 'views/transfer-funds-view'
WalletSubscribers = require 'models/wallet-subscribers'
WalletSubscribersView = require 'views/wallet-subscribers-view'
PaypalConnectingView = require 'views/paypal-connecting-view'
TemplateView = require 'views/base/template-view'
Customer = require 'models/customer'
mediator = require 'mediator'

module.exports = class WalletsController extends Controller

  session_key: 'last_used_wallet'
  collection_key: 'wallets'

  beforeAction: (params, route, options) ->
    ret = super

    return if @redirected

    wallets = @reuse 'wallets'
    wallets.fetch() unless wallets.isSyncing()

    ret

  index: (params, route, options) ->
    if options.query.amount
      throbber = $('#main-container').spin({top: '120px', size: '4x'})
      collection = @reuse 'wallets'
      model = @getModelOrGetFirst collection, mediator.user.get('last_used_wallet')
      model.set {amount: options.query.amount}
      return model.topup().always ->
        throbber.spin(false)
      .then (data) ->
        new PaypalConnectingView(autoRender: true)
        window.location = data.url
      , =>
        @redirectTo 'wallets#show', {walletId: @getSessionIdOrGetFirst()}

    @redirectTo 'wallets#show', {walletId: @getSessionIdOrGetFirst()}

  show: (params) ->
    # Inititialize Collections & Models
    collection = @reuse 'wallets'
    model = @getModelOrGetFirst collection, params.walletId
    @collection = new Transactions [], walletId: params.walletId
    @customer = new Customer

    # Set the page title
    @adjustTitle 'Payment'
    @publishEvent 'pageHeader', 'Wallets', description: 'Top up your wallets and check your payment history'

    unless collection.length
      @view = new TemplateView
        region: 'main'
        template: require 'views/templates/wallets-error'
      return

    # Fire up some views
    @walletsView = new WalletsView {
      model, collection, region: 'main'
    }

    @paypal = new PaypalConnectingView

    @view = new WalletView {
      model, collection, @customer, region: 'main'
    }

    @transactionsView = new TransactionsView {
      model, @collection, region: 'main'
    }

    # Get all transactions for this wallet
    @collection.fetch()
    if mediator.user.get('customer')?
      @customer.fetch()


  settings: (params, route, options) ->
    # Inititialize Collections & Models
    collection = @reuse 'wallets'
    model = @getModelOrGetFirst collection, params.walletId

    @collection = new WalletSubscribers([], walletId: params.walletId)
    @model = new Model

    # Set the page title
    title = if collection.length > 1 then model.get('name') else 'Payment'
    @adjustTitle "#{title} - settings"
    @publishEvent 'pageHeader', title, icon: 'money', subheader: 'settings'

    # Fire up some views
    @view = new WalletSettingsView {
      model, collection, region: 'main'
    }

    @subscribersView = new WalletSubscribersView {
      @model, @collection, region: 'main'
    }

    @collection.fetch(reset:true)

  transfer: (params, route, options) ->
    collection = @reuse 'wallets'
    model = collection.get(@getSessionIdOrGetFirst())


    # Need more than 1 wallet to make a transfer
    @redirectTo 'wallets#index' if collection.length < 2

    # Set the page title
    @adjustTitle 'Transfer Funds'
    @publishEvent 'pageHeader', 'Transfer Funds', icon: 'money'

    # Fire up some views
    @view = new TransferFundsView {collection, model, region: 'main'}
