Controller = require('controllers/base/controller')

Batches = require('models/batches')
Connections = require('models/connections')
ContactGroups = require('models/contact-groups')
Contacts = require('models/contacts')
Customer = require('models/customer')
Error = require('models/error')
HlrBatch = require('models/hlr-batch')
HlrBatches = require('models/hlr-batches')
Model = require('models/base/model')
Numbers = require('models/numbers')
HlrBatchResults = require('models/hlr-batch-results')
Wallets = require('models/wallets')

HlrBatchSendView = require('views/hlr-batch-send-view')
HlrBatchNavbarView = require('views/hlr-batch-navbar-view')
HlrBatchListView = require('views/hlr-batch-list-view')
HlrBatchResultView = require('views/hlr-batch-result-view')

module.exports = class HlrBatchController extends Controller

  beforeAction: ->
    super

    return if @redirected

    @reuse('hlrs-navbar', HlrBatchNavbarView, { region: 'main' })

  index: (params, route, options) ->
    @adjustTitle('HLR Batch')

    @publishEvent(
      'pageHeader', 'HLR Batch', {
        description: 'Manage HLR batches and see progress in real time'
      }
    )

    @collection = new HlrBatches(
      [], { subscribeBuffer: true, offset: options.query.offset or 0 }
    )

    # Fetch batches using the analytics endpoint.
    @collection.fetchWithStats()

    @model = new Model

    @customer = new Customer

    @customer.fetch()

    @view = new HlrBatchListView({
      @model,
      @collection,
      @customer,
      region: 'main'
    })

  create: (params, route, options) ->
    @adjustTitle('New HLR Batch')

    @publishEvent(
      'pageHeader', 'New HLR Batch', { description: 'Create a new HLR Batch' }
    )

    @hlr = new HlrBatch
    @contactgroups = new ContactGroups
    @connections = new Connections
    @numbers = new Numbers
    @customer = new Customer

    groupId = undefined
    groupId = parseInt(options.query.group) if options.query?.group

    # Fetch HLR connections.
    @connections.fetchWithType(2)

    @contactgroups.fetch({
      reset: true
    },
    {
      data: { include_global: true }
    })

    @numbers.fetch({ reset: true })
    @customer.fetch()
    wallets = @reuse('wallets', Wallets)

    @view = new HlrBatchSendView({
      model: @hlr,
      wallets: wallets,
      contactgroups: @contactgroups,
      connections: @connections,
      numbers: @numbers,
      customer: @customer,
      toGroup: groupId
      region: 'main'
    })

  show: (params) ->
    @adjustTitle('HLR Batch details')

    @publishEvent('pageHeader', 'HLR Batch details', {
      description: 'View HLR Batch details'
    })

    @collection = new HlrBatchResults([], {id: params.id})
    @model = new HlrBatch({id: params.id})

    # Fetching Hlrs in batch.
    @collection.fetch()

    # Fetching single HLR batch from analytics endpoint.
    @model.fetchWithStats()

    @view = new HlrBatchResultView({
      model: @model,
      collection: @collection,
      region: 'main'
    })
