Model = require 'models/base/model'
PublicSiteView = require 'views/public-site-view'
SiteFooter = require 'views/site-footer-view'
LoginView = require 'views/login-view'
PasswordResetView = require 'views/password-reset-view'
PasswordCreateView = require 'views/password-create-view'
VerifyPhoneView = require 'views/verify-phone-view'
VerifyEmailView = require 'views/verify-email-view'
mediator = require 'mediator'
utils = require 'lib/utils'
config = require 'config'

# Extends Chaplin.Controller and not our base Controller to avoid
# authentication checks
module.exports = class AuthController extends Chaplin.Controller

  # Methods that should be public available and ensure unauthed users
  # goes here. Methods for authed and unauthed goes in public controller.

  beforeAction: (params, route, options) ->

    # we want to do special logic for auth
    mediator.user.expireSession() if(route.name isnt 'auth#auth')
    mediator.socket?.disconnectSocket()
    @reuse('public-site', PublicSiteView)

  login: (params, route, options) ->
    @adjustTitle('Login')
    attrs = {
      password_reset_href: utils.reverse('auth#passwordReset')
      signup_href: config.SIGNUP
    }
    attrs.alert = params.error if params.error
    attrs.next = params.next if params.next?
    @model = new Model(attrs)

    @view = new LoginView({ @model, region: 'main' })

  # Pre auth with an token
  auth: (params, route, options) ->
    # Get rid of api_token in url and prevent back button logins
    Backbone.history.navigate('/auth', { replace: true })

    if(mediator.user.get('api_token')? and mediator.user.get('api_token') != params.token)
      return Chaplin.mediator.publish('!logout')
    else

      mediator.user.expireSession()
      mediator.user.set({ api_token: params.token })
      mediator.user.fetch(url: mediator.user.urlFor('/2/users/me')).then(() =>
        mediator.user.extendSession()
        # Get rid of api_token in url and prevent back button logins
        window.location.replace if options.query.next
          options.query.next
        else
          utils.reverse('home#index')
      , () =>
        # Get rid of api_token in url and prevent back button logins
        window.location.replace(utils.reverse('auth#login'))
      )

  passwordReset: (params, route, options) ->
    @adjustTitle 'Reset Password'

    attrs =
      cancel_href: utils.reverse('auth#login')
    attrs.email = params.email if params.email
    @model = new Model attrs

    @view = new PasswordResetView {@model, region: 'main'}

  newPassword: (params, route, options) ->
    @adjustTitle 'Create a new password'

    @model = new Model
      id: params.hash
      cancel_href: utils.reverse('auth#login')

    @view = new PasswordCreateView {@model, region: 'main'}

  verifyEmail: (params, route, options) ->
    @adjustTitle 'Verify your new email address'

    @model = new Model
      hash: params.hash
      progress: true
      dashboard_href: utils.reverse 'home#index'
      forwardURL: options.query.url

    @view = new VerifyEmailView {@model, region: 'main'}

  verifyPhone: (params, route, options) ->
    @adjustTitle 'Verify your new phone number'

    @model = new Model
      hash: params.hash
      progress: true
      dashboard_href: utils.reverse('home#index')

    @view = new VerifyPhoneView {@model, region: 'main'}
