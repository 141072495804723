
ModalView = require './modal-view'
utils = require 'lib/utils'

module.exports = class TrialHelpModal extends ModalView
  autoRender: true

  options:
    footer: true
    header: true
    title: '<i class="fa fa-exclamation-circle"></i>&nbsp;There\'s a new version available!'
    primaryButton: 'Upgrade!'
    secondaryButton: '<a class="btn btn-link" data-dismiss="modal">I have unsaved work!</a>'

  modalOptions:
    keyboard: true
    show: true

  events:
    'hide.bs.modal': 'modalHide'
    'click .btn-primary': 'reloadApp'

  getModalTemplateFunction: -> return () => @getFullBody()

  reloadApp: () -> document.location.reload(true);

  getFullBody: () -> """
      <p>There's a new version of the customer portal available! We would recommend you to upgrade to get the latest bug fixes and features.</p>
      <p>Note that all unsaved work will be lost, so be sure to send your messages first!</p>
      <p>When you want to upgrade, just press the upgrade button or refresh your browser (you may need to clear the browser cache)</p>
    """


  dispose: ->
    return if @disposed
    super
