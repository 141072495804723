Collection = require 'models/base/collection'
ContactGroup = require './contact-group'
mediator = require 'mediator'

module.exports = class ContactGroups extends Collection
  model: ContactGroup

  urlRoot: '/2/contacts/groups/'

  pagination: 'groups'
  comparator: 'name'

  initialize: ->
    super

    @subscribeEvent 'contactgroups', @updateGroup

  updateGroup: (data) ->
    return if @disposed
    model = @get(data.group_id)
    return unless model
    if data.processing
      model.set processing: data.processing
    else
      model.fetch().then -> model.set processing: data.processing

  addEdit: =>
    return @trigger('focus:add') if @find (g) -> return g.isNew()
    @unshift edit: true
