ModalView = require './modal-view'
utils = require 'lib/utils'

module.exports = class SendoutHelpModal extends ModalView
  autoRender: true

  options:
    footer: true
    header: true
    title: '<i class="fa fa-exclamation-circle"></i>&nbsp;Sendout Help'
    primaryButton: 'Ok!'
    secondaryButton: false

  modalOptions:
    keyboard: true
    show: true

  events:
    'hide.bs.modal': 'modalHide'

  getModalTemplateFunction: ->
    () => @getFullBody()

  getFullBody: () ->
    return """
      <h5>Label</h5>
      <p>
        A label so you easily can find your sendout later. We will automatically generate a label for you, but you are free to change it to whatever makes sense to you.
      </p>

      <h5>From</h5>
      <p>
        This will show up as the sender on the recipients phones. Use registered 2-way numbers, shortcodes or an alphanumeric text.
      </p>

      <h5>To</h5>
      <p>
        A list of contactgroups and/or numbers to send to.
      </p>

      <h5>Message</h5>
      <p>
        The message. We will show you how many messages will be sent and if you use unicode characters.
      </p>

      <h5>Sendtime</h5>
      <p>
        Set a date and time when you want the sendout to start.
      </p>

      <h5>Expiry time</h5>
      <p>
        If a message to a recipient fails (phone is not on etc.), we will resend it at a later time. If your message is time dependent, be sure to set an expiry time and we will not send more messages after that time.
      </p>
    """


  dispose: ->
    return if @disposed
    super
