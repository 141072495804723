module.exports = (match) ->
  match '', 'home#index'

  match 'contacts', 'contacts#index'
  match 'contacts/imports', 'contacts#imports'
  match 'contacts/:groupId', 'contacts#group', constraints: {groupId: /^\d*$/}

  match 'login', 'auth#login'
  match 'auth/:token', 'auth#auth'

  match 'messages', 'messages#index'
  match 'messages/new', 'messages#create'
  match 'messages/inbox', 'messages#inbox'
  match 'messages/inbox/:conversationId', 'messages#conversation'
  match 'messages/outbox', 'messages#outbox'
  match 'messages/:messageId', 'messages#show'

  match 'sendouts', 'sendouts#index'
  match 'sendouts/new', 'sendouts#create'
  match 'sendouts/:id/edit', 'sendouts#edit'
  match 'sendouts/:id', 'sendouts#show'

  match('hlr-batch/new', 'hlr-batch#create')
  match('hlr-batch', 'hlr-batch#index')
  match('hlr-batch/:id', 'hlr-batch#show')

  match 'password/reset', 'auth#passwordReset'
  match 'password/new/:hash', 'auth#newPassword'

  match 'email/:hash', 'auth#verifyEmail'
  match 'phone/:hash', 'auth#verifyPhone'

  match 'pricelists', 'pricelists#index'
  match 'pricelists/:connectionId', 'pricelists#show', constraints: {connectionId: /^\d+$/}

  match 'welcome', 'public#welcome'

  match 'settings/user', 'settings#user'
  match 'settings/connections', 'settings#connections'
  match 'settings/numbers', 'settings#numbers'

  match 'wallets', 'wallets#index'
  match 'wallets/transfer', 'wallets#transfer'
  match 'wallets/:walletId', 'wallets#show', constraints: {walletId: /^\d+$/}
  match 'wallets/:walletId/settings', 'wallets#settings', constraints: {walletId: /^\d+$/}

  match '*section/show/token', 'home#token'
  match 'show/token', 'home#token'

  match '*anything', 'public#notFound'
