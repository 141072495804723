Controller = require 'controllers/base/controller'
Collection = require 'models/base/collection'
Pricelist = require 'models/pricelist'
Connections = require 'models/connections'
PricelistView = require 'views/pricelist-view'
mediator = require('mediator')

module.exports = class PricelistsController extends Controller

  session_key: 'last_used_connection'
  collection_key: 'connections'

  beforeAction: ->
    super

    return if @redirected

    connections = @reuse 'connections', Connections
    if connections.isSynced()
      connections.fetch()
      true
    else
      connections.fetch(reset: true)

  index: ->
    @redirectTo 'pricelists#show', {connectionId: @getSessionIdOrGetFirst()}

  show: (params, route, options) ->
    connections = @reuse 'connections'
    connection = @getModelOrGetFirst connections, params.connectionId

    title = 'Price List'
    @adjustTitle title
    @publishEvent 'pageHeader', title, description: 'Check prices per country or download your price list'

    @model = new Pricelist account: id: params.connectionId

    @collection = new Collection
    @collection.beginSync()

    @view = new PricelistView {
      @model
      @collection
      connections: @reuse('connections')
      region: 'main'
    }



    @model.fetch().then =>
      @collection.reset @model.get('countries')
      @collection.finishSync()
