User = require 'models/user'
mediator = require 'mediator'
config = require 'config'

# The application object.
module.exports = class Application extends Chaplin.Application

  initialize: ->
    @subscribeEvent 'dispatcher:dispatch', @trackVisit
    # Execute redirect directly on the mediator
    # so that we dont need to require utils in this file.

    @subscribeEvent '!logout', (error) =>
      if @started
        uri = window.location.href.replace window.location.origin, ''
        uri = null if uri.match(/^\/login/)
        mediator.execute 'router:route', 'auth#login', {error: error, next: uri}
      else
        mediator.user.finishSync()

    super

  start: ->
    if mediator.user.isSyncing()
      return mediator.user.once('synced', @start, this)
    super

  # Create additional mediator properties
  # -------------------------------------
  initMediator: ->
    # Create a user property
    mediator.user = new User
    mediator.user.initSession()

    # Create socket property
    mediator.socket = null

    super

  trackVisit: (controller, params, route, options) ->
    window.ga?('send', 'pageview')

