Model = require '/models/base/model'

module.exports = class Message extends Model

  urlRoot: '/2/send'

  defaults:
    message: ''
    to: ''
    from: ''

  send: ->
    url = @urlFor "/2/send/#{@get('connection_id') or ''}"
    data = {
      from: @get('from')
      to: @get('to')
      message: @get('message')
    }

    @sync('create', this, {url, attrs: data})

  dispose: ->
    return if @disposed
    delete @_dfdSync
    super
