View = require 'views/base/view'
DeleteConnectionGroupView = require 'views/delete-connection-group-view'

module.exports = class ContactsView extends View
  className: 'row'
  template: require './templates/contacts'

  useRivets: true

  regions:
    'contacts-menu': '.col-md-4'
    'contacts-main': '.col-md-8'

  events:
    'keyup .nav-form .form-control': 'saveGroupOnReturn'

  listen:
    'add collection': 'focusAddInput'
    'focus:add collection': 'focusAddInput'
    'change:edit collection': 'focusEditInput'
    'change:deleteGroup collection': 'deleteGroup'

  focusAddInput: ->
    # New groups are unshifted and added first in list, hence :first
    @$('.panel-nav.nav-form:first .form-control').focus()

  focusEditInput: (group, edit) ->
    return unless edit
    name = group.get('name')
    @$('.panel-nav .nav-form .form-control').each (i, elem) ->
      $elem = $(elem)
      if $elem.val() is name
        $elem.focus()
        # break out of jquery loop
        return false

  saveGroupOnReturn: (event) ->
    if (event.keyCode or event.which) in [13, 27]
      $(event.currentTarget)
        .closest('.nav-form')
        .find('.group-save')
        .click() # Clickety-click!

  deleteGroup: (group, deleteGroup) ->
    return unless deleteGroup
    # Display confirmation modal for deleting group
    new DeleteConnectionGroupView {autoRender: true , group: group, collection: @collection}
