Controller = require 'controllers/base/controller'

# Models
Model = require 'models/base/model'
Collection = require 'models/base/collection'
Contacts = require 'models/contacts'
ContactGroup = require 'models/contact-group'
ContactGroups = require 'models/contact-groups'
ContactImports = require 'models/contact-imports'

# Views
ContactsView = require 'views/contacts-view'
ContactsListView = require 'views/contacts-list-view'
ContactImportsView = require 'views/contact-imports-view'
ContactsNavbarView = require 'views/contacts-navbar-view'

module.exports = class ContactsController extends Controller

  beforeAction: ->
    super
    @reuse 'contacts-navbar', ContactsNavbarView, region: 'main'
    @contactGroups = new ContactGroups

  imports: () ->
    @adjustTitle 'Contact Import'
    @publishEvent 'pageHeader', 'Contact Import', description: 'Import contacts via file uploads'

    contactImports = new ContactImports()
    contactImports.fetch()
    @contactGroups.fetch()
    state = new Model()


    @view = new ContactImportsView({
      model: state
      collection: contactImports
      region: 'main'
      contactGroups: @contactGroups
    })

  index: (args...) ->
    # we don't want to trigger a redirect, that would flush the main view
    @group.apply(@, args)

  group: (params, route, options) ->
    @adjustTitle 'Contacts'
    @publishEvent 'pageHeader', 'Contacts', description: 'Manage your contact groups'
    hasGroup = params.groupId?

    # Inititialize Collections & Models
    contactsData = {offset: options.query.offset or 0}
    contactsData.groupId = params.groupId if hasGroup
    @collection = new Contacts [], contactsData
    @contactGroups.fetch(data: include_global: true)



    contactGroupData = { name: 'Contacts' }
    contactGroupData.id = params.groupId if hasGroup
    @model = new ContactGroup contactGroupData

    @view = new ContactsView {
      model: @model
      collection: @contactGroups
      region: 'main'
    }

    # Fire up some views
    contactsListView = new ContactsListView {
      collection: @collection
      model: @model
      groups: @contactGroups
      region: 'contacts-main'
    }

    # Get all contacts
    @collection.fetch(reset: true)

    if @collection.groupId
      @model.fetch(data: include_global: true)
