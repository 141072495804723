Model = require '/models/base/model'
utils = require 'lib/utils'

module.exports = class Conversation extends Model

  urlRoot: '/2/conversations/'

  reverse: =>
    utils.reverse 'messages#conversation', conversationId: @id

  parse: (data) ->
    # We don't want to show `[part x of y]` in messages shown in our
    # conversation view.
    data.items = _.uniq data.items, (item) ->
      item.body = item.body.replace /^\[part \d+ of \d+\]/, ''
      date = item.timestamps.sms.in
      # Multipart is merged if they are within a ±300 seconds of each other.
      # Hence group time based on that interval
      item.body + (date - (date % 600))
    data

  defaults:
    items: []

  fromDisplayName: =>
    from = @get('from')
    return '' unless from
    if from.contact.id
      if from.contact.firstname and from.contact.lastname and from.contact.firstname isnt from.contact.lastname
        _.escape "#{from.contact.firstname} #{from.contact.lastname}"
      else
        _.escape from.contact.firstname
    else
      from.number
