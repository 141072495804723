CollectionView = require 'views/base/collection-view'
TransactionView = require 'views/transaction-view'

module.exports = class TransactionsView extends CollectionView
  itemView: TransactionView

  renderItems: false

  listSelector: 'tbody'
  fallbackSelector: '.fallback'

  getTemplateFunction: ->
    (data) -> """
      <h2>Wallet Transactions</h2>

      <div class="panel panel-default">
        <div class="panel-body">
          <table class="table">
            <thead>
              <tr>
                <th class="col-sm-2">Date</th>
                <th class="col-sm-2">Comment</th>
                <th class="col-sm-2">Amount</th>
                <th class="col-sm-2">Balance</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
        <div class="js-collection-loader-container"></div>
        <div class="panel-body fallback">
          <h3 class="text-center">No Transactions</h3>
        </div>
      </div>
    """
