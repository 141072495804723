Wallets = require 'models/wallets'
SiteView = require 'views/site-view'
HeaderView = require 'views/header-view'
SiteFooter = require 'views/site-footer-view'
PageHeaderView = require 'views/page-header-view'
mediator = require 'mediator'
utils = require 'lib/utils'
SocketHandler = require 'lib/socket-handler'

Model = require 'models/base/model'

module.exports = class Controller extends Chaplin.Controller

  beforeAction: (params, route, options) ->

    # Check if the current session has expired
    if not mediator.user.validateSession()
      @redirected = true
      return @publishEvent("!logout") if route.name is 'home#index'
      return @publishEvent("!logout", "Login required")

    # Check if terms is accepted. If not redirect to Welcome view.
    if not mediator.user.termsAccepted()
      return @redirectTo 'public#welcome'

    # Manage socket connection
    unless mediator.socket?
      mediator.socket = new SocketHandler
      mediator.socket.connectSocket()

    # Inititialize Collections & Models
    wallets = @reuse 'wallets', Wallets
    # Fetch once, additional balance updates updated via socket
    dfd = wallets.fetch(reset: true) unless wallets.isSynced()

    routes = @reuse 'routes', Model

    routes.set route: route
    # Inititialize site structure views
    @reuse 'site', SiteView
    @reuse 'header', HeaderView, {model: routes, collection: wallets, region: 'header'}
    @reuse 'page-header', PageHeaderView, region: 'main'
    @reuse 'site-footer', SiteFooter, region: 'footer'

    dfd if dfd and route.controller is 'wallets'


  getModelOrGetFirst: (collection, id) ->
    return unless collection?.length
    model = collection.get(id) or collection.at(0)
    mediator.user.set @session_key, model.id if @session_key
    model

  getSessionIdOrGetFirst: ->
    id = mediator.user.get(@session_key)
    return id if id
    collection = @reuse @collection_key
    return unless collection.length
    collection.at(0).id
