Collection = require('models/base/collection')
HlrBatch = require('./hlr-batch')

module.exports = class HlrBatchResults extends Collection
  model: HlrBatch

  count: 200
  offset: 0

  urlRoot: ->
    "/2/hlrbatch/#{@hlrBatchId}/results/?count=#{@count}"

  url: ->
    base = super
    base += "&offset=#{@offset}" if @offset > 0
    base

  initialize: (models, options={}) ->
    @hlrBatchId = options.id

  dispose: ->
    return if @disposed
    delete @hlrBatchId
    super
