View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class PageHeaderView extends View
  className: 'page-header'

  listen:
    'pageHeader mediator': 'adjustHeader'

  adjustHeader: (header, options = {}) ->
    header = _.escape(header)
    header = "#{header} <small>#{ _.escape(options.subheader) }</small>" if options.subheader
    @$description.html(_.escape(options.description or ''))
    @$header.html(header)

  render: ->
    super
    @$header = @$('h1')
    @$description = @$('.description')

  getTemplateFunction: ->
    (data) -> """
      <h1>&nbsp;</h1>
      <p class="description"></p>
    """

  dispose: ->
    return if @disposed
    delete @$header
    super
