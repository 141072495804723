Message = require 'models/message'
View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class MessagePreviewView extends View
  template: require './templates/message-preview'
  noWrap: true # wrapping is actually stupid

  useRivets: true

  optionNames: View::optionNames.concat ['sendout', 'message', 'connections', 'wallets']

  initialize: () ->
    @messageCount = 0;
    if(@sendout)
      # create our message model instance from sendout sms
      @message = new Message(@sendout.get('sms'))
      @listenTo @sendout, 'change:sms', () => @message.set(@sendout.get('sms')) # update sms text from sendouts

      @listenTo @sendout, 'change:wallet_id', @updateWallet
      @listenTo @sendout, 'change:connection_id', @updateConnection

      @listenTo @sendout, 'change:sms', @updateMessageCount
      @listenTo @sendout, 'change:recipients_count', @updateCostEstimate
      @listenTo @sendout, 'change:recipients_count', @updateMessageCount
    else
      @listenTo @message, 'change:wallet_id', @updateWallet
      @listenTo @message, 'change:connection_id', @updateConnection

    if @wallets
      @updateWallet()
      @listenTo @wallets, 'synced', @updateWallet

    @updateSMSData()
    @listenTo @message, 'change', @updateSMSData

  updateMessageCount: () ->
    @messageCount = (@sendout?.get('recipients_count') or 1) * @smsData.multipartCount

  updateCostEstimate: () ->
    @sendout.estimateCost(@connection.get('id')) if @sendout and @connection

  updateSMSData: () ->
    lastCount = @smsData?.multipartCount or 0
    @smsData = utils.getSMSData(@message.get('message'))
    @updateCostEstimate() if lastCount isnt @smsData.multipartCount

  updateConnection: () ->
    if(@sendout)
      @connection = @connections.get(@sendout.get('connection_id'))
    else if(@message)
      @connection = @connections.get(@message.get('connection_id'))


  updateWallet: () =>
    if(@sendout)
      @wallet = @wallets.get({id: @sendout.get('wallet_id')})
    else if(@message)
      @wallet = @wallets.get({id: @message.get('wallet_id')})


  updateEstimate: () ->
    @sendout

  render: ->
    super

  dispose: ->
    return if @disposed
    super
