View = require 'views/base/view'
mediator = require 'mediator'
config = require 'config'

# Site view is a top-level view which is bound to body.
module.exports = class SiteView extends View
  container: 'body'
  id: 'site-container'
  regions:
    header: '#header'
    page: '#page-container'
    main: '#main-container'
    footer: '#footer'
  template: require './templates/site'

  initialize: ->
    super
    mediator.socket.addCallback(@subscribeSocket)
    @subscribeEvent "socket:disconnected", =>
      return if @disposed
      mediator.socket.addCallback(@subscribeSocket)
    @subscribeEvent "socket:event.contacts.#{Chaplin.mediator.user.id}", @subscribeContacts
    @subscribeEvent 'dispatcher:dispatch', =>
      return if @disposed
      @$main.find('.popover').remove()
      @$main.find('.tooltip').remove()

    @resize = _.debounce @resize, 500
    $(window).on 'resize orientationchange', @resize
    @initBodyDragOver()

  resize: (event) =>
    @publishEvent 'window:resize', event

  initBodyDragOver: () ->
    body = $('body')
    dragEnters = 0
    setClass = ()-> body[if dragEnters is 0 then 'removeClass' else 'addClass']('bodyDragOver')
    reset = ()->
      dragEnters = 0
      setClass()
    body.on 'dragenter', ()-> dragEnters += 1
    body.on 'dragleave', ()-> dragEnters -= 1
    body.on 'dragenter dragleave', setClass
    body.on 'drop dragend', reset

  subscribeSocket: () =>
    @publishEvent 'socket:send',
        command: 'eventsubscribe'
        data:
          event: "contacts.#{Chaplin.mediator.user.id}"

  subscribeContacts: (data) ->
    return if @disposed
    @publishEvent "contactgroups", data

  render: ->
    super
    @$main = @$el.find('#main-container')
    @$main.tooltip
      selector: '[rel=tooltip]'
      container: @$main
    @$main.popover
      selector: '[rel=popover]'
      container: @$main
      trigger: 'hover'


  dispose: ->
    return if @disposed
    delete @$main
    @publishEvent 'socket:send',
      command: 'eventunsubscribe'
      data:
        event: "contacts.#{Chaplin.mediator.user.id}"
    super
