NavbarView = require 'views/navbar-view'
utils = require 'lib/utils'
ContactsImportHelpModal = require('./contacts-import-help-modal')

module.exports = class ContactsNavbarView extends NavbarView
  events:
    'click [nav-id="showContactImportHelp"]': 'openHelpModal'
  items: [
    {
      href: utils.reverse 'contacts#index'
      id: 'contacts#group,contacts#index'
      title: 'Contacts'
      icon: 'fa fa-book'
    },
    {
      href: utils.reverse 'contacts#imports'
      id: 'contacts#imports'
      title: 'Contact Imports'
      icon: 'fa fa-cloud-upload'
    }
  ]
  itemsRight: [
    {
      href: ''
      id: 'showContactImportHelp'
      title: 'Contact Import Help'
      icon: 'fa fa-question-circle'
    }
  ]

  openHelpModal: (e) ->
    e.preventDefault()
    new ContactsImportHelpModal()


