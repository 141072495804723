# Application-specific view helpers
# --------------------------------
utils = require 'lib/utils'

## Live value
## -----------
do ->
  rivets.binders['live-value'] =
    publishes: true
    bind: (el) -> $(el).on 'keyup', _.throttle(@publish, 250, trailing: true)
    unbind: (el) -> $(el).off 'keyup', @publish
    routine: (el, value) -> rivets.binders.value.routine(el, value)

## Password Strength Redux
## -----------
do ->
  rivets.binders['password-strength'] =
    bind: (el) ->
      $el = $(el)
      $container = $el.parent().addClass 'strength-container'
      if $el.hasClass('input-sm')
        $container.addClass('strength-sm')
      if $el.hasClass('input-lg')
        $container.addClass('strength-lg')
      $('<div>', class: 'strength-meter')
        .html('<div></div>')
        .appendTo $container
    unbind: (el) ->
      $container = $(el).parent()
        .removeClass('strength-container strength-sm strength-lg')
        .find('.strength-meter').remove()
        .end()
    routine: (el, value) ->
      strength = utils.passwordStrength(value)
      $container = $(el).parent()
      $meter = $container.find('.strength-meter div')

      if strength is 0
        $meter.attr class: ''
      else if strength is 1
        $meter.attr class: 'strength-very-weak'
      else if strength is 2
        $meter.attr class: 'strength-weak'
      else if strength is 3
        $meter.attr class: 'strength-medium'
      else
        $meter.attr class: 'strength-strong'



## generic style binder
## -----------
rivets.binders['style-*'] = (el, value)->
  style = @args[0]+ ': ' + value
  $(el).attr('style', style)

## Misc formatters
## ---------------
do ->
  # rv-class-disabled="model.valid | negate"
  rivets.formatters.negate = (value) ->
    !value

  # rv-class-disable="model:foo | eq 4"
  # arg will always be string, so lets convert value
  rivets.formatters.eq = (value, args) ->
    String(value) is args

  # rv-class-disable="model:foo | not_eq 4"
  # arg will always be string, so lets convert value
  rivets.formatters.not_eq = (value, args) ->
    String(value) isnt args

  # rv-class-disable="model:foo | gt 4"
  rivets.formatters.gt = (value, arg) ->
    parseFloat(value) > parseFloat(arg)

  # rv-class-disable="model:foo | lt 4"
  rivets.formatters.lt = (value, arg) ->
    parseFloat(value) < parseFloat(arg)

  rivets.formatters.blank = (value) ->
    value is undefined or value is null or value is ''

  # https://github.com/mikeric/rivets/issues/278#issuecomment-35854027
  rivets.formatters.length = (value) ->
    value?.length ? 0

  # String helpers
  # ------------

  # rv-text="model:foo | text 'this is %s'"
  rivets.formatters.text = (value, args...) ->
    args.join(' ').replace('%s', value)

  # rv-text="model:arr | join ', '
  rivets.formatters.join = (value = [], delimiter = '') ->
    value.join(delimiter)


  # Special formatter to print an array of strings to => "str1", "str2", "str3" ...
  rivets.formatters.showIndividualChars = (arr = []) ->
    return "" if not arr and arr.map
    arr = arr.map (str) -> "\"#{str}\""
    return arr.join(',')

  # rv-hide="model:foo | lowercase"
  rivets.formatters.lowercase =
    read: (value) -> if value then value.toLowerCase() else value
    publish: (value) -> value.toLowerCase()

  # Trim a value before setting it, useful on inputs
  # rv-value="model:email | trim
  rivets.formatters.trim =
    read: (value) -> if value then value.trim() else value
    publish: (value) -> value.trim()

  # parseInt on setting, getting will be string in DOM anyway
  # rv-value="model:type | parseInt
  rivets.formatters.parseInt =
    read: (value) -> value
    publish: (value) ->
      i = parseInt value
      if isNaN(i) then value else i

  # parseFloat on setting, getting will be string in DOM anyway
  # rv-value="model:type | parseFloat
  rivets.formatters.parseFloat =
    read: (value) -> value
    publish: (value) ->
      f = parseFloat value
      if isNaN(f) then value else f

  # Date helpers
  # ------------

  rivets.formatters.moment = (value, args...) ->
    return '' unless value
    value = moment.unix(value)
    if value.isValid()
      value.format(args.join(' '))
    else
      ''

  rivets.formatters.fromNow = (value) ->
    return '' unless value
    value = moment.unix(value)
    if value.isValid()
      value.fromNow()
    else
      ''

  rivets.formatters.ISO8601 = (value, args...) ->
    return '' unless value
    value = new Date(value).getTime() / 1000
    value = moment.unix(value)
    if value.isValid()
      value.format(args.join(' '))
    else
      ''

  # Math helpers
  # ------------

  rivets.formatters.fixed = (value, decimals = 4) ->
    parseFloat(value).toFixed(decimals)

  perc: (num, denominator = null) ->
    if denominator and denominator > 0
      num = num / denominator
    "#{rivets.formatters.fixed(num*100, 2)}%"

  # rv-on-submit="model.save | preventDefault"
  # rv-on-click="collection.setNext | preventDefault"
  rivets.formatters.preventDefault = (value) ->
    (e) ->
      e.preventDefault()
      value.call this, e
      return false

  # rv-on-click="model.destroy | stopPropagation"
  rivets.formatters.stopPropagation = (value) ->
    (e) ->
      e.preventDefault()
      e.stopPropagation()
      value.call this, e
      return false


  #TODO: Try to get country code based on visitors position (ip or geolocation)
  rivets.formatters.phone =
    read: (value) ->
      return unless value
      return value unless value.match(/^\+?[()\d ]+$/)
      utils.formatPhone value or ''
    publish: (value) ->
      utils.sanitizePhone value or ''

  # logging for devving
  rivets.formatters.log = (value) ->
    console.log('>> rv log >>')
    console.log(value)
    console.log('<< rv log <<')
    return value


## Parsley validators
## ------------------
window.ParsleyValidator
  .addValidator('phone', (value) ->
    return false unless value.match(/^\+?[()\d\-\. ]+$/)
    utils.validatePhone value
  , 32)
  .addMessage('en', 'phone', 'Not a valid phone number.')
