View = require 'views/base/view'
DatepickerView = require 'views/datepicker-view'
mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class MessagesLookupView extends View
  template: require './templates/messages-lookup'

  useRivets: true

  errors: []

  events:
    'submit #lookup-form': 'submitForm'
    'click #clear-input': 'clearInput'

  listen:
    'dateModal:closed model': 'dateModalClosed'

  optionNames: View::optionNames.concat ['sendouts', 'connections', 'numbers']

  dateOptions: [
    { text: 'Last 5 minutes'  , value: moment().subtract(5  , 'minutes').unix() }
    { text: 'Last 15 minutes' , value: moment().subtract(15 , 'minutes').unix() }
    { text: 'Last 1 hours'    , value: moment().subtract(1  , 'hours'  ).unix() }
    { text: 'Last 3 hours'    , value: moment().subtract(3  , 'hours'  ).unix() }
    { text: 'Last 6 hours'    , value: moment().subtract(6  , 'hours'  ).unix() }
    { text: 'Last 12 hours'   , value: moment().subtract(12 , 'hours'  ).unix() }
    { text: 'Last 24 hours'   , value: moment().subtract(24 , 'hours'  ).unix() }
    { text: 'Last 3 days'     , value: moment().subtract(3  , 'days'   ).unix() }
    { text: 'Last 7 days'     , value: moment().subtract(7  , 'days'   ).unix() }
    { text: 'Last 1 months'   , value: moment().subtract(1  , 'month'  ).unix() }
  ]

  dlrMap: [
    { text: 'Delivered'   , value: 'delivrd' }
    { text: 'Undelivered' , value: 'undeliv' }
    { text: 'Failed'      , value: 'failed'  }
    { text: 'Deleted'     , value: 'deleted' }
    { text: 'Rejected'    , value: 'rejectd' }
    { text: 'Expired'     , value: 'expired' }
    { text: 'Unknown'     , value: 'unknown' }
  ]

  initialize: ->
    super

    # Default date value is 24 hours back
    # Timestamp is not generated again because it will be different
    # If page is revisited so we store it in take it directly from dateOptions
    @model.set {from_date: @dateOptions[6].value}

    @listenTo @sendouts, 'synced', @selectizeSendouts
    @listenTo @connections, 'synced', @selectizeConnections
    @listenTo @numbers, 'synced', @selectizeFrom
    @listenTo @numbers, 'synced', @selectizeTo

  selectizeDatepicker: ->
    $datepicker = @$('#datepicker').selectize
      options: @dateOptions
      sortField: [field: 'value', direction: 'desc']
      onChange: (value) =>
        value = parseInt value
        if value > 0
          @model.unset 'to_date'
          @model.set {
            from_date: value
          }

    @_datepicker = $datepicker[0].selectize
    # Load the default value set in initialize()
    @_datepicker.setValue(@model.get('from_date'))

  datepickerModal: =>
    window.moment = moment
    @model.set('to_date', moment().unix()) if not @model.get('to_date')
    new DatepickerView {
      model: @model
    }

  selectizeTo: ->
    render = (item, escape) =>
      if @connections.length > 1 and item.connection
        return """
          <div>
            #{escape(item.text)}
            <p class="text-muted no-bottom-buffer">
              <small class="clearfix">
                <i>#{escape(item.country)} <span class="pull-right">#{escape(item.connection.name)}<span>
                </i></small>
            </p>
          </div>
        """
      else
        return "<div>#{escape(item.text)}</div>"

    @_to?.destroy?()

    longcodeList = []

    for longcode in @numbers.models
      sanitized = utils.sanitizePhone(longcode.get('number'))
      longcodeList.push {
        value: sanitized
        country: longcode.get('country')
        connection: longcode.get('keywords')[0].connection
        text: utils.formatPhone(longcode.get('number'))
        opt_group: 'longcodes'
      }

    $_to = @$('#sms-to')
    $_to.selectize
      optgroups: [
        {value: 'longcodes', label: 'Leased Numbers'},
        {value: 'recentSearches', label: 'Recent searches'}
      ]
      optgroupField: 'opt_group',
      optgroupOrder: ['recentSearches', 'longcodes'],
      options: longcodeList
      render:
        option: render
      create: (value, callback) =>
        sanitized = utils.sanitizePhone(value)
        return callback {value: sanitized, text: value, opt_group: 'recentSearches'}
      onChange: (value) => @model.set {to: value}

    @_to = $_to[0].selectize

  selectizeFrom: ->
    render = (item, escape) =>
      if item.opt_group is "longcodes" and @connections.length > 1
        return """
          <div>
            #{escape(item.text)}
            <p class="text-muted no-bottom-buffer">
              <small class="clearfix"><i>#{escape(item.country)} <span class="pull-right">#{escape(item.connection.name)}<span></i></small>
            </p>
          </div>
        """
      else
        return "<div>#{escape(item.text)}</div>"

    @_from?.destroy?()

    phones = mediator.user.get('phones')
    results = _.map phones, (phone) =>
      sanitized = utils.sanitizePhone(phone.number)
      {value: sanitized, text: utils.formatPhone(phone.number), opt_group: 'my'}

    recent = mediator.user.get('recent_alphas') or []
    for phone in recent
      if (phone.match(/^[a-zA-Z0-9 ]+$/) or utils.validatePhone(phone)) and 2 <= phone.length <= 17
        results.push {value: utils.sanitizePhone(phone), text: phone, opt_group: 'alpha'}

    for longcode in @numbers.models
      sanitized = utils.sanitizePhone(longcode.get('number'))
      results.push {
        value: sanitized
        country: longcode.get('country')
        connection: longcode.get('keywords')[0].connection
        text: utils.formatPhone(longcode.get('number'))
        opt_group: 'longcodes'
      }

    $_from = @$('#sms-from')

    $_from.selectize
      optgroups: [
        {value: 'my', label: 'My Verified Numbers'},
        {value: 'longcodes', label: 'Leased Numbers'},
        {value: 'alpha', label: 'Recently used alphanumerics'}
        {value: 'recentSearches', label: 'Recent searches'}
      ]
      optgroupField: 'opt_group',
      optgroupOrder: ['recentSearches', 'alpha', 'my', 'longcodes'],
      options: results
      render:
        option: render
      create: (value, callback) =>
        sanitized = utils.sanitizePhone(value)
        return callback {value: sanitized, text: value, opt_group: 'recentSearches'}
      onChange: (value) => @model.set {from: value}

    @_from = $_from[0].selectize

  dateModalClosed: ->
    filteredOptions = []
    if @model.get('from_date')
      filteredOptions = @dateOptions.filter (data) =>
        data.value is @model.get 'from_date'

    if filteredOptions.length and not @model.get('to_date')
      @_datepicker.setValue(filteredOptions[0].value)
    else
      @_datepicker.clear()
      @_datepicker.settings.placeholder = 'Custom'
      @_datepicker.updatePlaceholder()


  selectizeSendouts: ->
    @_sendouts?.destroy()
    $sendouts = @$('#sendouts').selectize
      valueField: 'id',
      labelField: 'label',
      searchField: 'label',
      options: @sendouts.toJSON()
      onChange: (value) =>
        batch = @sendouts.get(value)
        if batch
          @model.set {
            batch_id: batch.id
          }
        else
          @model.unset 'batch_id'
    @_sendouts = $sendouts[0].selectize

  selectizeConnections: ->
    @_connections?.destroy()
    $connections = @$('#connections').selectize
      valueField: 'id'
      labelField: 'label'
      searchField: 'label'
      options: @connections.toJSON()
      onChange: (value) =>
        connection = @connections.get(value)
        if connection
          @model.set {
            connection_id: connection.id
          }
        else
          @model.unset 'connection_id'
    @_connections = $connections[0].selectize

  selectizeDlrStatus: ->
    @_dlrStatus?.destroy()
    $dlrStatus = @$('#status').selectize
      plugins: ['remove_button']
      options: @dlrMap
      onChange: (value) =>
        if value
          @model.set {
            status: value.join()
          }
        else
          @model.unset 'status'
    @_dlrStatus = $dlrStatus[0].selectize

  submitForm: (mixed = {}) ->
    mixed.preventDefault?()
    data = {}
    for key, value of @model.toJSON()
      continue if not value or value is 'any'
      data[key] = value

    @search data

  search: (data) ->
    @errors = []
    fromDateText = 'No messages'
    for dateOption in @dateOptions
      if @model.get('from_date')? && dateOption.value is @model.get('from_date')
        fromDateText += ' in the ' + dateOption.text.toLowerCase()
        break
    $('.panel.fallback h3').text(fromDateText)
    if data.id
      @collection.urlRoot = "/2/sms/#{data.id}"
      data = null
    throbber = $('#lookup-form button[type="submit"]').spin()
    @collection.fetch(data: data, reset: true).then ->
      throbber.spin(false)
    , (data) =>
      seconds = data.responseText.match(/\d+ seconds?/)
      if data.status is 503 and seconds
        @errors = ["You have done too many requests in too short a time. Please wait #{seconds[0]} or change your search parameters."]
      else
        @errors = data.responseJSON

      throbber.spin(false)
    @collection.urlRoot = '/2/sms/'

  render: ->
    super
    @selectizeSendouts()
    @selectizeConnections()
    @selectizeDatepicker()
    @selectizeDlrStatus()

  dispose: ->
    return if @disposed
    @_sendouts?.destroy()
    @_connections?.destroy()
    @_dlrStatus?.destroy()
    properties = [
      '_connections', '_sendouts', '_before_date', '_before_time',
      '_after_date', '_after_time'
    ]
    delete this[prop] for prop in properties
    super

  clearInput: ->
    @_dlrStatus.clear()
    @_connections.clear()
    @_sendouts.clear()
    @_from?.clear()
    @_to?.clear()
    @model.clear()
    @model.set {from_date: @dateOptions[6].value}
    @_datepicker.setValue(@model.get('from_date'))
