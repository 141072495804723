Model = require '/models/base/model'
config = require '/config'
mediator = require '/mediator'

module.exports = class ContactImport extends Model
  urlRoot: '/2/contacts/uploads'

  defaults:
    status: -1
    file: undefined
    fileName: 'filename'
    groupId: undefined
    groupName: 'group name'
    total: 0
    successful: 0
    skipped: 0
    failed: 0
    uploadDate: undefined
    reportUrl: undefined
    fileUrl: undefined
    reportAvailable: false

  initialize: () ->
    @lastSocketEvent = new Date()
    @subscribeEvent 'imports-disposed', () =>
      @disposed = true

    refresh_rate = 2500
    if @get('status') <= 1 and not @disposed
      f = =>
        return if @disposed or @get('status') > 1
        @fallbackFetch()
        setTimeout(f, refresh_rate)
      setTimeout(f, refresh_rate)

  statusMap:
    '-1': 'not_uploaded'
    '0' : 'scheduled'
    '1' : 'processing'
    '2' : 'done'
    '3' : 'failed'
    '4' : 'interrupted'

  fallbackFetch: () =>
    return if @get('status') isnt 1
    timeSinceLastEvent = (new Date()) - @lastSocketEvent
    return if timeSinceLastEvent < 3000
    @fetch()

  parse: (response) ->

    response.groupName = response.group.name
    response.groupId = response.group.id
    response.groupURL = "/contacts/#{response.groupId}"
    response.fileName = response.filename
    response.reportAvailable = response.report_available
    response.uploadDate = response.upload_date if response.upload_date
    return response

  handleSocketData: (socketData) ->
    @lastSocketEvent = new Date()
    @set(@parse(socketData))

  hasIncidents: () ->
    return @get('failed') or @get('skipped')

  getSkippedPercent: () ->
    return "#{ (@get('skipped') / @get('total')) * 100 }%"
  getSuccessfulPercent: () ->
    return "#{ (@get('successful') / @get('total')) * 100 }%"
  getFailedPercent: () ->
    return "#{ (@get('failed') / @get('total')) * 100 }%"


  getFileDownloadURL: () ->
    return false if not @id or @getStatus() is 'not_uploaded'
    return config.API + @urlRoot + "/#{@id}/file?api_token=#{mediator.user.get('api_token')}"
  getReportDownloadURL: () ->
    return false if not @id or @getStatus() isnt 'done'
    return config.API + @urlRoot + "/#{@id}/report?api_token=#{mediator.user.get('api_token')}"
  getStatus: () ->
    return @statusMap[@get('status')]
