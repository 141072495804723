NavbarView = require 'views/navbar-view'
utils = require 'lib/utils'

module.exports = class MessagesNavbarView extends NavbarView

  items: [
    {
      href: utils.reverse 'messages#create'
      id: 'messages#create'
      title: 'New Message'
      icon: 'fa fa-pencil-square-o'
    }
    {
      href: utils.reverse 'messages#inbox'
      id: 'messages#inbox'
      title: 'Inbox'
      icon: 'fa fa-inbox'
    }
    {
      href: utils.reverse 'messages#outbox'
      id: 'messages#outbox'
      title: 'Sent Messages'
      icon: 'fa fa-archive'
    }
  ]
