config = require 'config'

success = () =>
  Application = require 'application'
  routes = require 'routes'
  ##== Webfonts
  ##
  #### Load some fonts
  WebFont.load
    google:
      families: ['Raleway']

  ##== Form Validation
  ##
  #### Bootstrap 3 config for parsley
  window.ParsleyConfig =
    excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled]'
    trigger: 'change'
    successClass: 'has-success'
    errorClass: 'has-error'
    classHandler: (ParsleyField) ->
      ParsleyField.$element.closest('.form-group')
    errorsContainer: (ParsleyField) ->
      group = ParsleyField.$element.closest('.form-group')
      container = group.find('.errors-container')
      if container.length
        container
      else
        ParsleyField.$element.closest('div')
    errorsWrapper: '<ul class="help-block list-unstyled parsley-errors-list"></ul>'
    errorTemplate: '<li></li>'

  ##== Moment
  ##
  #### Configure date and time formats
  moment.locale 'en',
    longDateFormat:
      LT: "HH:mm" # If this is to change. Check places in the code where LT:ss is used so that they won't break.
      L: "YYYY-MM-DD"
      LL: "D MMMM YYYY"
      LLL: "D MMMM YYYY LT"
      LLLL: "dddd D MMMM YYYY LT"
    calendar:
      lastDay: '[Yesterday at] LT'
      sameDay: '[Today at] LT'
      nextDay: '[Tomorrow at] LT'
      lastWeek: '[Last] dddd [at] LT'
      nextWeek: 'dddd [at] LT'
      sameElse: 'L'
    week:
      dow: 1, # Monday is the first day of the week.
      doy: 4  #The week that contains Jan 4th is the first week of the year.

  ##== jQuery
  ##
  #### add the dataTransfer property for use with the native `drop` event
  #### to capture information about files dropped into the browser window
  Backbone.$.event.props.push 'dataTransfer'

  spinnerDefaults =
    icon: 'circle-o-notch fa-spin'
    size: '1x'
  _.assign $.spin, $.spin, { defaults: spinnerDefaults }

  ##== Application
  ##
  #### Start up the machinery
  new Application {
    title: 'Beepsend',
    controllerSuffix: '-controller',
    routes
  }

fail = (e) =>
  errorElement = document.createElement('div')
  text = '<div style="padding: 30px; text-align: center;"><i class="fa fa-exclamation-triangle"></i> Seems that something has gone wrong. <a href="mailto:help.sms@twilio.com">Please contact support</a>.</div>'
  errorElement.innerHTML = text
  document.body.appendChild(errorElement)

config.__init().success(success).fail(fail)


