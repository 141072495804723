View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class SendoutMoItemView extends View
  tagName: 'tr'

  getTemplateFunction: ->
    (data) ->
      if data.to.contact
        fullEscapedContactName = _.escape(data.to.contact.firstname+' '+data.to.contact.lastname)
      else
        fullEscapedContactName = '<span class="text-muted">No Name</span>'
      if data.dlr_stat
        status = switch data.dlr_stat
          when 'DELIVRD' then 'Delivered'
          when 'UNDELIV', 'FAILED', 'DELETED' then 'Undelivered'
          when 'EXPIRED' then 'Expired'
          when 'REJECTD' then 'Rejected'
          else data.dlr_stat
      else status = 'No status'
      return """
        <td style="white-space:nowrap">#{ fullEscapedContactName }</td>
        <td>#{ _.escape(data.to.number or '') }</td>
        <td style="white-space:nowrap">#{ _.escape(status) }</td>
        <td style="white-space:nowrap">
          #{ if data.mo_body then _.escape(data.mo_body) else '<span class="text-muted"><i>no reply</i></span>' }
        </td>
      """
