Collection = require './collection'
utils = require 'lib/utils'

"""
This pagination class uses traditional style pagination where an offset is sent to backend.
This offset is used for the database query in which the return set is paginated. In this way
the pagination is not sensitive to sort order, since it is not dependent on a linearly
increasing or decreasing id. Hence no logic is performed whether to fetch greater than or
less than max_id
"""
module.exports = class PaginatedCollection extends Collection

  pagination: 'pagination'
  count: 200
  offset: 0

  url: ->
    url = super
    sep = if url.indexOf('?') >= 0 then '&' else '?'
    qs = utils.queryParams.stringify({@count})
    url += "#{sep}#{qs}" if qs
    url

  initialize: (models, options = {}) ->
    super
    @on 'reset', @getPrevNext

  getPrevNext: ->
    url = @url()

    nextId = @offset + @count
    next = @sync 'read', this, {url: "#{url}&offset=#{nextId}"}

    previousId = @offset - @count
    previous = if previousId >= 0 then @sync 'read', this, {url: "#{url}&offset=#{previousId}"}
    else
      []

    $.when(next, previous).then (next, previous) =>
      return if @disposed
      @finishSync()

      @next = _.first(next) or next or []
      @previous = _.first(previous) or previous or []

      @trigger "#{@pagination}:pagination", {
        next: if @next.length then true else false
        previous: if @previous.length then true else false
        paginated: if @next.length or @previous.length then true else false
      }

  setPrevious: =>
    return unless @previous and @previous.length
    @offset -= @count
    @beginSync()
    @reset @previous, parse: true, sort: true
    @getPrevNext()

  setNext: =>
    return unless @next and @next.length
    @offset += @count
    @beginSync()
    @reset @next, parse: true, sort: true
    @getPrevNext()

  dispose: ->
    return if @disposed
    delete @previous
    delete @next
    super
