View = require('./view')
Collection = require('models/base/collection')

module.exports = class CollectionView extends Chaplin.CollectionView
  # This class doesn’t inherit from the application-specific View class,
  # so we need to borrow the method from the View prototype:
  getTemplateFunction: View::getTemplateFunction

  loadingSelector: '.loading'
  loadingThrobber: { size: '4x', top: null }
  useCssAnimation: false
  animationDuration: 0

  # Dont bind rivets by default
  useRivets: false

  # Rivets bindings options placeholder
  rivets: null

  toggleLoadingIndicator: (collection) ->
    return if not collection instanceof Collection
    visible = @collection.isSyncing()
    loadContainer = @$('.js-collection-loader-container')
    loadContainer = if not loadContainer.length then @$el else loadContainer.eq(0)
    loadContainer.spin if visible then @loadingThrobber else false

  filterCallback: (view, included) ->
    view.$el[if included then 'removeClass' else 'addClass'] 'hidden'

  toggleFallback: =>
    visible = @visibleItems.length is 0 and (
      if typeof @collection.isSynced is 'function'
        # Collection is a SyncMachine.
        @collection.isSynced()
      else
        # Assume it is synced.
        true
    )
    @$fallback[if visible then 'removeClass' else 'addClass'] 'hidden'

  render: ->
    super
    @_rivets?.unbind()
    if @useRivets
      @_rivets = rivets.bind @el, {
        obj: {},
        @model,
        @collection,
        view: @,
        user: Chaplin.mediator.user
      }, @rivets or {}

  dispose: ->
    return if @disposed
    @_rivets?.unbind()
    properties = [
      'rivets', '_rivets', 'loadingThrobber', 'useRivets'
    ]
    delete this[prop] for prop in properties
    #for own prop, obj of this when obj and typeof obj.destroy is 'function'
    #  obj.destroy()
    #  delete this[prop]
    super
