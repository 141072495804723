NavbarView = require 'views/navbar-view'
utils = require 'lib/utils'

module.exports = class SettingsNavbarView extends NavbarView
  headerText: 'Settings'
  items: [
    {
      href: utils.reverse 'settings#user'
      id: 'settings#user'
      title: 'User'
      icon: 'fa fa-user'
    }
    {
      href: utils.reverse 'settings#connections'
      id: 'settings#connections'
      title: 'Connections'
      icon: 'fa fa-exchange'
    }
    {
      href: utils.reverse 'settings#numbers'
      id: 'settings#numbers'
      title: '2-Way Numbers'
      icon: 'fa fa-list-ol'
    }
  ]
  itemsRight: [
    {
      href: utils.reverse 'auth#login'
      id: 'auth#login'
      title: 'Sign out'
      icon: 'fa fa-sign-out'
    }
  ]
