Controller = require 'controllers/base/controller'
HomePageView = require 'views/home-page-view'
Users = require 'models/users'
ShowTokenView = require 'views/show-token-view'

module.exports = class HomeController extends Controller

  index: ->
    @adjustTitle 'Dashboard'
    description = "You are logged in as #{Chaplin.mediator.user.get('name')} (#{Chaplin.mediator.user.get('email')})"
    @publishEvent 'pageHeader', 'Dashboard', description: description

    @view = new HomePageView region: 'main'

  token: ->
    @adjustTitle 'Dashboard'
    description = "You are logged in as #{Chaplin.mediator.user.get('name')} (#{Chaplin.mediator.user.get('email')})"

    @publishEvent 'pageHeader', 'Dashboard', description: description

    @collection = new Users
    @collection.fetch(reset: true)

    @view = new HomePageView region: 'main'

    new ShowTokenView {autoRender: true , @collection}
