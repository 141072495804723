ModalView = require('./modal-view')
utils = require('lib/utils')

module.exports = class SendoutHelpModal extends ModalView
  autoRender: true

  options: {
    footer: true,
    header: true,
    title: '<i class="fa fa-exclamation-circle"></i>&nbsp;HLR Batch Help',
    primaryButton: 'Ok!',
    secondaryButton: false,
  }

  modalOptions: {
    keyboard: true,
    show: true
  }


  events:
    { 'hide.bs.modal': 'modalHide' }

  getModalTemplateFunction: ->
    => @getFullBody()

  getFullBody: ->
    return '''
      <h5>Label</h5>
      <p>
        A label so you easily can find your hlr batch later. We will
        automatically generate a label for you, but you are free to
        change it to whatever makes sense to you.
      </p>

      <h5>To</h5>
      <p>
        A list of contact groups and/or numbers to do hlr lookups on.
      </p>
    '''


  dispose: ->
    return if @disposed
    super
