Model = require '/models/base/model'

module.exports = class Connection extends Model

  apiAttrs: [
    'label',
    'description'
    'callbacks'
    'system_id'
  ]

  fetchNumbers: ->
    @sync 'read', this, url: @urlFor "/2/numbers/?connection_id=#{@id}"
