View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class MessagesListItemView extends View
  tagName: 'a'
  className: 'list-group-item'
  attributes: ->
    href: utils.reverse 'messages#show', messageId: _.escape(@model.id)

  render: ->
    super
    status = switch @model.get('dlr').status
      when 'DELIVRD' then 'list-group-item-success'
      when 'UNDELIV', 'FAILED', 'DELETED' then 'list-group-item-danger'
      when 'EXPIRED', 'REJECTD' then 'list-group-item-warning'
      when 'MOSMS' then 'list-group-item-info'
      else ''
    @$el.addClass(status) if status
    @$el.attr('title', 'Click to view SMS details')

  getTemplateFunction: ->
    (data) ->
      status = if data.dlr.status
        """<strong>Status:</strong> #{ _.escape(data.dlr.status) }"""
      else
        ''
      """
      <div class="row">
        <div class="col-xs-6 col-xs-push-6 col-sm-4 col-sm-push-8 text-right">
          <p><small>#{moment.unix(data.timestamps.sms.in).format('L LT')}</small></p>
        </div>
        <div class="col-xs-6 col-xs-pull-6 col-sm-4 col-sm-pull-4">
          <p><small><strong>From:</strong> #{ _.escape(data.from.address) }</small></p>
        </div>
        <div class="col-xs-12 col-sm-4 col-sm-pull-4">
          <p><small><strong>To:</strong> #{ _.escape(data.to.address) }</small></p>
        </div>
      </div>
      <p class="break-all">#{ _.escape(data.body) }</p>
      <div class="row">
        <div class="col-xs-6 col-xs-push-6 col-sm-4 col-sm-push-8 text-right">
          <small>€#{ _.escape(data.price) }</small>
        </div>
        <div class="col-xs-6 col-xs-pull-6 col-sm-4 col-sm-pull-4">
          <small>#{ _.escape(data.connection.label) }</small>
        </div>
        <div class="col-xs-12 col-sm-4 col-sm-pull-4">
          <small>#{ status }</small>
        </div>
      </div>
      """
