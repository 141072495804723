View = require 'views/base/view'
utils = require 'lib/utils'
mediator = require 'mediator'

module.exports = class WelcomeView extends View
  template: require './templates/welcome'
  useRivets: true

  events:
    'click [data-action="continue"]': 'continue'

  # Automatically set terms to true before showing the welcome message
  render: ->
    super
    @showLoader()
    verified = @model.get('verified')
    verified.terms = true
    @model.save({verified}).then =>
      @hideLoader()

  continue: (event) ->
    event.preventDefault()
    utils.redirectTo 'home#index'

  showLoader: () ->
    @$('.panel-body').addClass('hidden')
    @$('.spinner').removeClass('hidden')

  hideLoader: () ->
    @$('.panel-body').removeClass('hidden')
    @$('.spinner').addClass('hidden')
