View = require 'views/base/view'
mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class PasswordCreateView extends View
  template: require './templates/password-create'
  noWrap: true
  useRivets: true

  listen:
    'change:password model': 'validatePassword'

  events:
    'submit form': 'createPassword'
    'click [data-action="togglePasswordHelp"]': 'togglePasswordHelp'

  togglePasswordHelp: (e) ->
    elm = @$('.password-rules')
    target = $(e.target)
    elm.toggleClass('show')
    target.html(if elm.hasClass('show') then 'less...' else 'more...')

  passwordCheckResult: {}
  validatePassword: () ->
    password = @model.get('password')
    _.assign(@passwordCheckResult, utils.checkPassword(password or ''))

  initialize: ->
    @model.urlRoot = '/2/users/password/'
    @model.apiAttrs = ['password']
    super

  render: ->
    @_parsley?.destroy()
    super
    _.defer( () => @_parsley = @$('form').parsley() )

  createPassword: (event) ->
    event.preventDefault()
    if(!@passwordCheckResult.valid)
      @model.set('errors', ['The password is not strong enough. Review the password guidelines and enter a new password.'])
      return
    throbber = @$('.create-password').spin()

    @model.save().then (data) =>
      mediator.user.extendSession()
      mediator.user.set data
      window.ga? 'send', {
        'hitType': 'event',
        'eventCategory': 'auth',
        'eventAction': 'passwordCreated',
        'eventValue': mediator.user.id
      }
      if mediator.user.termsAccepted()
        utils.redirectTo 'home#index'
      else
        utils.redirectTo 'public#welcome'
    , (jqXHR) =>
      @model.set
        errors: ['Something went wrong. Try again later or contact support at help.sms@twilio.com']
      throbber.spin(false)

  dispose: ->
    return if @disposed
    @_parsley?.destroy()
    delete @_parsley
    super
