Model = require '/models/base/model'

module.exports = class Number extends Model
  concatConnections: ->
    conn = ""
    for keyword in @get("keywords")
      if conn isnt ""
        conn += ", "
      conn += keyword.connection.name
    conn
