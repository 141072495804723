View = require 'views/base/view'

module.exports = class ConversationMessageView extends View

  listen:
    'change model': 'render'

  initialize: ->
    super

  render: ->
    className = 'col-xs-10'
    unless @$el.hasClass(className)
      className += " col-xs-offset-2" if @model.get('from').address isnt @conversation.get('from').number
      @$el.addClass className
    super

  getTemplateFunction: ->
    isOriginator = @$el.hasClass('col-xs-offset-2')
    wrapperClass = if isOriginator then 'well well-sm' else 'alert alert-info'
    (data) =>
      timestamp = if data.pending
        '<i class="fa fa-spin fa-circle-o-notch"></i>'
      else if data.timestamps and data.timestamps.sms and data.timestamps.sms.in
        """
          <i class="fa fa-check text-success"></i> #{moment.unix(data.timestamps.sms.in).calendar()}
        """
      else
        ''
      # "double small" to make it small enough
      html = """
        <div class="no-bottom-buffer #{wrapperClass}">#{_.escape(data.body)}</div>
        <p class="small text-muted #{if isOriginator then 'text-right' else ''}">
          <small>
          #{ timestamp }
          </small>
        </p>
      """


  dispose: ->
    return if @disposed
    delete @conversation
    super
