CollectionView = require 'views/base/collection-view'
WalletSubscriberView = require 'views/wallet-subscriber-view'

module.exports = class WalletSubscribersView extends CollectionView
  itemView: WalletSubscriberView

  listSelector: '.list-group'
  fallbackSelector: '.fallback'

  useRivets: true

  events:
    'submit .add-email': 'submitForm'

  submitForm: (event) ->
    event.preventDefault()
    @collection.create {email: @model.get('email')}, at: 0
    @model.set email: ''
    @_parsley.reset()

  render: ->
    @_parsley?.destroy()
    super
    @_parsley = @$('.add-email').parsley()

  getTemplateFunction: ->
    (data) ->
      """
        <div class="panel panel-default">
          <div class="panel-heading">
            <h2 class="panel-title">
              Balance Reminder Subscribers&nbsp;
              <span rel="tooltip" title="Add additional subscriber for account balance notification"><i class="fa fa-question-circle"></i></span>
            </h2>
          </div>
          <div class="panel-body">
            <div class="row">
              <form class="add-email" role="form">
                <div class="form-group col-sm-12">
                  <div class="input-group">
                    <input type="email" class="form-control" id="email-subscriber" rv-value="model:email" placeholder="Enter email" required>
                    <span class="input-group-btn">
                      <button type="submit" class="btn btn-default">Add Email</button>
                    </span>
                  </div>
                  <div class="errors-container"></div>
                </div>
              </form>
            </div>
            <ul class="list-group"></ul>
            <div class="fallback text-center">
              <h3>No subscribers</h3>
            </div>
          </div>
        </div>
      """

  dispose: ->
    return if @disposed
    @_parsley?.destroy()
    delete @_parsley
    super
