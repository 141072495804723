Model = require 'models/base/model'
PublicSiteView = require 'views/public-site-view'
SiteFooter = require 'views/site-footer-view'
WelcomeView = require 'views/welcome-view'

TemplateView = require 'views/base/template-view'

# Extends Chaplin.Controller and not our base Controller to avoid
# authentication checks
module.exports = class PublicController extends Chaplin.Controller

  # Methods that should be public available to both authed and unauthed
  # users goes here. Methods that should ensure unauthed users goes
  # in auth controller

  beforeAction: (params, route, options) ->
    @reuse 'public-site', PublicSiteView
    @reuse 'public-site-footer', SiteFooter, region: 'publicFooter'

  welcome: ->
    @adjustTitle 'Welcome'
    @view = new WelcomeView {model: Chaplin.mediator.user, region: 'main'}

  notFound: ->
    @adjustTitle '404 Not Found'
    notFoundTemplate = require '/views/templates/not-found'
    @view = new TemplateView
      template: () -> notFoundTemplate
      region: 'main'
