View = require 'views/base/view'

module.exports = class PricelistItemView extends View
  className: 'panel panel-default'

  getTemplateFunction: ->
    (data) ->
      networks = ''
      for network in data.networks
        networks += """
          <li class="list-group-item clearfix">
            <small class="pull-left">
              <i class="fa-li fa fa-check-square text-success"></i>
              #{ _.escape(network.operator) }
            </small>
            <small class="pull-right">
              €#{ _.escape(network.price) }
            </small>
          </li>
        """

      price = if data.min_price is data.max_price
        "€#{ _.escape(data.max_price) }"
      else
        """<small>€#{ _.escape(data.min_price) }-#{ _.escape(data.max_price) }</small>"""

      """
      <div class="panel-heading toggable clearfix" data-toggle="collapse" data-parent=".panel-group" data-target="#collapse-#{data.mcc}">
        <h4 class="panel-title pull-left">
          #{ _.escape(data.name) }
        </h4>
        <h4 class="panel-title pull-right">#{ price }</h4>
      </div>
      <div id="collapse-#{ _.escape(data.mcc) }" class="panel-collapse collapse list-group list-group-condensed">
        #{networks}
      </div>
      """
