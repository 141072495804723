NavbarView = require('views/navbar-view')
HlrBatchHelpModal = require('./hlr-batch-help-modal')
utils = require('lib/utils')

module.exports = class HlrBatchNavbarView extends NavbarView
  events: {
    'click [nav-id="showHlrBatchHelp"]': 'openHelpModal'
  }

  items: [
    {
      href: utils.reverse('hlr-batch#create')
      id: 'hlr-batch#create'
      title: 'New HLR Batch'
      icon: 'fa fa-pencil-square-o'
    }
    {
      href: utils.reverse('hlr-batch#index')
      id: 'hlr-batch#index'
      title: 'Results'
      icon: 'fa fa-arrow-circle-o-right'
    }
  ]
  itemsRight: [
    {
      href: ''
      id: 'showHlrBatchHelp'
      title: 'HLR Batch Help'
      icon: 'fa fa-question-circle'
    }
  ]

  openHelpModal: (e) ->
    e.preventDefault()
    new HlrBatchHelpModal()
