Model = require '/models/base/model'
utils = require 'lib/utils'

module.exports = class Wallet extends Model

  apiAttrs: [
    'name'
    'notify_limit'
  ]

  reverse: =>
    utils.reverse 'wallets#show', walletId: @id

  reverseSettings: =>
    utils.reverse 'wallets#settings', walletId: @id

  reverseTransfer: =>
    utils.reverse 'wallets#transfer'

  topup: ->

    l = window.location

    url = "#{l.protocol}//#{l.host}#{utils.reverse 'wallets#show', walletId: @id}"

    @sync 'create', this, {
      url: @urlFor "/2/wallets/#{@id}/topup/paypal/"
      attrs:
        amount: parseFloat(@get('amount'))
        url:
          return: "#{url}?success"
          cancel: url
    }
