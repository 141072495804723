ModalView = require './modal-view'
ContactGroup = require 'models/contact-group'
utils = require 'lib/utils'

module.exports = class DeleteSendoutView extends ModalView

  autoRender: false

  options:
    footer: true
    header: true
    title: 'Delete Sendout'

  modalOptions:
    backdrop: 'static'
    keyboard: false
    show: true

  events:
    'click .delete': 'delete'

  getModalTemplateFunction: ->
    (data) => """
      #{@getFullBody()}
    """

  getFullBody: ->
    """
    <p>
      Are you sure you want to delete this Sendout?
    </p>
  """

  getModalHeader: ->
    """
    <div class="modal-header">
      #{@getCloseButton()}
      <h4 class="modal-title">#{@options.title}</strong></h4>
    </div>
    """

  getModalFooter: ->
    """
    <div class="modal-footer">
      <button class="btn btn-default" data-dismiss="modal" aria-hidden="true">Close</button>
      <button class="btn btn-danger delete">Delete</button>
    </div>
    """

  dispose: ->
    return if @disposed
    super
    # Remove any eventual first backdrop
    $('.modal-backdrop').eq(0).remove()

  delete: ->
    throbber = @$('.btn-danger').spin()
    onSuccess = ()=>
      noty
        text: """<i class="fa fa-comment"></i> Sendout was deleted successfully!"""
        type: 'success'
        timeout: 2000
    onFail = ()=>
      noty
        text: """<i class="fa fa-exclamation-circle"></i> The sendout is being processed and can't be deleted"""
        type: 'warning'
        timeout: 3000


    always = ()=>
      throbber.spin(false)
      @dismiss()

    @options.model.destroy(wait: true).success(onSuccess).fail(onFail).always(always)


