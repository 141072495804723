Collection = require 'models/base/collection'
Sendout = require './sendout'
utils = require 'lib/utils'

module.exports = class Sendouts extends Collection
  model: Sendout

  comparator: (model1, model2) ->
    return if model1.get('date_created') < model2.get('date_created') then 1 else -1

  count: 200
  offset: 0

  urlRoot: ->
    "/2/sendouts/?count=#{@count}"

  url: ->
    base = super
    base += "&offset=#{@offset}" if @offset > 0
    base

  initialize: (models, options = {}) ->
    if options.subscribeBuffer
      @mtCache = {}
      @subscribeEvent 'socket:state:mt', @cacheMTData
      @subscribeEvent 'socket:state:update', @socketSendoutUpdate

      @mtUpdateInterval = setInterval(@updateSendoutMTs, 250)
    @offset = parseInt(options.offset) or 0
    super

  fetchWithStats: ->
    @fetch(reset: true, silent: true).then =>
      endpointUrl = "/2/analytics/batches/?count=#{@count}"
      endpointUrl += "&offset=#{@offset}" if @offset > 0
      @fetch(
        add: false, merge: true, remove: false, silent: true
        url: @urlFor endpointUrl
      ).then =>
        @trigger 'reset'

  socketSendoutUpdate: (data) ->
    return if not data.id
    model = @get({id: data.id})
    return if not model
    model.set({ processed: data.processed, status: data.status })
    @trigger 'reset'

  cacheMTData: (data) ->
    # Not all data contains a batch id
    return if not data.batch or not data.batch.id
    # Build the proper hashmap structure if not preset already
    @mtCache[data.batch.id] = {} if not @mtCache[data.batch.id]
    status = utils.dlrMap[data.dlr.status]
    # Don't handle no_dlr. We have no proper way of actually measuring this
    return if status is "no_dlr"
    @mtCache[data.batch.id][status] = 0 if not @mtCache[data.batch.id][status]
    @mtCache[data.batch.id][status] += 1

  updateSendoutMTs:  =>
    for id, newStats of @mtCache
      sendout = @get(id)
      sendout.mergeSocketStats(newStats) if sendout
    @mtCache = {}

  # Get previous page url
  getPrevPageURL: ->
    offset = @offset - @count
    options = {}
    options.offset = offset if offset > 0
    utils.reverse 'messages#sendouts', {offset}

  dispose: ->
    return if @disposed
    clearInterval(@mtUpdateInterval)
    properties = [ 'mtCache' ]
    delete this[prop] for prop in properties
    super
