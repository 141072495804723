View = require 'views/base/view'
utils = require 'lib/utils'

DeleteSendoutModal = require 'views/sendout-delete-modal'
UpdateSendoutModal = require 'views/update-sendout-modal'


module.exports = class SendoutView extends View
  className: 'panel panel-default'
  useRivets: true
  template: require './templates/sendout-view'

  events:
    'click .csv': 'fetchCsv'
    'click .edit-sendout': 'updateSendout'
    'click .delete-sendout': 'openDeleteModal'

  updateSendout: (event) ->
    if @model.get('status') is 0
      utils.redirectTo({controller: 'sendouts', action: 'edit', params: {id: @model.id}})
    else
      @subview new UpdateSendoutModal {model: @model, autoRender: true}

  openDeleteModal: =>
    if @model.get('status') is 0
      # deletion is handled in the modalview
      @subview new DeleteSendoutModal {model: @model, autoRender: true}

  constructor: ->
    @rivets =
      binders:
        'status-width': @statusWidth
    super

  statusWidth: (el, value) =>
    width = '0%'
    width = (value / @model.get('total')) * 100 if value
    el.style.width = "#{width}%"

  fetchCsv: (event) ->
    id = event.currentTarget.getAttribute('data-id')
    url = "/2/sendouts/#{id}/messages.csv?api_token=#{Chaplin.mediator.user.get('api_token')}"
    if @customer.get('pricelist_delimiter')
      delimiter = encodeURIComponent(@customer.get('pricelist_delimiter'))
      url += "&delimiter=#{delimiter}"
    window.location = @model.urlFor(url)

    downloadLink = @$(event.target)
    downloadLink.spin().addClass('disabled')
    setTimeout ( ->
      downloadLink.spin(false).removeClass('disabled')
    ), 5000
