View = require 'views/base/view'
utils = require 'lib/utils'
mediator = require 'mediator'

module.exports = class UserView extends View
  tagName: 'li'
  className: 'list-group-item'
  useRivets: true

  listen:
    'change:edit model': 'render'
    'change:phones model': 'render'
    'change:new_password model': 'validatePassword'

  events:
    'click .edit-user': 'editUser'
    'click .edit-password': 'editPassword'
    'click .edit-email': 'editEmail'
    'click .cancel-edit': 'cancelEdit'
    'submit .user-form': 'saveUser'
    'submit .user-email-form': 'saveEmail'
    'submit .user-password-form': 'savePassword'
    'click [data-action="togglePasswordHelp"]': 'togglePasswordHelp'


  togglePasswordHelp: (e) ->
    elm = @$('.password-rules')
    target = $(e.target)
    elm.toggleClass('show')
    target.html(if elm.hasClass('show') then 'less...' else 'more...')

  passwordCheckResult: {}
  validatePassword: () ->
    password = @model.get('new_password')
    _.assign(@passwordCheckResult, utils.checkPassword(password or ''))

  editUser: (event) ->
    event.preventDefault()
    @model.set edit: 'edit'

  editEmail: (event) ->
    event.preventDefault()
    @model.set edit: 'email'

  editPassword: (event) ->
    event.preventDefault()
    @model.set edit: 'password'

  cancelEdit: (event) ->
    event.preventDefault()
    if @model.isNew()
      @model.collection.remove @model
    else
      @model.set edit: false

  saveUser: (event) ->
    event.preventDefault()
    throbber = @$('.save-user').spin()
    @model.save()
      .success =>
        if @model.id is mediator.user.id
          mediator.user.set @model.toJSON()
        @model.set {
          edit: false
          alert: 'User successfully updated!'
        }
      .fail (error) =>
        errors = error.responseJSON.errors.map (error) -> return _.escape(error)
        @$('.alert').removeClass('hidden')
        @$('.alert').html """<ul><li>#{errors.join('</li><li>')}</li></ul>"""
      .always () =>
        throbber.spin(false)

  saveEmail: (event) ->
    event.preventDefault()
    throbber = @$('.save-email').spin()
    @model.setEmail().then =>
      @model.set {
        edit: false
        alert: """
          Email updated! You will receive a verification at
          #{ _.escape(@model.get('email')) }. You need to confirm the change through the
          link in the email.
        """
      }
    , (jqXHR) =>
      errors = jqXHR.responseJSON.errors.map (error) -> _.escape(error)
      alert = @$('.alert').html """<ul><li>#{errors.join('</li><li>')}</li></ul>"""
      throbber.spin(false)

  savePassword: (event) ->
    event.preventDefault()
    if(!@passwordCheckResult.valid)
      @$('.alert').removeClass('hidden')
      alert = @$('.alert').html("""The password is not strong enough. Review the password guidelines and enter a new password.""")
    else
      throbber = @$('.save-password').spin()
      @model.setPassword().then =>
        @model.set
          edit: false
          alert: 'Password updated successfully!'
      , (jqXHR) =>
        errors = jqXHR.responseJSON.errors.map (error) -> _.escape(error)
        @$('.alert').removeClass('hidden')
        @$('.alert').html("""<ul><li>#{errors.join('</li><li>')}</li></ul>""")
        throbber.spin(false)

  render: ->
    @_parsley?.destroy()
    edit = @model.get('edit')
    super
    switch edit
      when 'edit'
        @selectizeUserTypes()
        @selectizeConnections()
        @selectizePhones()
        @_parsley = @$('.user-form').parsley()
      when 'email'
        @_parsley = @$('.user-email-form').parsley()
      when 'password'
        @_parsley = @$('.user-password-form').parsley()

  # Can be done with checkboxes and rivets:
  # https://github.com/mikeric/rivets/issues/328#issuecomment-47148701
  selectizeUserTypes: ->
    @_userTypes?.destroy()
    $userTypes = @$("#user-types-#{@model.id}").selectize
      plugins: ['remove_button']
      options: [
        {value: 1, text: 'Technical Contact'}
        {value: 2, text: 'Account Contact'}
        {value: 3, text: 'Support Contact'}
        {value: 4, text: 'Price List Contact'}
        {value: 5, text: 'Finance Contact'}
      ]
      onChange: (values) =>
        user_types = if values then ({id: value} for value in values) else null
        @model.set {user_types}
    @_userTypes = $userTypes[0].selectize
    # populate field with values already in the model
    @_userTypes.addItem(type.id) for type in @model.get('user_types')

  selectizeConnections: ->
    return unless @connections
    @_connections?.destroy()
    render = (item, escape) ->
      """
        <div>
          <strong>#{escape(item.label)}</strong>
          <p class="text-muted no-bottom-buffer">
            <small>#{escape(item.wallet.name)} €#{escape(item.wallet.balance)}</small>
          </p>
        </div>
      """
    $_connections = @$("#default-connection-#{@model.id}").selectize
      valueField: 'id',
      labelField: 'label',
      searchField: 'label',
      options: @connections.toJSON()
      onChange: (value) =>
        default_connection = @connections.get(value)
        return unless default_connection
        @model.set {default_connection}
      render:
        option: render

    @_connections = $_connections[0].selectize
    @_connections.setValue @model.get('default_connection').id

  selectizePhones: ->
    $_phones = @$("#phone-#{@model.id}").selectize
      plugins: {
        'remove_button': {append: false}
      }
      options: @model.get('phones')
      valueField: 'number'
      labelField: 'number'
      createOnBlur: true
      create: (value, callback) =>
        ret = number: value
        phones = @model.get('phones')
        phones.push ret
        @model.set {phones}
        callback ret
      onDelete: (values) =>
        phones = @model.get('phones')
        phones = _.filter(phones, (phone) -> phone.number != values[0])
        @model.set {phones}
      render:
        item: (data, escape) ->
          return """
            <div class='item #{if data.primary_number then "active" else ""}'>
            <div class='number'>#{ _.escape(data.number) }</div>
            #{if data.primary_number then "<div class='primary-number'><i class='fa fa-check'></i></div>" else "<div class='remove'>&times;</div>"}
            </div>
          """

    @_phones = $_phones[0].selectize
    @_phones.addItem(phone.number) for phone in @model.get('phones')

    @$(".number").on "mouseup", (event) =>
      selectedPhone = event.currentTarget.parentElement.attributes['data-value'].value
      originalPhones = @model.get('phones')
      phones = _.cloneDeep(originalPhones)
      phones = _.each phones, (phone) ->
        if phone.number is selectedPhone
          phone.primary_number = true
        else
          phone.primary_number = false
        return
      @model.set {phones}

  getTemplateData: ->
    data = super
    data

  getTemplateFunction: ->
    switch @model.get('edit')
      when 'edit' then @editTemplate
      when 'email' then @editEmailTemplate
      when 'password' then @editPasswordTemplate
      else @defaultTemplate

  defaultTemplate: (data) ->
    texts = []
    icon = "<i class=\"fa fa-fw fa-envelope\"></i> "
    textType = if data.verified.email then 'success' else 'danger'
    icon = """<span class="text-#{textType}">#{icon}</span>"""
    texts.push icon + _.escape(data.email)
    icon = "<i class=\"fa fa-fw fa-phone\"></i> "
    textType = if data.verified.phone then 'success' else 'danger'
    icon = """<span class="text-#{textType}">#{icon}</span>"""
    texts.push icon + utils.formatPhone data.phone if data.phone
    texts.push """
      <strong>Api Token:</strong> <code>#{ _.escape(data.api_token) }</code>
      <span rel="tooltip" title="Your API Token will grant you access to the API"><i class="fa fa-question-circle"></i></span>
    """

    html = ''
    for text in texts
      html += """<p class="list-group-item-text">#{text}</p>"""

    alert = if data.alert
      """
      <div class="alert alert-success">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
        <p>#{ _.escape(data.alert) }</p>
      </div>
      """
    else
      ''

    """
    #{alert}
    <div class="clearfix">
      <div class="btn-toolbar pull-right">
        <div class="btn-group">
          <button class="btn btn-default edit-password">
            <i class="fa fa-key fa-fw"></i>
          </button>
          <button class="btn btn-default edit-email">
            <i class="fa fa-envelope-o fa-fw"></i>
          </button>
          <button class="btn btn-default edit-user">
            <i class="fa fa-pencil fa-fw"></i>
          </button>
        </div>
      </div>
      <h4 class="list-group-item-heading">#{ _.escape(data.name) }</h4>
      #{html}
    </div>
    """

  editTemplate: (data) =>
    """
    <form class="form-horizontal user-form" role="form">
      <div class="row top-buffer">
        <div class="col-sm-8 col-sm-offset-3">
          <div class="alert alert-danger hidden"></div>
        </div>
      </div>
      <div class="form-group">
        <label for="name-#{@model.id}" class="col-sm-3 control-label">Name</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="name-#{@model.id}" rv-value="model:name" data-parsley-minlength="3" required>
        </div>
      </div>
      <div class="form-group">
        <label for="phone-#{@model.id}" class="col-sm-3 control-label">Phone</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="phone-#{@model.id}" required>
          <span class="label label-info"><i class="fa fa-question-circle"></i> Info</span>
          The highlighted number is your primary number, this cannot be deleted. To select another number as your primary, click any of your verified numbers in the list and save.
          When entering a new number, a verification SMS will be sent to your new number asking you to verify the change. Until then, the number will not be visible in this list.
        </div>
      </div>
      <div class="form-group" rv-show="view.connections.length | gt 1">
        <label for="default-connection-#{@model.id}" class="col-sm-3 control-label">Default Connection</label>
        <div class="col-sm-9">
          <select id="default-connection-#{@model.id}"></select>
        </div>
      </div>
      <div class="form-group">
        <label for="user-types-#{@model.id}" class="col-sm-3 control-label">User Types&nbsp;<span rel="tooltip" title="Set which type of correspondence with Beepsend you want, you can select multiple user roles"><i class="fa fa-question-circle"></i></span></label>
        <div class="col-sm-9">
          <select id="user-types-#{@model.id}" multiple="multiple"></select>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-3 col-sm-9">
          <button type="submit" class="btn btn-default save-user">Save</button>
          <button type="button" class="btn btn-link cancel-edit">Cancel</button>
        </div>
      </div>
    </form>
    """

  editEmailTemplate: (data) =>
    """
    <h4>Email for #{ _.escape(data.name) }</h4>
    <hr>
    <form class="form-horizontal user-email-form" role="form">
      <div class="row top-buffer">
        <div class="col-sm-8 col-sm-offset-3">
          <div class="alert alert-danger hidden"></div>
        </div>
      </div>
      <div class="form-group">
        <label for="email-#{@model.id}" class="col-sm-3 control-label">Email</label>
        <div class="col-sm-9">
          <input type="email" class="form-control" id="email-#{@model.id}" rv-value="model:email | lowercase | trim" data-parsley-maxlength="255">
          <span class="label label-info"><i class="fa fa-question-circle"></i> Info</span>
          An email will be sent to your new email asking you to verify the change. Until then your current email will be used.
        </div>
      </div>
      <div class="form-group">
        <label for="email-password-#{@model.id}" class="col-sm-3 control-label">Current Password</label>
        <div class="col-sm-9">
          <input type="password" class="form-control" id="email-password-#{@model.id}" rv-value="model:email_password" required>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-3 col-sm-9">
          <button type="submit" class="btn btn-default save-email">Save</button>
          <button type="button" class="btn btn-link cancel-edit">Cancel</button>
        </div>
      </div>
    </form>
    """


  editPasswordTemplate: (data) =>
    """
    <h4>Password for #{ _.escape(data.name) }</h4>
    <hr>
    <form class="form-horizontal user-password-form" role="form">
      <div class="row top-buffer">
        <div class="col-sm-8 col-sm-offset-3">
          <div class="alert alert-danger hidden"></div>
        </div>
      </div>
      <div class="form-group">
        <label for="password-#{@model.id}" class="col-sm-3 control-label">Current Password</label>
        <div class="col-sm-9">
          <input type="password" class="form-control" id="password-#{@model.id}" rv-value="model:password" required>
        </div>
      </div>
      <div class="form-group">
        <label for="new-password-#{@model.id}" class="col-sm-3 control-label">New Password</label>
        <div class="col-sm-9">
          <input type="password" class="form-control" id="new-password-#{@model.id}" data-parsley-maxlength="50" rv-live-value="model:new_password" rv-password-strength="model:new_password" required>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-3 hidden-xs control-label"></label>
        <div class="col-sm-9">
          <div class="password-rules">
            <div>
              <i class="fa fa-check-circle fa-fw text-success" rv-show="view.passwordCheckResult.lengthOK"></i>
              <i class="fa fa-times-circle fa-fw text-danger" rv-hide="view.passwordCheckResult.lengthOK"></i>
              &nbsp;Minimum 16 characters <a class="pull-right" data-action="togglePasswordHelp">more...</a>
            </div>
            <div>Try to satisfy as many as possible:</div>
            <div>
              <i class="fa fa-check-circle fa-fw" rv-show="view.passwordCheckResult.hasUppercase"></i>
              <i class="fa fa-angle-right fa-fw" rv-hide="view.passwordCheckResult.hasUppercase"></i>
              &nbsp;One UPPER CASE letter
            </div>
            <div>
              <i class="fa fa-check-circle fa-fw" rv-show="view.passwordCheckResult.hasLowercase"></i>
              <i class="fa fa-angle-right fa-fw" rv-hide="view.passwordCheckResult.hasLowercase"></i>
              &nbsp;One lower case letter
            </div>
            <div>
              <i class="fa fa-check-circle fa-fw" rv-show="view.passwordCheckResult.hasSpecialChar"></i>
              <i class="fa fa-angle-right fa-fw" rv-hide="view.passwordCheckResult.hasSpecialChar"></i>
              &nbsp;One special character
            </div>
            <div>
              <i class="fa fa-check-circle fa-fw" rv-show="view.passwordCheckResult.hasNumber"></i>
              <i class="fa fa-angle-right fa-fw" rv-hide="view.passwordCheckResult.hasNumber"></i>
              &nbsp;One Number
            </div>
            <ul class="extra-content">
              <li>
                Consider using a passphrase. A long sentence that has meaning for you and includes special characters and is difficult to crack, e.g. Owls are such lovely creatues!
              </li>
              <li>
                Make your password significantly different each time and make it hard to guess. Don't include personal information or your user name in your password.
              </li>
              <li>
                Avoid using repeating or sequential characters in your password, e.g. Beepsenduser1234 or Grandma's8888
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div class="form-group">
        <label for="confirm-password-#{@model.id}" class="col-sm-3 control-label">Confirm New Password</label>
        <div class="col-sm-9">
          <input type="password" class="form-control" id="confirm-password-#{@model.id}" rv-value="model:confirm_password" data-parsley-equalto="#new-password-#{@model.id}">
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-3 col-sm-9">
          <button type="submit" class="btn btn-default save-password">Save</button>
          <button type="button" class="btn btn-link cancel-edit">Cancel</button>
        </div>
      </div>
    </form>
    """

  dispose: ->
    return if @disposed
    @_parsley?.destroy()
    @_userTypes?.destroy()
    delete @_parsley
    delete @userTypes
    super
