View = require 'views/base/view'
mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class TransferFundsView extends View
  template: require './templates/transfer-funds'

  useRivets: true

  listen:
    'synced collection': 'selectize'

  events:
    'submit form': 'transferFunds'

  transferFunds: (event) ->
    event.preventDefault()

    error = @$('.alert-danger')
    error.remove() if error.length

    throbber = @$('.transfer-funds').spin()
    url = @collection.urlFor "/2/wallets/#{@_from.getValue()}/transfer/#{@_to.getValue()}/"
    amount = @$('#amount').val()
    sync = @collection.sync 'create', @collection, {
      url,
      attrs: {amount}
    }

    sync.then (data) =>
      throbber.spin(false)
      @renderSuccess(data)
    , (request) =>
      throbber.spin(false)
      data = JSON.parse request.response
      @renderError(data.errors)

  selectize: ->
    @_from?.destroy()
    @_to?.destroy()
    render = (item, escape) ->
      """
        <div>
          <strong>#{escape(item.name)}</strong>
          <p class="text-muted form-control-static">
            <small>€#{escape(item.balance)}</small>
          </p>
        </div>
      """
    options =
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      options: @collection.toJSON()
      render:
        item: (item, escape) ->
          """
            <div>
              <strong>#{escape(item.name)}</strong>
              <small>€#{escape(item.balance)}</small>
            </div>
          """
        option: (item, escape) ->
          """
            <div>
              <strong>#{escape(item.name)}</strong>
              <p class="text-muted form-control-static">
                <small>€#{escape(item.balance)}</small>
              </p>
            </div>
          """
    $from = @$('#from').selectize options
    $to = @$('#to').selectize options

    @_from = $from[0].selectize
    @_to = $to[0].selectize

  render: ->
    super
    @$panel = @$('.panel')
    @selectize()
    @_parsley = @$('form').parsley()

  renderSuccess: (data) ->
    @$panel.addClass 'hidden'

    from = utils.reverse 'wallets#show',
      walletId: data.source_wallet.id

    to = utils.reverse 'wallets#show',
      walletId: data.target_wallet.id

    $('<div>', class: 'alert alert-success').html("""
      <h4 class="alert-heading">Transaction Complete</h4>
      <p>
        Successfully transfered <strong>€#{ _.escape(data.amount) }</strong> from
        <strong>#{ _.escape(data.source_wallet.name) }</strong> to
        <strong>#{ _.escape(data.target_wallet.name) }</strong>.
      </p>
      <p class="text-center">
        <a href="#{ _.escape(from) }" class="btn btn-success btn-lg">
          #{ _.escape(data.source_wallet.name) }
        </a>
        <a href="#{ _.escape(to) }" class="btn btn-success btn-lg">
          #{ _.escape(data.target_wallet.name) }
        </a>
      </p>
    """).appendTo @$el

  renderError: (errors) ->
    errors = errors.map (error) -> return _.escape(error)
    $('<div>', class: 'alert alert-danger').html("""
      <h4 class="alert-heading">Transfer failed</h4>
      <ul><li>#{errors.join('</li><li>')}</li></ul>
    """).insertBefore @$panel

  dispose: ->
    return if @disposed
    @_from?.destroy()
    @_to?.destroy()
    @_parsley?.destroy()
    properties = [
      '_from', '_to', '_parsley', '$panel'
    ]
    delete this[prop] for prop in properties
    super
