CollectionView = require 'views/base/collection-view'
ContactsListItemView = require 'views/contacts-list-item-view'
utils = require 'lib/utils'

module.exports = class ContactsView extends CollectionView
  template: require './templates/contacts-list'
  itemView: ContactsListItemView

  useRivets: true
  renderItems: false

  listSelector: '.list-group'
  fallbackSelector: '.fallback'

  listen:
    'change:query model': 'search'
    'synced collection': 'checkCollectionLength'

  events:
    'click .new-contact': 'newContact'
    'click .send-group-sms': 'sendGroupSMS'
    'click .csv': 'fetchCsv'

  optionNames: CollectionView::optionNames.concat ['groups']

  initialize: (options) ->
    super
    @model.set isFirstPage: @collection.offset is 0

  checkCollectionLength: ->
    @model.set isLastPage: @collection.length is 0
    if @collection.models.length is 0 then @$('.csv').addClass('disabled') else @$('.csv').removeClass('disabled')

  nextPage: (event) =>
    event.preventDefault()
    @collection.offset = @collection.offset + @collection.count
    @pageChangeURL()

  previousPage: (event) =>
    event.preventDefault()
    @collection.offset = @collection.offset - @collection.count
    @collection.offset = 0 if @collection.offset < 0
    @pageChangeURL()

  pageChangeURL: ->
    @collection.fetch(reset: true)
    options = {}
    options.offset = @collection.offset if @collection.offset > 0
    route = if @groupId
      utils.reverse 'contacts#group', {@groupId}, options
    else
      utils.reverse 'contacts#index', {}, options
    @model.set isFirstPage: @collection.offset is 0
    Backbone.history.navigate route, trigger: false

  newContact: (event) ->
    event.preventDefault()
    groups = []

    if @collection.groupId?
      groups = [{id: @collection.groupId}]

    @model = @collection.unshift new @collection.model
      edit: true
      groups: groups
      isOwner: true

    $.getJSON("//api.wipmania.com/jsonp?callback=?").then (data) =>
      detectedCountry = data.address.country_code.toLowerCase()
      @$("#phone-#{@model.cid}").intlTelInput 'selectCountry', detectedCountry
      @$("#phone-#{@model.cid}").focus()

  sendGroupSMS: (event) ->
    event.preventDefault()

    if @collection.groupId
      route = utils.reverse 'sendouts#create', {}, group: @collection.groupId
      Backbone.history.navigate route, trigger: true

  search: (model, query) ->
    if query
      @collection.search query
    else
      @collection.fetch(reset: true)


  filterItems: (model, query, options) ->
    query = query.toLowerCase()
    @filter (contact) ->
      ret = true

      return ret unless query

      name = contact.get('name')
      phone = contact.get('msisdn')

      needle = "#{name}|#{phone}"

      ret and needle.toLowerCase().indexOf(query) isnt -1

  initItemView: (model) ->
    view = super
    view.group = @model
    view.groups = @groups
    view.listenTo view.group, 'synced', view.render
    view

  fetchCsv: (event) ->
    path = @collection.urlRoot() + "&csv=true&api_token=#{Chaplin.mediator.user.get('api_token')}"
    window.location = @model.urlFor(path)
    # Disable the link and show the spinner, after 5 seconds enable it again
    downloadLink = @$(event.target)
    downloadLink.spin().addClass('disabled')
    setTimeout ( ->
      downloadLink.spin(false).removeClass('disabled')
    ), 5000
