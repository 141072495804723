
NavbarView = require 'views/navbar-view'
SendoutHelpModal = require('./sendout-help-modal')
utils = require 'lib/utils'

module.exports = class SendoutsNavbarView extends NavbarView
  events:
    'click [nav-id="showSendoutHelp"]': 'openHelpModal'

  items: [
    {
      href: utils.reverse 'sendouts#create'
      id: 'sendouts#create'
      title: 'New Sendout'
      icon: 'fa fa-pencil-square-o'
    }
    {
      href: utils.reverse 'sendouts#index'
      id: 'sendouts#index'
      title: 'Sendouts'
      icon: 'fa fa-arrow-circle-o-right'
    }
  ]
  itemsRight: [
    {
      href: ''
      id: 'showSendoutHelp'
      title: 'Sendout Help'
      icon: 'fa fa-question-circle'
    }
  ]

  openHelpModal: (e) ->
    e.preventDefault()
    new SendoutHelpModal()
