CollectionView = require 'views/base/collection-view'
UserView = require 'views/user-view'

module.exports = class UsersView extends CollectionView
  template: require './templates/users'
  itemView: UserView

  useRivets: true
  renderItems: false

  listSelector: '.list-group'
  fallbackSelector: '.fallback'

  listen:
    'change:query model': 'filterItems'

  optionNames: CollectionView::optionNames.concat ['connections']

  filterItems: (model, query, options) ->
    query = query.toLowerCase()
    @filter (user) ->
      ret = true

      return ret unless query

      name = user.get('name')
      username = user.get('username')
      email = user.get('email')
      phone = user.get('phone')

      needle = "#{name}|#{username}|#{email}|#{phone}"

      ret and needle.toLowerCase().indexOf(query) isnt -1

  initItemView: (model) ->
    view = super
    view.connections = @connections
    view
