Collection = require 'models/base/collection'
Connection = require './connection'

module.exports = class Connections extends Collection
  model: Connection

  urlRoot: '/2/connections/'

  comparator: 'label'

  fetchWithType: (type) ->
    @fetch(silent: true).then =>
      return if @disposed
      @remove (model for model in @models when type isnt model.get('type'))
      @trigger 'reset', this
