PaginatedCollection = require 'models/base/paginated-collection'

module.exports = class SendoutMoMessages extends PaginatedCollection
  urlRoot: ->
    "/2/sendouts/#{@sendoutId}/messages/"

  initialize: (models, options={}) ->
    @sendoutId = options.id
    @getPrevNext()

  dispose: ->
    return if @disposed
    delete @sendoutId
    super
