Collection = require 'models/base/collection'
WalletSubscriber = require './wallet-subscriber'

module.exports = class WalletSubscribers extends Collection
  model: WalletSubscriber

  comparator: 'id'

  urlRoot: ->
    "/2/wallets/#{@walletId}/emails/"

  initialize: (models, options = {}) ->
    @walletId = options.walletId or null
    super

  dispose: ->
    return if @disposed
    delete @walletId
    super
