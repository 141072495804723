mediator = require 'mediator'

Controller = require 'controllers/base/controller'
Model = require 'models/base/model'

# settings navbar
SettingsNavbarView = require 'views/settings-navbar-view'


# for connections view
Connections = require 'models/connections'
ConnectionsView = require 'views/connections-view'

# for users view
Customer = require 'models/customer'
Users = require 'models/users'
CustomerView = require 'views/customer-view'
UsersView = require 'views/users-view'

# for numbers view
Numbers = require 'models/numbers'
NumbersView = require 'views/numbers-view'


module.exports = class ConnectionsController extends Controller

  beforeAction: ->
    super
    return if @redirected
    @reuse 'settings-navbar', SettingsNavbarView, region: 'main'

  connections: ->
    @adjustTitle 'Connections'
    @publishEvent 'pageHeader', 'Connections', description: 'Manage your Connections, mainly for developers'

    @collection = new Connections
    @model = new Model

    @view = new ConnectionsView {@collection, @model, region: 'main'}

    @collection.fetch(reset: true)


  user: ->
    @adjustTitle 'User Settings'
    @publishEvent 'pageHeader', 'User Settings', description: 'Settings for your account'

    @collection = new Users
    @model = new Model

    @connections = new Connections
    if mediator.user.get('customer')?
      @customer = new Customer
      @customerView = new CustomerView {model: @customer, region: 'main'}
      @customer.fetch()

    @view = new UsersView {@collection, @connections, @model, region: 'main'}
    @connections.fetch(reset: true)

    @collection.fetch(reset: true)


  numbers: ->
    @model = new Model
    @collection = new Numbers

    @adjustTitle 'Numbers'
    @publishEvent 'pageHeader', '2-Way Numbers', description: 'Here you can see your beepsend numbers used for 2-Way communication'

    @view = new NumbersView {@model, @collection, region: 'main'}

    @collection.fetch(reset: true)
