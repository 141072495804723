View = require 'views/base/view'

module.exports = class NavbarView extends View
  className: 'navbar navbar-default'

  items: []
  itemsRight: []
  useRivets: true

  listen:
    'dispatcher:dispatch mediator': 'setActiveLink'

  setActiveLink: (controller, params, route, options) ->
    return if @disposed
    @$('[nav-id]').removeClass('active')
    @$("[nav-id*='#{route.name}']").addClass('active')

  template: () ->
    return """
      <div class="container-fluid">
        <ul class="nav navbar-nav" rv-if="view.items | length">
          <li rv-each-item="view.items" rv-nav-id="item.id">
            <a rv-href="item.href" rv-title="item.title">
              <i rv-class="item.icon"></i> {item.title}
            </a>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right" rv-if="view.itemsRight | length">
          <li rv-each-item="view.itemsRight">
            <a rv-href="item.href" rv-title="item.title" rv-nav-id="item.id">
              <i rv-class="item.icon"></i> {item.title}
            </a>
          </li>
        </ul>
      </div>
    """

  dispose: ->
    return if @disposed
    delete @items
    delete @itemsRight
    super
