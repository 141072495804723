Model = require '/models/base/model'
utils = require 'lib/utils'

module.exports = class Pricelist extends Model

  urlRoot: -> "/2/connections/#{@get('account').id}/pricelists/current"

  defaults:
    networks: []
    account:
      id: null

  parse: (response) ->
    countries = {}

    for network in response.networks
      if network.country.name of countries

        if network.price < countries[network.country.name].min_price
          countries[network.country.name].min_price = network.price
        else if network.price > countries[network.country.name].max_price
          countries[network.country.name].max_price = network.price

        countries[network.country.name].networks.push network

      else

        countries[network.country.name] =
          name: network.country.name
          prefix: network.country.prefix
          code: network.country.code
          mcc: network.mccmnc[0].mcc
          min_price: network.price
          max_price: network.price
          networks: [network]

    # Cast object to array and sort networks
    response.countries = for name, country of countries
      country.networks = _.sortBy country.networks, (network) ->
        if network.operator is 'Default'
          network.operator = if country.networks.length > 1
            "All other networks in #{network.country.name}"
          else
            "All networks in #{network.country.name}"
          'ZZZZZ'
        else
          network.operator
      country
    response

  reverse: =>
    utils.reverse 'pricelist#show', connectionId: @get('account').id

  getName: ->
    account = @get('account')
    if account.name
      "#{account.name} price list"
    else
      "Unknown price list"
