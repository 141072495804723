View = require 'views/base/view'
mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class LoginView extends View
  noWrap: true
  template: require './templates/login'
  useRivets: true

  events:
    'submit form': 'authenticate'
    'click #password-reset': 'passwordReset'

  passwordReset: (event) ->
    event.preventDefault()
    event.stopPropagation()
    utils.redirectTo 'auth#passwordReset', {email: @model.get('email')}

  # Trigger the authentication request against the server
  authenticate: (event) =>
    event.preventDefault()

    @_rivets.publish()

    email = @model.get('email')
    password = @model.get('password')

    unless email and password
      return @model.set alert: 'Please fill in your email and password'

    # Spin the button
    throbber = @$('.btn').spin()

    auth = mediator.user.authenticate email.toLowerCase(), password

    auth.then (data) =>
      mediator.user.extendSession()
      mediator.user.set data, parse: true
      window.ga? 'send', {
        'hitType': 'event',
        'eventCategory': 'auth',
        'eventAction': 'login',
        'eventValue': mediator.user.id
      }
      if mediator.user.termsAccepted()
        if @model.get("next")
          utils.redirectTo {url: @model.get("next")}
        else
          utils.redirectTo 'home#index'
      else
        utils.redirectTo 'public#welcome'
    , (data) =>
      @model.set alert: data.responseJSON.errors[0]
      throbber.spin(false)
