View = require('views/base/view')
utils = require('lib/utils')
HlrBatch = require('models/hlr-batch')

module.exports = class HlrBatchPreviewView extends View

  template: require('./templates/hlr-batch-preview')

  noWrap: true

  useRivets: true

  optionNames: View::optionNames.concat([
    'hlr',
    'connections',
    'wallets'
  ])

  initialize: ->
    if @hlr
      @listenTo(@hlr, 'change:wallet_id', @updateWallet)
      @listenTo(@hlr, 'change:connection_id', @updateConnection)
    if @wallets
      @updateWallet()
      @listenTo(@wallets, 'synced', @updateWallet)

  updateWallet: =>
    if @hlr
      @wallet = @wallets.get({ id: @hlr.get('wallet_id') })

  updateConnection: =>
    if @hlr
      @connection = @connections.get(@hlr.get('connection_id'))

  render: ->
    super

  dispose: =>
    return if @disposed
    super
