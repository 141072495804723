View = require 'views/base/view'
Model = require 'models/base/model'
HeaderWalletView = require "views/header-wallet-view"
mediator = require 'mediator'
utils = require 'lib/utils'
TrialHelpModal = require('./trial-help-modal')
NewVersionNotifier = require('./new-version-notifier-modal')

module.exports = class HeaderView extends View
  useRivets: true
  tagName: 'header'
  attributes: {style: 'overflow: hidden'}
  template: require('views/templates/header')
  events:
    'click .opentrialmodal': 'openTrialHelp'
    'click .reloadApp': 'openNewVersionNotifier'


  menuItems: [
    {
      id: 'nav-home'
      class: ''
      href: utils.reverse('home#index')
      icon: 'fa fa-home fa-fw'
      label: 'Dashboard'
    },
    {
      id: 'nav-messages'
      class: ''
      href: utils.reverse('messages#create')
      icon: 'fa fa-pencil-square-o fa-fw'
      label: 'Messages'
    },
    {
      id: 'nav-sendouts'
      class: ''
      href: utils.reverse('sendouts#create')
      icon: 'fa fa-pencil-square fa-fw'
      label: 'Sendouts'
    },
    {
      id: 'nav-hlr-batch',
      class: '',
      href: utils.reverse('hlr-batch#create')
      icon: 'fa fa-search fa-fw',
      label: 'HLR Batch'
    },
    {
      id: 'nav-contacts'
      class: ''
      href: utils.reverse('contacts#index')
      icon: 'fa fa-book fa-fw'
      label: 'Contacts'
    },
    {
      id: 'nav-price-list'
      class: ''
      href: mediator.user.pricelist()
      icon: 'fa fa-list-ul fa-fw'
      label: 'Price List'
    },
    {
      id: 'nav-payment'
      class: ''
      href: mediator.user.wallet()
      icon: 'fa fa-money fa-fw'
      label: 'Payment'
    },
    {
      id: 'nav-settings'
      class: 'visible-xs visible-sm'
      href: utils.reverse('settings#user')
      icon: 'fa fa-cog fa-fw'
      label: 'Settings'
    },
    {
      id: 'nav-signout'
      class: 'visible-xs visible-sm'
      href: '/login'
      icon: 'fa fa-sign-out fa-fw'
      label: 'Sign out'
    }
  ]

  listen:
    'window:resize mediator': 'setFooterHeight'

  initialize: ->
    super
    @initVersionChecker()
    @viewState = new Model({newVersionAvailable: false})
    @viewState.on('change:newVersionAvailable', @setFooterHeight.bind(@))
    @listenTo(@collection, 'synced', @renderWallets) if @collection
    @listenTo mediator.user, 'change:last_used_wallet', =>
      @$('#wallet').attr href: mediator.user.wallet()
    @listenTo mediator.user, 'change:last_used_connection', =>
      @$('#pricelist').attr href: mediator.user.pricelist()
    @listenTo @model, 'change' , @setActiveLink


  initVersionChecker: () ->
    eTag = null

    pollInterval = 5 * 60 * 1000 # check every 5 minutes
    onPolled = (data, text, jqXHR) =>
      if jqXHR.status == 200 then @viewState.set('newVersionAvailable', true) else setTimeout(poll, pollInterval)

    poll = () =>
      $.ajax({
        method: 'HEAD', url: '/', cache: false,
        headers: { 'If-None-Match': eTag }
      }).always(onPolled)

    onFirstFetchSuccess = (data, text, jqXHR) -> eTag = jqXHR.getResponseHeader('eTag')

    firstPoll = () =>
      $.ajax({ method: 'HEAD', url: '/' })
        .done(onFirstFetchSuccess)
        .done(poll)
        .fail(setTimeout.bind(null, firstPoll, pollInterval))

    firstPoll()

  setFooterHeight: ->
    @$el.css({overflow: 'hidden'})
    setTimeout () =>
      navFooterHeight = @$('.nav-footer').outerHeight() + 10 # We have to add like, 10px because of browsers invisible newline space. doh
      windowWidth = $(window).width()
      navFooterHeight = 0 if windowWidth < 1000; # nav footer isn't shown under 1000px
      @$('.header-nav').css({ paddingBottom: "#{navFooterHeight}px", marginBottom: "-#{navFooterHeight}px" })
      setTimeout(@$el.css.bind(@$el, {overflow: 'auto'}), 1000)
    , 0

  renderWallets: ->
    subview.dispose() for subview in @subviews
    for model in @collection.models
      @subview "wallet-#{model.id}", new HeaderWalletView {
        model, container: '.nav-wallets'
      }
    @setFooterHeight()


  isTrial: ()->
    user = Chaplin.mediator.user
    isTrial = user.get('trial') || user.get('customer').trial
    return isTrial

  setActiveLink: =>

    return if @disposed
    route = @model.get('route')

    @$('nav ul>li').removeClass('active')

    routeHash = {
      'home#index' : '#nav-home'
      'messages#create' : '#nav-messages'
      'messages#sendouts' : '#nav-messages'
      'messages#inbox' : '#nav-messages'
      'messages#outbox' : '#nav-messages'

      'sendouts#create' : '#nav-sendouts'
      'sendouts#index' : '#nav-sendouts'
      'sendouts#edit' : '#nav-sendouts'

      'hlr-batch#create': '#nav-hlr-batch'

      'contacts#index' : '#nav-contacts'
      'contacts#group' : '#nav-contacts'
      'contacts#imports' : '#nav-contacts'
      'pricelists#show' : '#nav-price-list'
      'wallets#show' : '#nav-payment'
      'settings#user' : '#nav-settings'
      'settings#connections' : '#nav-settings'
      'settings#numbers' : '#nav-settings'
    }

    @$(routeHash[route.name]).addClass('active') if routeHash[route.name]


  render: ->
    super
    @$('[rel=popover]').popover
      container: '#site-container'
      trigger: 'hover'

    @setActiveLink()

  openTrialHelp: () ->
    new TrialHelpModal()

  openNewVersionNotifier: () ->
    new NewVersionNotifier()

  toggleDropDown: ->
    $('#dropdown-content').toggle 'true'
