View = require 'views/base/view'
utils = require 'lib/utils'
mediator = require 'mediator'

module.exports = class InboxesView extends View
  template: require './templates/inbox'

  useRivets: true

  listen:
    'synced collection': 'synced'
    'error collection': 'synced'

  events:
    'click .list-group-item': 'changeReadState'

  initialize: ->
    @model.set isFirstPage: @collection.offset is 0

  synced: ->
    @$('.bs-loading').remove()
    @checkCollectionLength()
    if @collection.length
      @model.trigger 'change:query'
      @$('.bs-empty').addClass 'hidden'
      @hasResults = true
      inboxMessageList = JSON.parse mediator.user.getInboxList()
      if inboxMessageList?
        for model in @collection.models
          for inboxMessage in inboxMessageList
            conversationIdForInboxMessage = "#{inboxMessage.to}-#{inboxMessage.from}"
            if conversationIdForInboxMessage is model.id and model.get('timestamp') > inboxMessage.timestamp
              @$("##{model.id}").addClass("list-group-item-info")
    else
      @$('.bs-empty').removeClass 'hidden'
      @hasResults = false

  filter: ->
    query = @model.get('query')

    return @collection.models unless query

    _.filter @collection.models, (model) ->
      needle = model.get('body')
      from = model.get('from')
      if from
        needle += "#{needle}|#{from.number}|#{model.fromDisplayName()}"

      needle.toLowerCase().indexOf(query.toLowerCase()) isnt -1

  checkCollectionLength: ->
      @model.set isLastPage: @collection.length is 0

  nextPage: (event) =>
    event.preventDefault()
    @collection.offset = @collection.offset + @collection.count
    @pageChangeURL()

  previousPage: (event) =>
    event.preventDefault()
    @collection.offset = @collection.offset - @collection.count
    @collection.offset = 0 if @collection.offset < 0
    @pageChangeURL()

  pageChangeURL: ->
    @collection.fetch()
    options = {}
    options.offset = @collection.offset if @collection.offset > 0
    route = utils.reverse 'messages#inbox', {}, options
    @model.set isFirstPage: @collection.offset is 0
    Backbone.history.navigate route, trigger: false

  changeReadState: (event) ->
    inboxMessageList = JSON.parse mediator.user.getInboxList()
    if inboxMessageList?
      conversationId = event.currentTarget.id.split "-"
      to = conversationId[0]
      from = conversationId[1]
      newMessageList = inboxMessageList.filter (item) ->
        item.to isnt to and item.from isnt from
      localStorage.setItem 'inbox_list', JSON.stringify(newMessageList)
