Model = require '/models/base/model'
mediator = require 'mediator'
utils = require 'lib/utils'

module.exports = class User extends Model


  defaults:
    verified: {
      email: false
      terms: false
    }

  apiAttrs: ['name', 'phones', 'user_types', 'default_connection', 'verified']

  urlRoot: '/2/users/'

  # Authenticate an email and a password against server
  authenticate: (email, password) ->
    # Not @sync here because of models/base/model inheritance
    Backbone.sync 'create', this,
      url: @urlFor "/authenticate"
      attrs: {email, password}

  setPassword: ->
    @sync('update', this, {
      url: @urlFor "/2/users/#{@id}/password"
      attrs:
        password: @get('password')
        new_password: @get('new_password')
    }).then(() =>
      @unset 'password'
      @unset 'new_password'
      @unset 'confirm_password'
    )

  setEmail: ->
    @sync('update', this, {
      url: @urlFor "/2/users/#{@id}/email"
      attrs:
        password: @get('email_password')
        email: @get('email')
      }).then =>
        @unset 'email_password'

  initSession: ->
    @on 'change', =>
      return unless @id
      localStorage.setItem 'bs-session', JSON.stringify(@toJSON())

    try
      session = JSON.parse localStorage.getItem('bs-session')
    catch
      session = null

    return unless session

    @resetInboxCount()
    mediator.subscribe "socket:state:mo", @incrementInboxCount

    @set(session)
    @fetch(url: @urlFor('/2/users/me'))

  extendSession: ->
    @set '__session_expire', moment().add(14, 'days').unix()

  expireSession: ->
    @clear silent: true
    localStorage.removeItem('bs-session')
    @removeInboxList()

  # Make sure expire timestamp is in the future
  validateSession: ->
    if moment().unix() < parseInt @get '__session_expire'
      @extendSession()
      return true
    false

  termsAccepted: ->
    verified = @get('verified')
    verified and verified.terms is true

  wallet: ->
    id = @get('last_used_wallet')
    if id
      utils.reverse 'wallets#show', walletId: id
    else
      utils.reverse 'wallets#index'

  pricelist: ->
    id = @get('default_connection')?.id
    if id
      utils.reverse 'pricelists#show', connectionId: id
    else
      utils.reverse 'pricelists#index'

  incrementInboxCount: (data) =>
    @setInboxCount @get('inbox_count') + 1
    @addToInboxList(data)

  decrementInboxCount: =>
    @setInboxCount @get('inbox_count') - 1

  setInboxCount: (inbox_count = 0) =>
    @set {inbox_count}

  resetInboxCount: =>
    @setInboxCount()

  getInboxList: ->
    localStorage.getItem 'inbox_list'

  removeInboxList: ->
    localStorage.removeItem 'inbox_list'

  addToInboxList: (data) =>
    inboxList = JSON.parse @getInboxList()
    unless inboxList?
      inboxList = []
    newIncomingMessage = {
      "timestamp": parseInt(data.timestamps.sms.in)-1
      "from": data.from.address
      "to": data.to.address
    }
    inboxList.push newIncomingMessage
    localStorage.setItem 'inbox_list', JSON.stringify inboxList
