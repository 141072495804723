Model = require './model'
config = require 'config'

module.exports = class Collection extends Chaplin.Collection

  # Use the project base model per default, not Chaplin.Model
  model: Model

  # Mixin a synchronization state machine.
  _.extend @prototype, Chaplin.SyncMachine

  initialize: ->
    super
    @on('request', @beginSync)
    @on('sync synced', @finishSync)
    @on('error', @unsync)
    @on('unsynced', @unsync)

  apiRoot: config.API
  urlRoot: ''

  updateSeparateModel: (model) ->
    current = @get(model.id)
    model.set current.toJSON() if current
    model

  url: -> @urlFor _.result(this, 'urlRoot')

  urlFor: (path) ->
    return @apiRoot + path

  sync: (method, model, options) ->
    options.headers = {} unless options.headers
    options.headers['Authorization'] = "Token #{Chaplin.mediator.user.get 'api_token'}"

    success = options.success
    options.success = (args...) =>
      return if model.disposed
      success? args...
      @finishSync()

    error = options.error
    options.error = (args...) =>
      if arguments[0].status is 401
        return Chaplin.mediator.publish '!logout', _.first(arguments[0].responseJSON.errors)
      return if model.disposed
      error? args...
      @abortSync()

    super(method, model, options)
