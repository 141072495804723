mediator = require 'mediator'
config = require 'config'

module.exports = class SocketHandler

  backoff: 1
  defaultDelay: 750
  socketTimeoutHandle: null
  callbackQueue: []
  sockjs: null

  constructor: () ->
    mediator.subscribe 'socket:send', (data) =>
      @sockjs?.send JSON.stringify data

  connectSocket: =>
    @sockjs = new SockJS config.SOCKET

    @sockjs.onopen = () =>
      @backoff = 1
      @sockjs.send JSON.stringify {
        command: 'authenticate'
        data:
          api_token: mediator.user.get('api_token')
      }
      # Run throught the callback queue
      for callback in @callbackQueue
        callback()

      # Subscribe to all state changes (state 11) for all connections this user
      # has access to. We use 11 because we only care when we get the DLR.
      @sockjs.send JSON.stringify {
        command: 'subscribe'
        data:
          state: 11
          type: 'raw'
      }
      # Subscribe to all MO's. State 61 is actually state 11 (50 + 11) which
      # means incoming MO waiting to be sent to customer.
      @sockjs.send JSON.stringify {
        command: 'subscribe'
        data:
          state: 61
          type: 'raw'
      }
      # Subscribe to all Batches with the connectid that the user as access to
      # We want to listen to the state
      @sockjs.send JSON.stringify {
        command: 'batchsubscribe'
      }

    @sockjs.onmessage = (event) ->
      if event.data.command
        # This is state updates, handle separately
        if event.data.command.indexOf("state") is 0
          state_metadata = event.data.command.split '.'
          key = if state_metadata[2] < 50 then "state:mt" else "state:mo"
          mediator.publish "socket:" + key, event.data.data
        else if /^contact-upload/.test(event.data.command)
          mediator.publish "socket:contact-upload", event.data.data
        else if event.data.data.status
          mediator.publish "socket:state:update", event.data.data
        else
          mediator.publish "socket:#{event.data.command}", event.data.data

    @sockjs.onclose = (event) =>
      @sockjs = null
      @reconnectSocket()
      mediator.publish 'socket:disconnected'

  reconnectSocket: () ->
    if @socketTimeoutHandle?
      clearTimeout(@socketTimeoutHandle)
    @socketTimeoutHandle = setTimeout =>
      @connectSocket()
    , @calcBackoffDelay()

  calcBackoffDelay: () ->
    @backoff = Math.min(@backoff * 1.5, 30)
    Math.round(@backoff * @defaultDelay)

  disconnectSocket: ->
    return unless @sockjs?
    mediator.unsubscribe 'socket:send'
    @sockjs.onclose = null
    @sockjs.close()
    @sockjs = null
    mediator.socket = null

  addCallback: (callback) ->
    if @sockjs?.readyState is 1
      callback()
    else unless _.contains(@callbackQueue, callback)
      @callbackQueue.push callback
