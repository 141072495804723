CollectionView = require('views/base/collection-view')
HlrBatchItemView = require('views/hlr-batch-item-view')
utils = require('lib/utils')

module.exports = class HlrBatchListView extends CollectionView
  template: require('./templates/hlr-batch-list')

  itemView: HlrBatchItemView

  useRivets: true
  listSelector: '.list-group'
  fallbackSelector: '.fallback'

  listen: {
    'change:query model': 'filterItems',
    # Check if current offset is the last or the first every time
    # collection is synced.
    'synced collection': 'configurePagination'
  }

  optionNames: CollectionView::optionNames.concat(['customer'])

  initialize: ->
    super

  filterItems: (model, query, options) ->
    query = query.toLowerCase()
    @filter (sendout) ->
      ret = true
      return ret unless query
      needle = sendout.get('label')
      ret and needle.toLowerCase().indexOf(query) isnt -1

  configurePagination: ->
    @model.set({
      # Todo: Last page does not work if number of batches is
      # exactly 200, 400 or 600... if the @collection.count is 200.
      isLastPage: @collection.length < @collection.count,
      isFirstPage: @collection.offset is 0
    })

  nextPage: (event) =>
    event.preventDefault()
    return if @collection.isSyncing()
    @collection.offset = @collection.offset + @collection.count
    @pageChangeURL()

  previousPage: (event) =>
    event.preventDefault()
    return if @collection.isSyncing()
    @collection.offset = @collection.offset - @collection.count
    @collection.offset = 0 if @collection.offset < 0
    @pageChangeURL()

  pageChangeURL: ->
    @collection.fetchWithStats()
    options = {}
    options.offset = @collection.offset if @collection.offset > 0
    route = utils.reverse('hlr-batch#index', {}, options)
    @model.set({ isFirstPage: @collection.offset } is 0)
    Backbone.history.navigate(route, { trigger: false })


  initItemView: (hlr) ->
    view = super
    view.customer = @customer
    view
