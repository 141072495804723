CollectionView = require 'views/base/collection-view'
SendoutView = require 'views/sendout-view'
utils = require 'lib/utils'

module.exports = class SendoutsView extends CollectionView
  template: require './templates/sendouts'

  itemView: SendoutView

  useRivets: true
  listSelector: '.list-group'
  fallbackSelector: '.fallback'

  listen:
    'change:query model': 'filterItems'
    'synced collection': 'checkCollectionLength'

  optionNames: CollectionView::optionNames.concat ['customer']

  initialize: ->
    @model.set isFirstPage: @collection.offset is 0
    super

  filterItems: (model, query, options) ->
    query = query.toLowerCase()
    @filter (sendout) ->
      ret = true
      return ret unless query
      needle = sendout.get('label')
      ret and needle.toLowerCase().indexOf(query) isnt -1

  checkCollectionLength: ->
    @model.set isLastPage: @collection.length is 0

  nextPage: (event) =>
    event.preventDefault()
    @collection.offset = @collection.offset + @collection.count
    @pageChangeURL()

  previousPage: (event) =>
    event.preventDefault()
    @collection.offset = @collection.offset - @collection.count
    @collection.offset = 0 if @collection.offset < 0
    @pageChangeURL()

  pageChangeURL: ->
    @collection.fetchWithStats()
    options = {}
    options.offset = @collection.offset if @collection.offset > 0
    route = utils.reverse 'sendouts#index', {}, options
    @model.set isFirstPage: @collection.offset is 0
    Backbone.history.navigate route, trigger: false

  initItemView: (sendout) ->
    view = super
    view.customer = @customer
    view
