ModalView = require './modal-view'
utils = require 'lib/utils'

module.exports = class TrialHelpModal extends ModalView
  autoRender: true

  options:
    footer: true
    header: true
    title: '<i class="fa fa-exclamation-circle"></i>&nbsp;You are in trial mode!'
    secondaryButton: '<a data-dismiss="modal" class="btn btn-primary" href="/wallets">I want full access!</a>'
    primaryButton: 'Maybe later!'

  modalOptions:
    keyboard: true
    show: true

  events:
    'hide.bs.modal': 'modalHide'

  getModalTemplateFunction: ->
    () => @getFullBody()

  getFullBody: () -> """
      <p>This means you have limited access to our API<p>
      <span>You can do <b><i>everything except</i></b>:</span>
      <ul>
        <li>Send messages to other recipients than yourself</li>
        <li>Send messages to more than one recipient (groups or multiple numbers)</li>
      </ul>
      <p>As soon as you charge your wallet you will have full access to the API!</p>
    """


  dispose: ->
    return if @disposed
    super
