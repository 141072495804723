View = require 'views/base/view'
utils = require 'lib/utils'

module.exports = class WalletsView extends View
  tagName: 'form'
  template: require './templates/wallets'

  useRivets: true

  listen:
    'synced collection': 'selectize'
    'change:balance collection': 'selectize'

  selectize: ->
    @_selectize?.destroy()
    render = (item, escape) ->
      """
        <div>
          <strong>#{escape(item.name)}</strong>
          <p class="text-muted no-bottom-buffer">
            <small class="text-primary">€#{escape(item.balance)}</small>
          </p>
        </div>
      """
    $selectize = @$('.wallets-select').selectize
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      options: @collection.toJSON()
      onChange: @redirect
      render:
        option: render
    @_selectize = $selectize[0].selectize
    @_selectize.setValue @model.id

  render: ->
    super
    @selectize()

  redirect: (id) =>
    return if parseInt(id) is @model.id
    utils.redirectTo 'wallets#show', walletId: id

  dispose: ->
    return if @disposed
    @_selectize.destroy()
    delete @_selectize
    super
