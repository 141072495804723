CollectionView = require 'views/base/collection-view'
ConnectionView = require 'views/connection-view'

module.exports = class ConnectionsView extends CollectionView
  template: require './templates/connections'
  itemView: ConnectionView

  useRivets: true
  renderItems: false

  listSelector: '.connections-container'
  fallbackSelector: '.fallback'

  listen:
    'change:query model': 'filterItems'

  filterItems: (model, query, options) ->
    query = query.toLowerCase()
    @filter (connection) ->
      ret = true

      return ret unless query

      label = connection.get('label')
      description = connection.get('description')
      system_id = connection.get('system_id')
      dlr_url = connection.get('dlr_url')
      mo_url = connection.get('mo_url')
      callback_method = connection.get('callback_method')
      type = if connection.get('type') is 2 then 'hlr' else 'sms'

      needle = "#{label}|#{description}|#{system_id}|#{dlr_url}|#{mo_url}|#{callback_method}|#{type}"

      ret and needle.toLowerCase().indexOf(query) isnt -1
